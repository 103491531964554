import React, { useEffect } from 'react';
import './GrievanceDashboard.css';
import { useState } from 'react';
import { useRef } from 'react';
import { FaFile } from "react-icons/fa";
import { BiSolidDownload } from "react-icons/bi";

import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import Navbar from '../Navbar.js';
import { FaThumbsUp, FaSort } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { GrNext } from "react-icons/gr"; //'>' icon
import Icon from "@mui/material/Icon";
import Cookies from 'js-cookie';

//import { moment } from 'moment';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
//import FirstPageIcon from '@mui/icons-material/FirstPage';
//import LastPageIcon from '@mui/icons-material/LastPage';

//import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
//import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { SlControlStart } from "react-icons/sl";
import { SlControlEnd } from "react-icons/sl";
import { TfiControlSkipBackward } from "react-icons/tfi";
import { TfiControlSkipForward } from "react-icons/tfi";

import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

//DialogBox Imports
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
//import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

//Icon edit
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import { BiSolidEditAlt } from "react-icons/bi";

//Loading
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

//Help icon
import { BiSolidHelpCircle } from 'react-icons/bi';
import useMediaQuery from '@mui/material/useMediaQuery';

function TablePaginationActions(props) {
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <SlArrowRight /> : <TfiControlSkipBackward />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <SlArrowLeft /> : <IoIosArrowBack />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <SlControlStart /> : <IoIosArrowForward />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <SlControlStart /> : <TfiControlSkipForward />}
            </IconButton>
        </Box>
    );
};

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
    return { name, calories, fat };
};

const rows = [
    createData('Cupcake', 305, 3.7),
    createData('Donut', 452, 25.0),
    createData('Eclair', 262, 16.0),
    createData('Frozen yoghurt', 159, 6.0),
    createData('Gingerbread', 356, 16.0),
    createData('Honeycomb', 408, 3.2),
    createData('Ice cream sandwich', 237, 9.0),
    createData('Jelly Bean', 375, 0.0),
    createData('KitKat', 518, 26.0),
    createData('Lollipop', 392, 0.2),
    createData('Marshmallow', 318, 0),
    createData('Nougat', 360, 19.0),
    createData('Oreo', 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

export default function GrievanceDashboard() {
    const [showComponent, setShowComponent] = useState(false);
    const [hideComponent, setHideComponent] = useState(true);
    const [grievance, setGrievance] = useState('')
    const [ticketNumber, setTicketNumber] = useState('')
    const [tno, setTno] = useState('')
    const [comment, setComment] = useState('') //Comments from Internal User Regarding Grievance
    const [grievanceStatus, setGrievanceStatus] = useState('') //Status : Updated by Internal User
    const [adminResponded, setAdminResponded] = useState('') //Status : Updated by Internal User

    const [singleGrievance, setSingleGrievance] = useState('')
    const [grievanceAcknowledgement, setGrievanceAcknowledged] = useState('False') //Status : Updated by Internal User
    const [sortBy, setSortBy] = useState('grievanceNumber')
    const navigate = useNavigate();
    const moment = require('moment') 
    const [errorForNotFoundGrievance, setErrorForNotFoundGrievance] = useState('');
    const [errorInComment, setErrorInComment] = useState('');
    const [acknowledgementGiven, setAcknowledgementGiven] = useState('');
    const [grievanceCategoryData, setGrievanceCategoryData] = useState('')
    const [grievanceCategory, setGrievanceCategory] = useState('')

    const refInputBoxClear = useRef(null);
    const theme = useTheme()

    /////////////////////
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Search
    const [copyList, setCopyList] = useState(grievance)
    const [filteredRowsFinal, setFilteredRowsFinal] = useState('')

    //Loading btn
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const handleClose = () => {
        setOpenBackdrop(false);
    };

    //RegisterDialog
    const [openRegisterDialog, setOpenRegisterDialog] = React.useState(false);

    const requestSearch = (searchedVal) => {
       
         const filteredRows = grievance?.filter((row) => {
             return row.grievanceStatus?.toLowerCase().includes(searchedVal.toLowerCase());
         });
        setFilteredRowsFinal(filteredRows)
        setCopyList(filteredRows);
        //console.log("Filtered rows ::" + filteredRows)
        //console.log(filteredRows=='')
        if (filteredRowsFinal =='') {
            const filteredRows2 = grievance?.filter((row) => {
                return row.grievanceDetails?.toLowerCase().includes(searchedVal.toLowerCase());
            });
            setCopyList(filteredRows2);
            //console.log("Filtered rows2 ::" + filteredRows)
            if (filteredRows2 == '') {
                const filteredRows3 = grievance?.filter((row) => {
                    return row.department?.toLowerCase().includes(searchedVal.toLowerCase());
                });
                setCopyList(filteredRows3);
                //console.log("Filtered rows3 ::" + filteredRows)
                if (filteredRows3 == '') {
                    const filteredRows4 = grievance?.filter((row) => {
                        return row.customerName?.toLowerCase().includes(searchedVal.toLowerCase());
                    });
                    setCopyList(filteredRows4);
                    //console.log("Filtered rows4 ::" + filteredRows)
                }
            }
        }
        //console.log("rowsPerPage=====" + copyList[0]);
        //console.log("RowsPerPage.customerName==== " + filteredRows[0].customerName)
        //console.log("RowsPerPage..grievanceDetails==== " + filteredRows[0].grievanceDetails)
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    ////////////////////////
    const handleClick = () => {
        setShowComponent(true);
        asyncGetGrievanceByTicketNumber(); //Fontend (Ticket Number) = backend ( Grievance Number)
        setHideComponent(false);
    } //hides component if shown, reveals if not shown
    const handleClickClear = (e) => {
        setShowComponent(false);
        setHideComponent(true);
        setErrorForNotFoundGrievance('');
        navigate("/GrievanceDashboard");
        refInputBoxClear.current.value = '';
        // e.preventDefault();
    }; //hides component if shown, reveals if not shown

    const handleClickEdit = (grievanceNumber) => {
        //console.log("==========" + grievanceNumber);
        if (grievanceNumber != '') {
            asyncGetGrievanceCategoryByDept(grievanceNumber);
            asyncShowGrievanceOnClickEdit(grievanceNumber);
           // asyncShowGrievanceOnClickEdit(grievanceNumber);

        } else {
            //console.log("Empty grievance number")
            setErrorForNotFoundGrievance("Enter grievance number!")
        }
    };

  
    const handleSubmitClickCommentAndStatus=(event,grievanceNumber) => {
        if (comment == '') {
            setErrorInComment("Field cannot be empty")
            event.preventDefault();
        } else {
            setShowComponent(false);
            setHideComponent(true);
            asyncSubmitCommentAndStatus(grievanceNumber);
        }
    }

    const handleClickAcknowledged = (grievanceNumber) => {
        setGrievanceAcknowledged('True');
        asyncPostAcknowledgement(grievanceNumber);
    };
    useEffect(() => {
       
        //console.log("grievanceStatus :: " + grievanceStatus);
        //console.log("grievanceCategory :: " + grievanceCategory);
        setComment('');
        setAcknowledgementGiven('')
        //To Open Grievance Summary Dialog on each refresh
        //handleClickOpen()
        //All department related grievances will be displayed.
        //console.log("InternalUserId" in sessionStorage)
        if ("InternalUserId" in sessionStorage) {
            asyncGetGrievanceByEmpCode();
        }
    }, []);
   
    const asyncGetGrievanceByEmpCode = async () => {

        try {
            //string customerType, int membershipId, string emailId, string phoneNumber, string customerName, string grievanceDetails, string department)
            const strGetAllGrievanceUrl = '/grievance/grievance-data-all?empCode=' + sessionStorage.getItem("InternalUserId");
            //const strGetGrievanceUrl = '/grievance/grievance-data';
            //console.log("strURL = " + strGetAllGrievanceUrl);

            await fetch(strGetAllGrievanceUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`,
                }
            }).then(response => {
                if (response.status == 401) {
                    alert("Session expired!")
                } else {
                    return response.json()
                }
            }).then(data => {
                //console.log("data", data);
                if (data) { 
                    setGrievance(data);
                    setCopyList(data);
                }
                //console.log("Grievance : "+grievance)
            });

        } catch (error) {
            alert("Something went wrong")
            console.log("error : " + error);
        };
    }
    const asyncGetGrievanceByTicketNumber = async () => {

        try {
            //string customerType, int membershipId, string emailId, string phoneNumber, string customerName, string grievanceDetails, string department)
            if (ticketNumber == '') {
                setTicketNumber(tno);
            }
            const strGetAllGrievanceUrl = '/grievance/grievance-data-by-tno?ticketNumber=' + ticketNumber;
            console.log("strURL = " + strGetAllGrievanceUrl);
           
            await fetch(strGetAllGrievanceUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`,
                }
            }).then(response => {
                if (response.status == 401) {
                    alert("Session expired!")
                } else {
                    return response.json()
                }            }).then(data => {
                //console.log("data", data);
                    setSingleGrievance(data);
                    setGrievanceCategory(singleGrievance.grievanceCategoryId);
                    setGrievanceStatus(singleGrievance.grievanceStatus);
                    //console.log("SingleGrievance :: "+data);

                setComment('');
                setErrorInComment('')
                //console.log("Grievance : "+grievance)
            });

        } catch (error) {
            alert("Something went wrong")
            console.log("error : " + error);
        };
    }

    const asyncGetGrievanceCategoryByDept = async (grievanceNumber) => {
         const strGetAllGrievanceCategoryUrl = '/grievance/grievance-category-by-tno';
            console.log("strURL = " + strGetAllGrievanceCategoryUrl);

        await fetch(strGetAllGrievanceCategoryUrl, {
            method: 'POST',
            headers: {
                'Authorization': `${sessionStorage.getItem('jwt')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                GrievanceNumber: grievanceNumber
            })
                
            }).then(response => {
                if (response.status == 401) {
                    alert("Session expired!")
                } else {
                    return response.json()
                }
            }).then(data => {
                //console.log("data", data);
                setGrievanceCategoryData(data);
                //console.log(grievanceCategoryData.grievanceCategoryId);
                //console.log(grievanceCategoryData.grievanceCategoryName);

            });
    }
    //Onclick EDIT Button
    const asyncTrackGrievanceOnClickEditButton = async (grievanceNumber) => {

        try {
            //method getting called
            //string customerType, int membershipId, string emailId, string phoneNumber, string customerName, string grievanceDetails, string department)
            const strGetSingleGrievanceUrl = '/grievance/grievance-data-by-tno?ticketNumber=' + grievanceNumber;
            console.log("strURL = " + strGetSingleGrievanceUrl);
           
            await fetch(strGetSingleGrievanceUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`,
                }
            }).then(response => {

                setShowComponent(true)
                setHideComponent(false)
                setErrorInComment('')
                return response.json()

            }).then(data => {
                setSingleGrievance(data);
                setGrievanceCategory(data.grievanceCategoryId);
                setGrievanceStatus(data.grievanceStatus);
                //console.log("singleGrievance data:: "+data)
                //console.log("OLD : singleGrievance.createdDate : : " + data.createdDate);
                //console.log("OLD : singleGrievance.createdDate : : " + moment(data.createdDate)?.utc().format('DD/MM/YYYY'));
                asyncGetGrievanceCategoryByDept(data.grievanceNumber);

                //var date = new Date(singleGrievance.createdDate).toLocaleDateString();
                //singleGrievance.createdDate = date.split(' ')[0];
                //console.log("Newww date format : : " + date);
            });

        } catch (error) {
            alert("Something went wrong")
            console.log("error : " + error);
        };
        console.log("errorForNotFoundGrievance == ''", errorForNotFoundGrievance === '')
    }
    const asyncShowGrievanceOnClickEdit = async (grievanceNumber) => {

        try {
            ////string customerType, int membershipId, string emailId, string phoneNumber, string customerName, string grievanceDetails, string department)
            //if (ticketNumber == '') {
            //    setTicketNumber(tno);
            //}
            const strGetAllGrievanceUrl = '/grievance/grievance-data-by-tno?ticketNumber=' + grievanceNumber;
            console.log("strURL = " + strGetAllGrievanceUrl);
            await fetch(strGetAllGrievanceUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`,
                }
            }).then(response => {
                
                if (response.status !== 200) {
                    setErrorForNotFoundGrievance('Invalid Grievance number!');

                    console.log("errorForNotFoundGrievance : " + errorForNotFoundGrievance);
                    return Error;
                } else {
                    setShowComponent(true)
                    setHideComponent(false)
                    return response.json()
                }
            }).then(data => {

                setSingleGrievance(data); 
                //console.log(":: moment(singleGrievance.createdDate)? : : " , moment(singleGrievance.createdDate).utc().format('DD/MM/YYYY'));

            //    let result = moment(moment()).diff(singleGrievance.dateOfEscalation, 'days')
            });

        } catch (error) {
            alert("Something went wrong")
            console.log("error : " + error);
        };

    }
    const asyncSubmitCommentAndStatus = async (grievanceNumber) => {
        //if (tno == '') {
        //    setTno(ticketNumber);
        //}
        //Test this
       // if (grievanceStatus == '1') {
       //     setGrievanceStatus('Submitted');
       // }
       //else if  (grievanceStatus == '2') {
       //     setGrievanceStatus('In-progress');
       // }else if  (grievanceStatus == '3') {
       //     setGrievanceStatus('Resolved');
       // }else if (grievanceStatus == '4') {
       //     setGrievanceStatus('Submitted');
       // }
        try {
            //,/grievance/grievance-update-comment-status
            //string ticketNumber,string comment,string grievanceStatus)
            setOpenBackdrop(true);

            var empCode = sessionStorage.getItem("InternalUserId");
            const strSubmitCommentAndStatus = 'grievance/grievance-update-comment-status';
            console.log("strURL = " + strSubmitCommentAndStatus);

            await fetch(strSubmitCommentAndStatus, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    GrievanceNumber:  grievanceNumber ,
                    Comment:  comment ,
                    GrievanceStatus: grievanceStatus,
                    AdminResponded: adminResponded,
                    EmpCode: `${sessionStorage.getItem('InternalUserId')}`,
                    GrievanceCategoryId: Number(grievanceCategory)
            })
                            //body: JSON.stringify({ PhoneNumber: strPhoneNumber })

            }).then(response => {
                return response.json()
            }).then(data => {
                //console.log("data", data);
                setOpenBackdrop(false);

                alert("Submitted successfully.")
                //TODO :  data==="true"
                //setSingleGrievance(data);
            });
            //After click on submit button retrive updated grievance data
            asyncGetGrievanceByEmpCode();
        } catch (error) {
            setOpenBackdrop(false);
            alert("Something went wrong")
            console.log("error : " + error);
        };
    }
    const handleDownload = async (grievanceNumber) => {
        var strFileExtension;
        const strGetFileExtension = "file/get-extension?grievanceNumber=" + grievanceNumber;
        fetch(strGetFileExtension, {
            method: 'GET',
            headers: {
                'Authorization': `${sessionStorage.getItem('jwt')}`,
            }
        }).then(response => {
            return response.json()
        }).then(data => {
            //console.log("extn", data);
            strFileExtension = data.fileExtension;
            // setFileExtension(data.fileExtension);
        }).then(
            fetch(`file/download/${grievanceNumber}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('File not found');
                    }
                    return response.blob();
                })
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const a = document.createElement('a');
                    a.href = url;

                    a.download = grievanceNumber + strFileExtension;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    console.error('Error:', error);
                }));
    }

    const handleDownloadSingleGrievanceDetails = async (grievanceNumber) => {
        //const response = await axios({
        //    url: 'http://localhost:5000/student/generatePdf',
        //    method: 'POST',
        //    data: student,
        //    responseType: 'blob',  // Important: indicates a binary data response
        //});



        //const response= await fetch(`report/download-submission-summary`, {
        //     method: 'POST',
        //     headers: {
        //         'Authorization': `${sessionStorage.getItem('jwt')}`,
        //     },
        //     responseType: 'blob'
        // });
        // const file = new Blob([response.data], { type: 'application/pdf' });
        // const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);



        await fetch(`report/generate-grievance-details-pdf`, {
            method: 'POST',
            headers: {
                'Authorization': `${sessionStorage.getItem('jwt')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                GrievanceNumber: grievanceNumber
            })
        }).then((response) => {
            if (!response.ok) {
                throw new Error('File not found');
            }
            return response.blob();
        })
            .then((data) => {
                const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
                //window.open(url);
                const a = document.createElement('a');
                a.href = url;
                a.download = grievanceNumber +"_GrievanceDetails"+ ".pdf";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    const asyncPostAcknowledgement = async (grievanceNumber) => {
        setOpenBackdrop(true)
        try {
            var empCode = sessionStorage.getItem("InternalUserId");
            //string customerType, int membershipId, string emailId, string phoneNumber, string customerName, string grievanceDetails, string department)
            const strPostAckUrl = 'grievance/submit-grievance-acknowledgement?ticketNumber=' + grievanceNumber+'&empCode='+empCode;
            //console.log("strPostAckUrl = " + strPostAckUrl);

            await fetch(strPostAckUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`
                }
            }).then(response => {
                return response.json()
            }).then(data => {
                console.log("Grievance-acknowledgement posted : ", data);
                setOpenBackdrop(false)
                //console.log("Grievance : "+grievance)
                asyncTrackGrievanceOnClickEditButton(grievanceNumber)
                //console.log("singleGrievance.grievanceAcknowledgement ::" + singleGrievance.grievanceAcknowledgement)
            });

        } catch (error) {
            setOpenBackdrop(false)
            alert("Something went wrong")
            console.log("error : " + error);
        };
    };
   
    //Code for Terms and conditions popup
    //Not using
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = () => {
        setOpen(true);
    };


    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    //Changes for small screen:
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <Navbar />
            {/*Summary Dialog*/}
            {/*<Dialog*/}
            {/*    open={open}*/}
            {/*    onClose={handleClose}*/}
            {/*    scroll='paper'*/}
            {/*    aria-labelledby="scroll-dialog-title"*/}
            {/*    aria-describedby="scroll-dialog-description" >*/}
            {/*    <DialogTitle id="scroll-dialog-title">{grievance.department } : Grievance Summary</DialogTitle>*/}
            {/*    <DialogContent dividers={scroll === 'paper'}>*/}
            {/*        */}{/*<DialogContentText*/}
            {/*        */}{/*    id="scroll-dialog-description"*/}
            {/*        */}{/*    ref={descriptionElementRef}*/}
            {/*        */}{/*    tabIndex={-1}  >*/}
            {/*        */}{/*    <div class="grid-container">*/}
            {/*        */}{/*    <span class="nav-bg-col grid-item" col='2'> Number of pending grievances</span>*/}
            {/*        */}{/*        <div class="grid-item"> 5</div>*/}
            {/*        */}{/*        <div class="grid-item"> Number of <p className="">Ticket Number :</p></div>*/}
            {/*        */}{/*        <div class="grid-item"> <p className="bold"></p></div>*/}

            {/*        */}{/*</div>*/}
                    
            {/*        */}{/*<table class="table bg-white ">*/}
                        
            {/*        */}{/*    <tbody>*/}
            {/*        */}{/*        <tr>*/}
            {/*        */}{/*            <th className="bold"><p>Number Of Pending Grievances -</p></th><td >1</td>*/}
            {/*        */}{/*        </tr>*/}
            {/*        */}{/*        <tr>*/}
            {/*        */}{/*            <th className="bold"><p>Total Unacknowledged Grievances -</p></th><td>6</td>*/}
            {/*        */}{/*        </tr>*/}
            {/*        */}{/*        <tr>*/}
            {/*        */}{/*            <th className="bold"><p>Closed Grievances -</p></th><td>10</td>*/}
            {/*        */}{/*        </tr>*/}
            {/*        */}{/*        <tr>*/}
            {/*        */}{/*            <th className="bold"><p>Resolved Grievances :</p></th><td>21</td>*/}
            {/*        */}{/*        </tr>*/}
            {/*        */}{/*        <tr>*/}
            {/*        */}{/*            <th className="bold"><p>In-progress Grievances :</p></th><td>20</td>*/}
            {/*        */}{/*        </tr>*/}
            {/*        */}{/*    </tbody>*/}
            {/*        */}{/*    </table>*/}

            {/*        */}{/*</DialogContentText>*/}
                   
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button onClick={handleClose} >NEXT</Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div class="nav-path-align" ><h4>

                <a href="/" style={{ 'color': 'black' }}>Home<GrNext style={{ 'margin-bottom': '2px', 'size': '10px' }} /></a>
                <a href="/GrievanceDashboard" style={{ 'color': 'black' }}>Grievance Dashboard</a>
            </h4>
            </div>
            <div id="">
                {!isSmallScreen &&
                <div class="input-group">
                    {!isSmallScreen &&
                        <label className="bold search-label">Track Grievance by Ticket Number :
                            {errorForNotFoundGrievance && <p style={{ color: "red" }}>{errorForNotFoundGrievance}</p>}</label>
                    }
                {/*<input type="text" class="form-control" id="datatable-search-input" placeholder="Search by Ticket Number" />*/}
                {/*<button class="btn btn-primary" id="advanced-search-button" type="button" onClick={handleClick}>*/}
                {/*    <i class="fa fa-search" ></i>*/}
                {/*</button>*/}
              
                    <input ref={refInputBoxClear} class="mb-4" type="text" id="datatable-search-input" onChange={(e) => setTicketNumber(e.target.value)} placeholder="Search by Ticket Number" />
                    <button class="mrright mb-4 btn btn-primary" id="advanced-search-button" onClick={() => handleClickEdit(ticketNumber)} type="button">
                        <i class="fa fa-search"></i>

                </button>
                <div class="submit-div-search">
                        {/*<MDBBtn type="submit" className="d-inline mb-4 mrleft" onClick={() => handleClickEdit(ticketNumber)}>Apply</MDBBtn>*/}
                    <MDBBtn type="cancel" className="mb-4 mrleft" id="clear-input-btn" onClick={e=>handleClickClear(e)}>Reset</MDBBtn>
                    </div>
                    <div className="mrright">
                        <input className="mrright"
                            placeholder='Track grievance by keyword'
                            type='text'
                            onInput={(e) => requestSearch(e.target.value)}
                            
                        />
                       
                    </div>
                    <button className="mb-4 btn-white btn-primary" type="button">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
                }
                {isSmallScreen &&
                    <div  style={{ 'flex-wrap': 'unset !important'} }>
                        {!isSmallScreen &&
                            <label className="bold search-label">Track Grievance by Ticket Number :
                                {errorForNotFoundGrievance && <p style={{ color: "red" }}>{errorForNotFoundGrievance}</p>}</label>
                        }
                        {/*<input type="text" class="form-control" id="datatable-search-input" placeholder="Search by Ticket Number" />*/}
                        {/*<button class="btn btn-primary" id="advanced-search-button" type="button" onClick={handleClick}>*/}
                        {/*    <i class="fa fa-search" ></i>*/}
                        {/*</button>*/}

                        <input ref={refInputBoxClear} class="mb-4" type="text" id="datatable-search-input" onChange={(e) => setTicketNumber(e.target.value)} placeholder="Search by Ticket Number" />
                        <button class="mb-4 btn-primary" id="advanced-search-button" onClick={() => handleClickEdit(ticketNumber)} type="button">
                            <i class="fa fa-search" style={{ color: "white" }}></i>

                        </button>
                        <div class="submit-div-search">
                            {/*<MDBBtn type="submit" className="d-inline mb-4 mrleft" onClick={() => handleClickEdit(ticketNumber)}>Apply</MDBBtn>*/}
                            <MDBBtn type="cancel" className="mb-4 mrleft" id="clear-input-btn" onClick={e => handleClickClear(e)}>Reset</MDBBtn>
                        </div>
                        <div className="mrright">
                            <input className="mrright"
                                placeholder='Track grievance by keyword'
                                type='text'
                                onInput={(e) => requestSearch(e.target.value)}

                            />
                            <button className="mb-4 btn-white btn-primary" type="button">
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                       
                    </div>
                }
            </div>
            {/*<input*/}
            {/*    variant='outlined'*/}
            {/*    placeholder='search...'*/}
            {/*    type='search'*/}
            {/*    onInput={(e) => requestSearch(e.target.value)}*/}
            {/*/>*/}
            
            <br/>
            {hideComponent &&
                <>
             
                <TableContainer component={Paper}  sx={{'text-align': 'center', 'vertical-align': 'middle' }} >
                    

                    
                    <Table sx={{ 'text-align': 'center', 'vertical-align': 'middle' }} aria-label="custom pagination table">
                        
                        <TableHead>
                            {/*<TableRow >*/}
                            {/*    <TableCell colSpan='11'>*/}
                                    {/*<div className="input-group-inside-btn">*/}
                                    {/*    <input className=""*/}
                                    {/*        placeholder='Track grievane by keyword'*/}
                                    {/*        type='text'*/}
                                    {/*        onInput={(e) => requestSearch(e.target.value)}*/}
                                    {/*        style={{width:"400px"} }*/}
                                    {/*    /> */}
                                    {/*    <button className="mb-4 btn-white btn-primary" type="button">*/}
                                    {/*        <i class="fa fa-search"></i>*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                            {/*    </TableCell>*/}
                            {/*</TableRow>*/}
                            <TableRow sx={{ 'text-align': 'center', 'vertical-align': 'middle' }}>
                            
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }} >Edit Ticket
                </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }} >Ticket Number <FaSort onClick={() => setSortBy('grievanceNumber')} />
                            </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                    Reported Date <FaSort onClick={() => setSortBy('createdDate')} />
                                </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                Name <FaSort onClick={() => setSortBy('customerName')} />
                            </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                Grievance Details <FaSort onClick={() => setSortBy('grievanceDetails')} />
                            </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                Status <FaSort onClick={() => setSortBy('grievanceStatus')} />
                            </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                Existing Member/Customer
                            </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                Additional Details/ File
                                </TableCell>
                            
                            <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                Customer Status <FaSort onClick={() => setSortBy('customerStatus')} />
                            </TableCell>
                               
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                    Escalation Status
                                </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                    Customer Remarks
                                </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                    CCIL Comment
                                </TableCell>

                          </TableRow>
                    </TableHead>
                        <TableBody >
                            {copyList && copyList?.sort((a, b) => {
                                if (sortBy == 'customerName') {
                                    if (a.customerName < b.customerName) {
                                        return -1
                                    }
                                    if (a.customerName > b.customerName) {
                                        return 1
                                    }
                                }
                                else if (sortBy == 'grievanceNumber') {
                                    if (a.grievanceNumber < b.grievanceNumber) {
                                        return -1
                                    }
                                    if (a.grievanceNumber > b.grievanceNumber) {
                                        return 1
                                    }
                                } else if (sortBy == 'createdDate') {
                                    if (a.createdDate < b.createdDate) {
                                        return -1
                                    }
                                    if (a.createdDate > b.createdDate) {
                                        return 1
                                    }
                                } else if (sortBy == 'customerType') {
                                    if (a.customerType < b.customerType) {
                                        return -1
                                    }
                                    if (a.customerType > b.customerType) {
                                        return 1
                                    }
                                } else if (sortBy == 'grievanceStatus') {
                                    if (a.grievanceStatus < b.grievanceStatus) {
                                        return -1
                                    }
                                    if (a.grievanceStatus > b.grievanceStatus) {
                                        return 1
                                    }
                                } else if (sortBy == 'grievanceDetails') {
                                    if (a.grievanceDetails < b.grievanceDetails) {
                                        return -1
                                    }
                                    if (a.grievanceDetails > b.grievanceDetails) {
                                        return 1
                                    }
                                }
                                return 0
                            }) &&
                            (rowsPerPage > 0 
                                ? copyList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : copyList
                                )?.map((row) => (
                                    <TableRow >
                                        <TableCell component="th" style={{ width: 120, 'text-align': 'center' }} scope="row">
                                            <MDBBtn onClick={() => asyncTrackGrievanceOnClickEditButton(row.grievanceNumber)} style={{ '--mdb-btn-padding-top': '0.4rem !important', '--mdb-btn-padding-bottom': ' 0.3rem','--mdb-btn-padding-x': '0.8rem' }}><BiSolidEditAlt style={{'font-size':'large'}} /></MDBBtn>
                                </TableCell>
                                        <TableCell style={{ width: 120, 'text-align': 'center' }} >
                                    <p class="fw-normal mb-1" onChange={(e) => setTno(e.target.value)}>{row.grievanceNumber}</p>
                                </TableCell>
                                        <TableCell style={{ minWidth: 100, 'text-align': 'center' }} >
                                            <p class="fw-bold mb-1">{moment(row.createdDate)?.utc().format('DD/MM/YYYY')}</p>
                                        </TableCell>
                                        <TableCell style={{ width: 120, 'text-align': 'center' }} >
                                    <div class="d-flex align-items-center">

                                        <div class="ms-3">
                                            <p class="fw-bold mb-1">{row.customerName}</p>
                                            <p class="text-muted mb-0">{row.emailId}</p>
                                        </div>
                                            </div>
                                        </TableCell>
                                       
                                        <TableCell style={{ minWidth: 170, 'text-align': 'center' }} >
                                    <p class="fw-normal mb-1">{row.grievanceDetails}</p>
                                </TableCell>
                                        <TableCell style={{ width: 120, 'text-align': 'center' }} >
                                    <span class="badge badge-success rounded-pill d-inline">{row.grievanceStatus}</span>
                                </TableCell>
                                        <TableCell style={{ width: 120, 'text-align': 'center' }} >
                                    {row.membershipId }
                                </TableCell>
                                        <TableCell style={{ minWidth: 150, 'text-align': 'center' }} >
                                            <MDBBtn type="submit" className="d-inline" onClick={() => handleDownload(row.grievanceNumber)}
                                                style={{ '--mdb-btn-padding-top': '0.4rem !important', '--mdb-btn-padding-bottom': ' 0.3rem', '--mdb-btn-padding-x': '0.8rem' }}
                                            >Download <FaFile style={{ 'margin-bottom': '5px' }}></FaFile></MDBBtn>
                                            
                                </TableCell>
                                        <TableCell style={{ width: 120, 'text-align': 'center' }} >
                                            {row.customerStatus}
                                        </TableCell>
                                        <TableCell sx={{ minWidth: 190, 'text-align': 'center' }} style={{ minWidth: 140 }}>
                                            {row.escalationLevel == '1' &&
                                                <> <p>Escalated to Level- <b>{row.escalationLevel}</b>, In-Progress,
                                                    {row.adminResponded == 'Yes' && <b style={{ color: "blue" }}> Action taken</b>}
                                                    {row.adminResponded == 'No' && <b style={{ color: "red" }}> Action not taken</b>}
                                                </p>
                                                </>}
                                            {row.escalationLevel == '2' &&
                                                <>
                                                    <p>Escalated to Level- <b>{row.escalationLevel}</b>, In-Progress,
                                                        {row.adminResponded == 'Yes' && <b style={{ color: "blue" }}> Action taken</b>}
                                                        {row.adminResponded == 'No' && <b style={{ color: "red" }}> Action not taken</b>}
                                                    </p>
                                                </>}
                                            {row.escalationLevel == '' && <><p>-</p></>}
                                        </TableCell>
                                        <TableCell style={{ minWidth: 160, 'text-align': 'center' }} >
                                    {row.customerRemark}
                                </TableCell>
                                    
                                        <TableCell style={{ width: 120, 'text-align': 'center' }} >
                                            {row.comment}
                                        </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 0 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                        <TableFooter>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={4}
                                count={copyList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableFooter>
                </Table>
                </TableContainer>
            </>
            }
            {showComponent &&
                <div>
                    <div class="grid-container">
                        <div class="grid-item"> <p className="bold">Ticket Number :</p></div>
                        <div class="grid-item"> <p className="">{singleGrievance.grievanceNumber}</p></div>
                        <div class="grid-item"> <p className="bold">Type of the customer :</p></div>
                        <div class="grid-item"> <p className="">{singleGrievance.customerType}</p></div>
                        <div class="grid-item"> <p className="bold">Member ID :</p></div>
                        <div class="grid-item"> <p className="">{singleGrievance.membershipId}</p></div>
                        <div class="grid-item"> <p className="bold">Name of the customer :</p></div>
                        <div class="grid-item"> <p className="">{singleGrievance.customerName}</p></div>
                        <div class="grid-item"> <p className="bold"> Reported Date :</p></div>
                     
                        <div class="grid-item">   <p className="">{moment(singleGrievance.createdDate).utc().format('DD/MM/YYYY')}</p></div>
                        <div class="grid-item"> <p className="bold"> Grievance Details : </p></div>
                        <div class="grid-item">  <p className="">{singleGrievance.grievanceDetails}</p></div>
                        <div class="grid-item">  <p className="bold">Additional Details / File : </p></div>
                        <div class="grid-item">
                            <MDBBtn className="position-center disabled-color" disabled={(singleGrievance.isAttachmentPresent)?.length > 1 ? false : true} onClick={() => handleDownload(singleGrievance.grievanceNumber)}
                                style={{ '--mdb-btn-padding-top': '0.4rem !important', '--mdb-btn-padding-bottom': ' 0.3rem', '--mdb-btn-padding-x': '0.8rem' }}
                            >Download File <FaFile style={{ 'margin-bottom': '5px' }} />
                            </MDBBtn>
                            {/*<MDBBtn id="download_btn" className="position-center" disabled={(singleGrievance.isAttachmentPresent)?.length > 1 ? false : true} onClick={() => handleDownload(singleGrievance.grievanceNumber)}>Download <FaFile /></MDBBtn>*/}

                        </div>
                            {singleGrievance.grievanceStatus == 'Closed' &&
                                <div class="grid-item"> <p className="bold">Grievance Status : </p></div>}
                            {singleGrievance.grievanceStatus == 'Closed' &&
                                <div class="grid-item"> <p >{singleGrievance.grievanceStatus} </p> </div>
                        }
                        {singleGrievance.escalationLevel == '1' &&
                            <>
                                <div class="grid-item"><p className="bold">Escalation Status : </p></div>
                                <div class="grid-item">
                                    <p>Escalated to Level- <b>{singleGrievance.escalationLevel}</b>,
                                        {/*{singleGrievance.adminResponded == 'Yes' && <b style={{ color: "blue" }}> Action taken</b>}*/}
                                        {/*{singleGrievance.adminResponded == 'No' && <b style={{ color: "red" }}> Action not taken</b>}*/}
                                    <select class="form-control-lg badge badge-success" onChange={(e) => setAdminResponded(e.target.value)} disabled={(singleGrievance.adminResponded == 'Yes') ? true : false}>

                                        <option value="none" style={(singleGrievance.adminResponded == 'Yes') ? { color: "blue" } : { color: "red" }} selected disabled hidden>{(singleGrievance.adminResponded == 'Yes') ? <>Action Taken</> : <>Action Not Taken</>}
                                        </option>
                                        {/*<option selected value={singleGrievance.grievanceStatus}>{singleGrievance.grievanceStatus}</option>*/}
                                        <option value="Yes" style={{ color: "blue" }}  >Action Taken</option>
                                        <option value="No" style={{ color: "red" }}>Action Not Taken</option>
                                    </select>
                                </p>
                                </div>
                            </>}
                        {singleGrievance.escalationLevel == '2' &&
                            <>
                                <div class="grid-item"><p className="bold">Escalation Status : </p></div>
                                <div class="grid-item">
                                    <p>Escalated to Level- <b>{singleGrievance.escalationLevel}</b>,<b>    </b>
                                    {/*    {singleGrievance.adminResponded == 'Yes' && <b style={{ color: "blue" }}> Action taken</b>}*/}
                                    {/*{singleGrievance.adminResponded == 'No' && <b style={{ color: "red" }}> Action not taken</b>}*/}
                                    <select class="form-control-lg badge badge-success" onChange={(e) => setAdminResponded(e.target.value)} disabled={(singleGrievance.adminResponded == 'Yes')?true:false }>

                                            <option value="none" style={(singleGrievance.adminResponded == 'Yes') ? { color: "blue" } : { color: "red" }} selected disabled hidden>{(singleGrievance.adminResponded == 'Yes') ? <>Action Taken</> : <>Action Not Taken</>}
                                            </option>
                                            {/*<option selected value={singleGrievance.grievanceStatus}>{singleGrievance.grievanceStatus}</option>*/}
                                            <option value="Yes" style={{ color: "blue" }}  >Action Taken</option>
                                            <option value="No" style={{ color: "red" }}>Action Not Taken</option>
                                        </select>
                                    
                                    </p>
                                </div>
                            </>}
                        {singleGrievance.grievanceStatus != 'Closed' &&
                        <>
                      <div class="grid-item"><p className="bold">Change Status : </p></div>
                          
                        <div class="grid-item"> <div class="position-center">
                                <select class="form-control-lg badge badge-success"  onChange={(e) => setGrievanceStatus(e.target.value)}>
                                
                                    <option value={singleGrievance.grievanceStatus}  selected disabled hidden>{singleGrievance.grievanceStatus}
                                </option>
                                {/*<option selected value={singleGrievance.grievanceStatus}>{singleGrievance.grievanceStatus}</option>*/}
                                    <option value="Submitted" disabled={(singleGrievance.grievanceStatus == 'In-Progress' || singleGrievance.grievanceStatus == 'Resolved')?true:false}>Submitted</option>
                                    <option value="In-Progress" disabled={(singleGrievance.grievanceStatus == 'Resolved') ? true : false}>In-Progress</option>
                                    <option value="Resolved">Resolved</option>
                                <option value="Closed">Closed</option>
                            </select>
                        </div>
                        </div>
                            <div class="grid-item"> <p className="required bold">Add Comment : </p></div>
                            <div class="grid-item">
                                <textarea className=" position-center form-control-lg" onChange={(e) => setComment(e.target.value)} type="text" placeholder="Add comment" rows={3} cols={75}>
                                </textarea>
                                <BiSolidHelpCircle title="Only / & % - ( ) $ ? these special characters are allowed. Limit is 1000 characters." className="help-btn d-inline" />

                                {errorInComment && comment==='' &&
                                    <p style={{ color: "red" }}>{errorInComment}</p>}
                            </div>
                            <div class="grid-item"><p className="bold">Grievance Acknowledgement : </p></div>
                            <div class="grid-item">
                            
                              
                                {(singleGrievance.grievanceAcknowledgement === 'True') ?
                                    <MDBBtn type="submit" disabled={singleGrievance.grievanceAcknowledgement == 'True' ? true : false} className="Satisfiedbtn" onClick={() => handleClickAcknowledged(singleGrievance.grievanceNumber)} ><FaThumbsUp />   Acknowleged</MDBBtn>
                                :
                                
                                <div> <MDBBtn type="submit" className="Dissatisfiedbtn" onClick={() => handleClickAcknowledged(singleGrievance.grievanceNumber)} ><FaThumbsUp /> Acknowledge</MDBBtn>
                                            <label className="mrleft">Action not taken, please acknowledge!</label>
                                  </div>
                                }
                                
                            </div>
                            </>}


                        {singleGrievance.grievanceStatus != 'Closed' &&
                            <>
                                <div class="grid-item"><p className="bold"> Grievance Category : </p></div>

                                <div class="grid-item"> <div class="position-center">
                                {/*    <select class="form-control-lg badge badge-success" onChange={(e) => setGrievanceStatus(e.target.value)}>*/}

                                {/*        <option value={singleGrievance.grievanceCategory} defaultValue selected disabled hidden>{singleGrievance.grievanceStatus}*/}
                                {/*        </option>*/}
                                {/*        <option value="Submitted" disabled={(singleGrievance.grievanceStatus == 'In-Progress' || singleGrievance.grievanceStatus == 'Resolved') ? true : false}>Submitted</option>*/}
                                {/*        <option value="In-Progress" disabled={(singleGrievance.grievanceStatus == 'Resolved') ? true : false}>In-Progress</option>*/}
                                {/*        <option value="Resolved">Resolved</option>*/}
                                {/*        <option value="Closed">Closed</option>*/}
                                {/*</select>*/}
                                <select class="select-option mb-4 custom-select" onChange={(e) => setGrievanceCategory(e.target.value)}>
                                    <option value={singleGrievance.grievanceCategory} defaultValue selected disabled hidden>{singleGrievance.grievanceCategory}</option>
                                    {grievanceCategoryData && grievanceCategoryData.map((category) => (
                                        <option value={category.grievanceCategoryId}>{category.grievanceCategoryName}</option>
                                    )
                                    )}
                                </select>
                                </div>
                                </div>                               
                            </>}
                    </div>
                    <div className="submit-div">  
                        <MDBBtn type="submit" onClick={(event) => handleSubmitClickCommentAndStatus(event,singleGrievance.grievanceNumber )} >Save</MDBBtn>
                        <MDBBtn type="cancel" className="mrleft" onClick={e => handleClickClear(e)} >Cancel</MDBBtn>
                        <MDBBtn type="submit" className="mrleft d-inline" onClick={() => handleDownloadSingleGrievanceDetails(singleGrievance.grievanceNumber)}
                            style={{ '--mdb-btn-padding-top': '0.4rem !important', '--mdb-btn-padding-bottom': ' 0.3rem', '--mdb-btn-padding-x': '0.8rem' }}
                        >Download Details <BiSolidDownload size={ 16} style={{ 'margin-bottom': '5px','size':'large' }}></BiSolidDownload></MDBBtn>
                    </div>
                </div>
            }
            <hr/>

            {/*<div className=" footer max-height padding-footer padding-footer-bottom  d-flex  text-center text-md-start bg-primary-col  ">*/}
            {/*    */}{/*<div className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">*/}
            {/*    <div className="text-white">*/}
            {/*        Copyright � 2024. All rights reserved.*/}
            {/*    </div>*/}

            {/*    <div>*/}

            {/*    </div>*/}

            {/*</div>*/}


            </div>
    );
}
import React, { useRef } from "react";
import { useState, useEffect } from 'react';
import './PostGrievance.css';
import Navbar from '../Navbar.js';
import { BiSolidHelpCircle } from 'react-icons/bi';

//import { PDFDownloadLink } from '@react-pdf';
//import { Document, Page, Text, View, StyleSheet } from '@react-18-pdf/renderer';
//import { PDFDownloadLink } from '@react-18-pdf/renderer';
//import { useReactToPrint } from 'react-to-print';
import { useNavigate } from "react-router-dom";
import { GrNext } from "react-icons/gr"; //'>' icon
import Cookies from 'js-cookie';
import Pdf, { usePDF, Resolution, Margin } from "react-to-pdf";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import { MDBTooltip,
    MDBContainer, MDBBtn, MDBInput
} from 'mdb-react-ui-kit';
//import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

//--------------DEPARTMENTS--------------
//Derivatives
//Economic Research
//Economic Research & Surveillance, Membership & HRD
//Finance & Accounts
//Forex Settlement
//FX - Clear
//Information Technology
//Legal, Secretarial & Compliance
//Membership
//NDS Operations
//Operations - Fixed Income and Money Market
//Product Development
//Risk Management
//Other Services

//LEIL
//Surveillance
//Security Consultant
//Internal Audit
//Human Resources & Administration Department


//a.Enter Name
//b.Customer Type: Existing CCIL Member(Enter membership Id), Customer etc.
//c.Nature of Grievance: i.e.Systems / department(FXRetail, LEI, Membership, Accounts etc.)
//d.Grievance details: enter the text
//e.Upload File(if any): additional details of the grievance if required.
//f.Submit & Cancel button

//
//string customerType, int membershipId, string emailId, string phoneNumber, string customerName, string grievanceDetails, string department, string password)
//
function PostGrievance() {
    //const componentPDF = useRef(null);
    //const [showDownloadBtn, setShowDownloadBtn] = useState(false)

    const [customerType, setCustomerType] = useState(false)
    const [membershipId, setMembershipId] = useState('-')
    //const [emailId, setEmailId] = useState('')
    //const [phoneNumber, setPhoneNumber] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [grievanceDetails, setGrievanceDetails] = useState('')
    const [department, setDepartment] = useState('')
    const [entityDetails, setEntityDetails] = useState('-')
    const [fileExtension, setFileExtension] = useState()
    const [fileSelected, setFileSelected] = useState();
    const [fileSize, setFileSize] = useState();
    const [departmentData, setDepartmentData] = useState('')

    const [error, setError] = useState(null);
    const [fileError, setFileError] = useState(null);
    const [open, setOpen] = React.useState(false);


    useEffect(() => {
        //Get all department related data(id,name).
        asyncGetDepartmentData();
    }, []);
    const asyncGetDepartmentData = async () => {

        try {
            await fetch('/department/getDepartmentData', {
                method: 'POST',
                headers: {
                    'Authorization': `${Cookies.get('jwt')}`,
                }
            }).then(response => {
                return response.json()
            }).then(data => {
                console.log("department data", data);
                setDepartmentData(data);
            });

        } catch (error) {
            alert("Something went wrong")
            console.log("error : " + error);
        };
    }
    const navigate = useNavigate();
    const handleRadioButtonClick = () => {
        setCustomerType(!customerType);
        setError(null);
    }
    //To block Special characters
    const checkSpecialChar = (e) => {
        if (!/[0-9a-zA-Z]/.test(e.key)) {
            /// Allow these / & % - ( ) $ ?
            if (e.key() != '/' ||
                e.key() != '&' ||
                e.key() != '%' ||
                e.key() != '-' ||
                e.key() != '(' ||
                e.key() != ')' ||
                e.key() != '$' ||
                e.key() != '?' ) { 
                setError('Only / & % - ( ) $ ? these special characters are allowed!')
                e.preventDefault();
            }
        }
    };
    const onSubmitClick = () => {
        console.log("DEPT ID : " + department)
        //Call api - > method -> Stored Procedure to submit the Grievance.
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};.':"\\|,<>\/?~]/;
        console.log("IF BLOCK :: " +
            //(!specialChars.test(customerType)
            //|| !/[`!@#$%^&*()_+\-=\[\]{};.':"\\|,<>\/?~]/.test(membershipId)
            //|| !/[`!@#$%^&*()_+\-=\[\]{};.':"\\|,<>\/?~]/.test(sessionStorage.getItem("CustomerId"))
            //|| !/[`!#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/.test(sessionStorage.getItem("MailId"))
             /[`!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/.test(customerName));
            //|| !/[`@#$%^&*\[\]\\|<>\/~]/.test(grievanceDetails)
            //|| !/[`!@#$%^&*()+=\[\]{};.':"\\|,<>\/?~]/.test(department)));
        if (specialChars.test(customerType)
             //Allow for membership Id / & % - ( ) $ ?
                || /[`!@#^*_+=[]{};.':"|,<>?~]/.test(membershipId)
                || /[`!@#$%^&*()_+\-=\[\]{};.':"\\|,<>\/?~]/.test(sessionStorage.getItem("CustomerId"))
                || /[`!#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/.test(sessionStorage.getItem("MailId"))
                || /[`!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/.test(customerName)
                || /[`@#^*\[\]|<>~]/.test(grievanceDetails)
                || /[`!@#$%^&*()+=\[\]{};.':"\\|,<>\/?~]/.test(department)) {
                alert("Special characters not allowed!")

            } else {
                asyncPostGrievance();
        }
        //After successful insersion of grievance into Database, send an email to respective deptartment/Support team member
        //importFile();
    }
    const onClearClick = () => {
        navigate("/");
    }
    const handleClose = () => {
        setOpen(false);
    };
   
    const asyncPostGrievance = async () => {
        setOpen(false);
        const formData = new FormData();
        //if (fileSelected) {
            formData.append('file', fileSelected);
        //}
        const phoneNumber = sessionStorage.getItem("CustomerId");
        const emailId = sessionStorage.getItem("MailId");
        setFileError(null)
        if (fileSelected != null) {
            //setFileExtension((fileSelected.name).toString().split('.').pop());
            console.log("getExtension(fileSelected.name) ::: " + getExtension(fileSelected.name));
            let fileExtension = getExtension(fileSelected.name)
            if (fileExtension == 'jpg'
                || fileExtension == 'png' || fileExtension == 'pdf'
                || fileExtension == 'doc' || fileExtension == 'PNG') {
                setFileError(null);
            }
            else {
                setFileError("Invalid file format!")
            }
        }
        let pattern = /\./g;

        let result  = (fileSelected?.name)?.match(pattern).length;//to count number of extensions in file uploded by the customer
        if (result != 1 && result != undefined) {
            setFileError("Invalid file format!")
        }
        //Code to check size of the uploaded file
        setFileSize(null);
        if (fileSelected != null && (fileSelected.size * 0.001)>2000) {
            console.log("fileSelected.size * 0.001 :: " + fileSelected.size * 0.001);
            setFileError("File size should be less than 2MB, selected file size is : " + fileSelected.fileSize / 1000);
            
        }
        if ((result ==1 || result==undefined) && fileError==null && emailId != '' && phoneNumber != '' && customerName != '' && grievanceDetails != '' && department != '') {


            try {
                //string customerType, int membershipId, string emailId, string phoneNumber, string customerName, string grievanceDetails, string department)

                //const strGetGrievanceUrl = '/grievance/grievance-data';
                
                const strPostGrievanceUrl = 'grievance/submit-grievance';
                  //  + customerType + '&membershipId=' + membershipId + "&emailId=" + emailId + "&phoneNumber=" + phoneNumber + "&customerName=" + customerName + "&grievanceDetails=" + grievanceDetails + "&deptId=" + department + "&entityDetails=" + entityDetails;

                console.log("strURL = " + strPostGrievanceUrl);
       
                formData.append('customerType', customerType.toString())
                formData.append('membershipId', membershipId)
                formData.append('emailId', emailId)
                formData.append('phoneNumber', phoneNumber)
                formData.append('customerName', customerName)
                formData.append('grievanceDetails', grievanceDetails)
                formData.append('deptId', department)
                formData.append('entityDetails', entityDetails)
                formData.append('fileExtension', fileSelected ? getExtension(fileSelected.name) : '-')


                await fetch(strPostGrievanceUrl, {
                    method: 'POST',
                    headers: {
                        'Authorization': `${sessionStorage.getItem('jwt')}`,
                        //'Content-Type': 'multipart/formdata'
                        //'Content-Type': 'application/json'
                         Accept: "multipart/form-data"
                    },
                    body:formData
                }).then(response => {
                    if (response.status == 401) {
                        alert("Session expired!")
                    } else if (response.status == 415) {
                        alert("File Upload blocked");
                    }
                    else if (response.status == 200) {
                        return response.text()
                    } else {
                        alert("Failed to submit Grievance")
                    }
                }).then(data => {
                    console.log(data);
                    //Logic change to get all the data to be posted..
                    //TODO
                    if (data) {
                        if (String(data) !== "false") {
                            setOpen(false);
                            //Get All parameters to display on summary page 
                            //alert("Grievance Submitted")
                            sessionStorage.setItem("GrievanceId", data);
                            //if (fileSelected != null) {
                            //    //importFile();
                            //} else {
                                navigate("/GrievanceSubmissionSummary")
                            //}
                        }
                    } else {
                        // enter your logic - when there is an error (eg. error toast)
                        setOpen(false);
                        alert("Failed to submit grievance!")
                    }
                })

            } catch (error) {
                setOpen(false);
                alert("Something went wrong")
                console.log("error : " + error);
            };
        }
        else {
            if (fileError != null) {
                alert(fileError);
            } else {
                alert("Kindly fill all the required details!")
            }
        }
    }
    const saveFileSelected = (e) => {
        //in case you wan to print the file selected
        //console.log(e.target.files[0]);
        setFileSelected(e.target.files[0]);  
        console.log("fileSelected ::: " + fileSelected)
       
    };
    function getExtension(filename) {
        
        return filename.toString().split('.').pop()
    }
    const importFile = async () => {
        const formData = new FormData();
        formData.append("file", fileSelected);
        const grievanceNumber = sessionStorage.getItem("GrievanceId");
        //const grievanceNumber ='TNO1';

        //const strUploadFileUrl = 'file/upload-file?grievanceNumber=' + grievanceNumber + "&formData=" + formData;
        const strUploadFileUrl = 'file/upload-file?grievanceNumber='+grievanceNumber; 
        console.log("strUploadFileUrl = " + strUploadFileUrl);

        try {
            await fetch(strUploadFileUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`,
                },
                body: formData,
            }).then(response => {
                return response.text()
            }).then(data => {
                console.log(data);
                //Logic change to get all the data to be posted..
                if (data ==="File Upload Successful") {
                    //Get All parameters to display on summary page
                    //alert("File Uploaded")
                    console.log("File Upload successful.")
                    //localStorage.setItem("GrievanceID", data);
                    //importFile();
                    navigate("/GrievanceSubmissionSummary")
                } else {
                    // enter your logic - when there is an error (eg. error toast)
                    if (fileError != null) {
                        alert(fileError);
                    }
                    else if (data === "File size should be less than 2MB") {
                        alert("File size should be less than 2MB!");
                    }
                    else if (data === "File upload blocked.") {
                        alert("File upload blocked.");
                    }
                    else {
                        alert("Failed to upload file!")
                    }
                }
            })
            //const res = await axios.post("grievance/submit-grievance/uploadFile", formData);
        } catch (ex) {
            console.log("Error in file upload : "+ex);
        }
    };

    return (
        <div>
            <Navbar />
            <div class="nav-path-align" ><h4>

                <a href="/" style={{ 'color': 'black' }}>Home<GrNext style={{ 'margin-bottom': '2px', 'size': '10px' }} /></a>
                <a href="/Track_Grievance" style={{ 'color': 'black' }}>Post Grievance</a>
            </h4>
            </div>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {/*<h3>Post Grievance</h3>*/}
             <hr></hr>
            <div class="grid-container2">

                
               
                <div class="grid-item2"> <p className="required distance-between bold d-inline">Name of the person raising the Grievance</p>
                    <BiSolidHelpCircle title="Your Name" className="help-btn d-inline" />
                </div>
                <div class="grid-item2">  <MDBInput minlength="5" maxlength="100" required wrapperClass='mb-3' label='Enter Name' onChange={(e) => setCustomerName(e.target.value)} /></div>              
                <div class="grid-item2 "> <p className="distance-between bold  d-inline ">Your Organization Name </p> <BiSolidHelpCircle title="Enter entity details such as name of the entity." className="help-btn d-inline" /></div>
                <div class="grid-item2">  <MDBInput minlength="5" maxlength="100"  label='Enter Name of the Entity' onChange={(e) => setEntityDetails(e.target.value)} />

                </div>

                <div class="grid-item2">  <p className="required distance-between bold d-inline ">Select Your Grievance For Department / System</p> <BiSolidHelpCircle title="Select Department/System name." className="help-btn d-inline" /></div>

                <div class="grid-item2">

                   
                    <select class="select-option mb-4 browser-default custom-select" onChange={(e) => setDepartment(e.target.value)}>
                        <option selected>Select Department / System</option>
                        {departmentData && departmentData.map((dept) => (
                            <option value={dept.deptId}>{dept.departmentName}</option>
                        )
                        )}
                        {/*<option value="22">Fx-Clear</option>*/}
                        {/*<option value="3">Fx-Retail</option>*/}
                        {/*<option value="5">LEIL</option>*/}
                        {/*<option value="6">Membership</option>*/}
                        {/*<option value="7">Accounts</option>*/}
                        {/*<option value="8">Derivatives</option>*/}
                        {/*<option value="9">Economic Research</option>*/}
                        {/*<option value="10">Economic Research & Surveillance, Membership & HRD</option>*/}
                        {/*<option value="2">Information Technology</option>*/}
                        {/*<option value="11">Finance & Accounts</option>*/}
                        {/*<option value="12">Forex Settlement</option>*/}
                        {/*<option value="13">Legal, Secretarial & Compliance</option>*/}
                        {/*<option value="14">NDS Operations</option>*/}
                        {/*<option value="15">Operations - Fixed Income and Money Market</option>*/}
                        {/*<option value="16">Product Development</option>*/}
                        {/*<option value="17">Risk Management</option>*/}
                        {/*<option value="18">Surveillance</option>*/}
                        {/*<option value="4">Security Consultant</option>*/}
                        {/*<option value="19">Internal Audit</option>*/}
                        {/*<option value="20">Human Resources & Administration Department</option>*/}
                        {/*<option value="21">Other</option>*/}

                    </select>
                </div>

                <div class="grid-item2"><div className="distance-between bold d-flex justify-content-between  mb-4">
                    <div class="form-check">
                        <label class="form-check-label" for="flexRadioDefault1">If you are an Existing CCIL<br></br>Customer / Member please select</label>
                        <BiSolidHelpCircle title="Enter Member ID / Customer ID if available. Only / & % - ( ) $ ? these special characters are allowed." className="help-btn d-inline" ></BiSolidHelpCircle>
                        <input minlength="5" class="form-check-input d-inline btn-mr-top" type="checkbox" name="flexRadioDefault" onClick={handleRadioButtonClick} id="flexRadioDefault1" />
                    </div>

                </div></div>
                {customerType &&
                    <div class="grid-item2">{error && <p className="my-4 d-inline" style={{ color: 'red' }}>{error}</p>}

                        <MDBInput minlength="5" maxlength="20" required wrapperClass='mb-3' label='Enter Membership ID / Customer ID' onKeyDown={(e) => checkSpecialChar(e)} onChange={(e) => setMembershipId(e.target.value)} />
                    </div>}
                {!customerType &&
                    <div class="grid-item2" id="blank-div-to-show-hide"></div>}
                <div class="grid-item2">
                    <p className="required distance-between bold d-inline">Enter Your Grievance</p>
                    <BiSolidHelpCircle title="Enter details of your grievance. Only / & % - ( ) $ ? these special characters are allowed." className="help-btn d-inline" />
                 
                </div>
                <div class="grid-item2"> <div class="form-group green-border-focus">
                    <textarea maxlength="250" class="form-control" id="exampleFormControlTextarea5" rows="2" onChange={(e) => setGrievanceDetails(e.target.value)}></textarea>
                    Max. <b>250</b> characters allowed, Kindly write the detailed complaint in the above text box. The attchments would be considered only as supporting documents
                </div>
                </div>
                <div class="grid-item2">  <p className="distance-between bold">Additional Details / File <BiSolidHelpCircle title="SampleHelpText : Additional Grievance Details." className="help-btn d-inline" />
                    <br></br>(File must be less than 2 MB) 
</p>
                </div>
                <div class="grid-item2">   <div class="input-group-justify-left">
                    <div class="input-group-prepend" >
                        {/*<span class="input-group-text" id="inputGroupFileAddon01">Upload</span>*/}
                    </div>
                    <div class="custom-file border-div">
                        <input type="file" accept=".jpg, .png, .pdf, .doc, .PNG" onChange={saveFileSelected} class="custom-file-input" id="inputGroupFile01"
                            aria-describedby="inputGroupFileAddon01" />
                        {/*<label class="custom-file-label" for="inputGroupFile01">Choose file</label>*/}
                    </div> 
                </div> <p>(Allowed file types: jpg, png, pdf, doc, PNG)</p></div>

            </div>
            <div class="align-left" id="submit-cancel-btn">

                <MDBBtn type="submit" onClick={onSubmitClick} tooltip="hello" className="">Submit</MDBBtn>
                <MDBBtn type="cancel" className="mrleft-1" onClick={onClearClick}>Cancel</MDBBtn>

            </div>
      
               </div>
                 
            
    )
}
export default PostGrievance;
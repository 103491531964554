import React, { useEffect } from 'react';
import { useRef } from 'react';
import './GenerateReport.css';
import { useState } from 'react';
import { FaFile } from "react-icons/fa";
import { MDBBtn } from 'mdb-react-ui-kit';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { GrNext } from "react-icons/gr"; //'>' icon
import Cookies from 'js-cookie';

//import { MDBDatePicker4 } from 'mdb-react-ui-kit';

import Navbar from '../Navbar.js';
import { FaThumbsUp, FaSort } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { VscCalendar } from "react-icons/vsc";
import dayjs from 'dayjs';

//import { moment } from 'moment';

//import * as mdb from 'mdb-react-ui-kit';
//import  DataTable from 'react-data-table-component';

//import * as mdb from 'mdb-react-ui-kit';
//import { MDBCheckbox } from "../../../../node_modules/mdb-react-ui-kit/dist/types/index-free";


//Table Components Material UI
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
//import FirstPageIcon from '@mui/icons-material/FirstPage';
//import LastPageIcon from '@mui/icons-material/LastPage';

//import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
//import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { SlControlStart } from "react-icons/sl";
import { SlControlEnd } from "react-icons/sl";
import { TfiControlSkipBackward } from "react-icons/tfi";
import { TfiControlSkipForward } from "react-icons/tfi";

import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";


//Table functions : 
function TablePaginationActions(props) {
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <SlArrowRight /> : <TfiControlSkipBackward />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <SlArrowLeft /> : <IoIosArrowBack />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <SlControlStart /> : <IoIosArrowForward />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <SlControlStart /> : <TfiControlSkipForward />}
            </IconButton>
        </Box>
    );
};

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
    return { name, calories, fat };
};

const rows = [
    createData('Cupcake', 305, 3.7),
    createData('Donut', 452, 25.0),
    createData('Eclair', 262, 16.0),
    createData('Frozen yoghurt', 159, 6.0),
    createData('Gingerbread', 356, 16.0),
    createData('Honeycomb', 408, 3.2),
    createData('Ice cream sandwich', 237, 9.0),
    createData('Jelly Bean', 375, 0.0),
    createData('KitKat', 518, 26.0),
    createData('Lollipop', 392, 0.2),
    createData('Marshmallow', 318, 0),
    createData('Nougat', 360, 19.0),
    createData('Oreo', 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

export default function GenerateReport() {
    const [showComponent, setShowComponent] = useState(false);
    const [hideComponent, setHideComponent] = useState(true);
    const [grievance, setGrievance] = useState('')
    const [ticketNumber, setTicketNumber] = useState('')
    const [tno, setTno] = useState('')
    const [comment, setComment] = useState('') //Comments from Internal User Regarding Grievance
    const [grievanceStatus, setGrievanceStatus] = useState('') //Status : Updated by Internal User

    const [singleGrievance, setSingleGrievance] = useState('')
    const [grievanceAcknowledgement, setGrievanceAcknowledged] = useState('False') //Status : Updated by Internal User
    //set filter for filtering data to display in table and for report generation
    const [filterGrievanceStatus, setFilterGrievanceStatus] = useState('') //Status : Updated by Internal User
    const [filterAcknowledgementStatus, setFilterAcknowledgementStatus] = useState('') //Status : Updated by Internal User
    const [filterCustomerStatus, setFilterCustomerStatus] = useState('') //Status : Updated by Internal User
    const [filterDuration, setFilterDuration] = useState('') //Status : Updated by Internal User

    const [sortBy, setSortBy] = useState('grievanceNumber')
    const navigate = useNavigate();
    const moment = require('moment')
    /////////////////////
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const refSelectBoxClear = useRef(null);
    const refSelectBoxClearDate = useRef(null);
    const refSelectBoxClearAcknowledgementStatus = useRef(null);
    const refSelectBoxClearCustomerStatus = useRef(null);
    //const refSelectBoxClearDate = useRef(null);

    const [dateInputStartDate, setDateInputStartDate] = React.useState(null);
    const [dateInputEndDate, setDateInputEndDate] = React.useState(null);


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    ////////////////////////
    //hides component if shown, reveals if not shown
    const handleClickClearFilter=()=> {
        setFilterGrievanceStatus('');
        setFilterAcknowledgementStatus('');
        setFilterCustomerStatus('');
        setFilterDuration('');
        setDateInputStartDate(null);
        setDateInputEndDate(null);
        refSelectBoxClear.current.value = 'none';
        refSelectBoxClearDate.current.value = 'none';
        refSelectBoxClearAcknowledgementStatus.current.value = 'none';
        refSelectBoxClearCustomerStatus.current.value = 'none';

        //window.location.reload();


        //navigate("/GenerateReport")
        // e.preventDefault();
    } //hides component if shown, reveals if not shown

    useEffect(() => {
        //All department related grievances will be displayed.
        if ("InternalUserId" in sessionStorage) {
            asyncGetGrievanceByEmpCode();
        }
        console.log("dateInputStartDate :: ", dateInputStartDate)
        console.log(dateInputStartDate)

        console.log("dateInputEndDate :: " + dateInputEndDate)

    }, []);

    const asyncGetGrievanceByEmpCode = async () => {

        try {
            //string customerType, int membershipId, string emailId, string phoneNumber, string customerName, string grievanceDetails, string department)
            const strGetAllGrievanceUrl = '/grievance/grievance-data-all?empCode=' + sessionStorage.getItem("InternalUserId");
            //const strGetGrievanceUrl = '/grievance/grievance-data';
            console.log("strURL = " + strGetAllGrievanceUrl);

            await fetch(strGetAllGrievanceUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`,
                }
            }).then(response => {
                if (response.status == 401) {
                    alert("Session expired!")
                } else {
                    return response.json()
                }                return response.json()
            }).then(data => {
                console.log("data", data);
                setGrievance(data);
                //console.log("key = " + data.)
                
                const columnNames = Object.keys(data[0]);
                console.log("columnNames : " + columnNames)
            });

        } catch (error) {
            
            alert("Something went wrong")
            console.log("error : " + error);
        };
    }
  
    //To Generate Report
    // const [filterGrievanceStatus, setFilterGrievanceStatus] = useState('') //Status : Updated by Internal User
    //const [filterAcknowledgementStatus, setFilterAcknowledgementStatus] = useState('') //Status : Updated by Internal User
    //const [filterCustomerStatus, setFilterCustomerStatus] = useState('') //Status : Updated by Internal User
    //const [filterDuration, setFilterDuration] = useState('') //Status : Updated by Internal User

    const handleGenerateReport = async () => {

        try {
            //generate - excel - report2
           // const strSubmitRemarksAndStatus = 'report/generate-excel-report?empCode=' + localStorage.getItem("InternalUserId") + '&filterGrievanceStatus=' + filterGrievanceStatus + '&filterAcknowledgementStatus=' + filterAcknowledgementStatus + '&filterCustomerStatus=' + filterCustomerStatus + '&filterDuration=' + filterDuration+'&filterStartDate='+dateInputStartDate+'&filterEndDate='+dateInputEndDate;
            const strSubmitRemarksAndStatus = 'report/generate-excel-report?empCode=' + sessionStorage.getItem("InternalUserId") + '&filterGrievanceStatus=' + filterGrievanceStatus + '&filterAcknowledgementStatus=' + (filterAcknowledgementStatus) + '&filterCustomerStatus=' +filterCustomerStatus+ '&filterDuration=' + filterDuration + '&filterStartDate=' + dateInputStartDate + '&filterEndDate=' + dateInputEndDate;
            //const data;
            //data.append('empCode', localStorage.getItem("InternalUserId"));
            //data.append('filterGrievanceStatus', filterGrievanceStatus);
            //data.append('filterAcknowledgementStatus', filterAcknowledgementStatus);
            //data.append('filterCustomerStatus', filterCustomerStatus);
            //data.append('filterDuration', filterDuration);
            //data.append('filterStartDate',toString( dateInputStartDate));
            //data.append('filterEndDate', toString(dateInputEndDate));
            //console.log("data = " + data);
            //const strSubmitRemarksAndStatus = 'report/generate-excel-report';
            //console.log("strURL = " + strSubmitRemarksAndStatus);
             await fetch('/report/generate-excel-report', {
                method: 'POST',
                 headers: {
                     'Accept': 'application/json',
                     'Content-Type': 'application/json'
                     //,
                     //'Authorization': `${Cookies.get('jwt')}`,

                 },
                 body: JSON.stringify({
                     empCode: sessionStorage.getItem("InternalUserId"),
                     filterGrievanceStatus: filterGrievanceStatus,
                     filterAcknowledgementStatus: filterAcknowledgementStatus,
                     filterCustomerStatus: filterCustomerStatus,
                     filterDuration: filterDuration,
                     filterStartDate: dayjs(dateInputStartDate),
                     filterEndDate: dayjs(dateInputEndDate)
                 })
            })
            //Uncomment this code to handle file download response
                .then((response) => {
                if (!response.ok) {
                    throw new Error('File not found');
                }
                return response.blob();
            })
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const a = document.createElement('a');
                    a.href = url;

                    a.download = "GeneratedSampleReport.xlsx";
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })


            // .then((blob) => {
            //    const url = window.URL.createObjectURL(new Blob([blob]));
            //    const a = document.createElement('a');
            //    a.href = url;

            //     a.download = "newsanchitaFileName.xlsx";
            //    document.body.appendChild(a);
            //    a.click();
            //    window.URL.revokeObjectURL(url);
            //})
            //.catch((error) => {
            //    console.error('Error:', error);
            //});

        } catch (error) {
            alert("Something went wrong")
            console.log("error : " + error);
        };
    }
    return (
        <div>
            <Navbar />
            <div class="nav-path-align" ><h4>

                <a href="/" style={{ 'color': 'black' }}>Home<GrNext style={{ 'margin-bottom': '2px', 'size': '10px' }} /></a>
                <a href="/GenerateReport" style={{ 'color': 'black' }}>Generate Report</a>
            </h4>
            </div>
            <hr />
            <div id="div-align-center">

                <div class="input-group">
                    <div class="form-control-lg ">
                    
                        <LocalizationProvider  dateAdapter={AdapterDayjs}>
                       
                            <DemoContainer components={['DatePicker']}>
                                
                                <DatePicker  value={dateInputStartDate} onChange={(newValue) => setDateInputStartDate(newValue)} label="Select Start Date" sx={{ color: 'white' }}> <label>Select Start Date</label></DatePicker>
                               
                            </DemoContainer>
                            
                            </LocalizationProvider>
                        
            </div>
            <div class=" form-control-lg" style={{ 'color': 'red' }}>
                <LocalizationProvider sx={{ bgcolor: 'red' }} className="" dateAdapter={AdapterDayjs}>
                    <DemoContainer sx={{ 'color': 'red' }} components={['DatePicker']}>
                                <DatePicker value={dateInputEndDate} onChange={(newValue) => setDateInputEndDate(newValue)} sx={{ 'color': 'red' }} label="Select End Date" />
                    </DemoContainer>
                </LocalizationProvider>
                    </div>
            </div></div>
            <div id="div-align-center">

                <div class="input-group">

                    {/*<DropdownButton id="dropdown-basic-button"  title="Sample Dropdown">*/}
                    {/*    <Dropdown.Item>Item1</Dropdown.Item>*/}
                    {/*    <Dropdown.Item>Item2*/}
                    {/*    </Dropdown.Item></DropdownButton>*/}
                   
                    <div class="">

                        <select ref={refSelectBoxClear} 
                            onChange={(e) => setFilterGrievanceStatus(e.target.value)} 
                        ><option value="none" selected disabled hidden>Select Grievance status
                            </option>
                            <option  value="Submitted">Submitted</option>
                            <option value="In-Progress">Under Process</option>
                            <option value="Resolved">Resolved</option>
                            <option value="Closed">Closed</option>
                            <option value="">All</option>
                            {/*<option value="">None</option>*/}

                        </select>
                    </div>
                    <div class="">
                        <select ref={refSelectBoxClearAcknowledgementStatus} class=" mrleft form-control-lg" 
                            onChange={(e) => setFilterAcknowledgementStatus(e.target.value)}
                        ><option value="none" selected disabled hidden>Select Acknowledgement Status
                            </option>
                            <option value="True">Acknowledged Grievances</option>
                            <option value="No">Unacknowledged Grievances</option>
                            <option value="">All</option>
                        </select>
                    </div>
                    <div class="">
                       

                        <select ref={refSelectBoxClearDate} class="mrleft form-control-lg" 
                        onChange={(e) => setFilterDuration(e.target.value)}
                        ><option  value="none" selected disabled hidden>Select Days
                            </option>
                            <option value="7">Last 7 days</option>
                            <option value="14">Last 14 days</option>
                            <option value="30">One Month</option>
                            <option value="71">six Months</option> {/*//TODO : Change number of days from 71=>183*/}
                            <option value="365">One year</option> 
                            <option value="">All<VscCalendar/> </option>
                            {/*<option value="">None</option>*/}
                            <VscCalendar /> 
                        </select>
                    </div>
                    <div class="">
                        <select ref={refSelectBoxClearCustomerStatus} class="mb-4 mrleft form-control-lg" 
                        onChange={(e) => setFilterCustomerStatus(e.target.value)}
                        > <option value="none" selected disabled hidden>Customer Feedback
                            </option>
                            <option value="Satisfied">Satisfied</option>
                            <option value="Dissatisfied">Dissatisfied</option>
                            <option value="">All</option>
                            {/*<option value="">None</option>*/}

                        </select>
                    </div>
                  
                </div>
              
            </div>
            
            <br />
            <MDBBtn onClick={handleClickClearFilter }>Reset Filters</MDBBtn>
            <div className="mrleft d-inline">

                    <MDBBtn onClick={handleGenerateReport}>Generate Excel Report</MDBBtn>
                {/*<link to="/FileName1.xlsx" target="_blank" downloads>Download</link>*/}

            </div>
            <br/>
            <hr/>

            {/*{hideComponent &&*/}
            {/*    <>*/}
            {/*        <TableContainer component={Paper}>*/}

            {/*            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">*/}

            {/*                <TableHead>*/}

            {/*                    <TableRow>*/}
            {/*                        <TableCell >Ticket Number <FaSort onClick={() => setSortBy('grievanceNumber')} />*/}
            {/*                        </TableCell>*/}
            {/*                        <TableCell>*/}
            {/*                            Name <FaSort onClick={() => setSortBy('customerName')} />*/}
            {/*                        </TableCell>*/}
            {/*                        <TableCell>*/}
            {/*                            Reported Date <FaSort onClick={() => setSortBy('createdDate')} />*/}
            {/*                        </TableCell>*/}
            {/*                        <TableCell>*/}
            {/*                            Grievance Details <FaSort onClick={() => setSortBy('grievanceDetails')} />*/}
            {/*                        </TableCell>*/}
            {/*                        <TableCell>*/}
            {/*                            Status <FaSort onClick={() => setSortBy('grievanceStatus')} />*/}
            {/*                        </TableCell>*/}
            {/*                        <TableCell>*/}
            {/*                            Existing CCIL Member/Customer*/}
            {/*                        </TableCell>*/}
            {/*                        <TableCell>*/}
            {/*                            Customer Feedback <FaSort onClick={() => setSortBy('customerStatus')} />*/}
            {/*                        </TableCell>*/}
            {/*                    <TableCell>*/}
            {/*                        Customer Feedback Remarks*/}
            {/*                    </TableCell>*/}
            {/*                    <TableCell>*/}
            {/*                        CCIL Comment*/}
            {/*                    </TableCell>*/}
            {/*                    </TableRow>*/}
            {/*                </TableHead>*/}
            {/*                <TableBody>*/}
            {/*                {grievance && grievance?.sort((a, b) => {*/}
            {/*                    if (sortBy == 'customerName') {*/}
            {/*                        if (a.customerName < b.customerName) {*/}
            {/*                            return -1*/}
            {/*                        }*/}
            {/*                        if (a.customerName > b.customerName) {*/}
            {/*                            return 1*/}
            {/*                        }*/}
            {/*                    }*/}
            {/*                    else if (sortBy == 'grievanceNumber') {*/}
            {/*                        if (a.grievanceNumber < b.grievanceNumber) {*/}
            {/*                            return -1*/}
            {/*                        }*/}
            {/*                        if (a.grievanceNumber > b.grievanceNumber) {*/}
            {/*                            return 1*/}
            {/*                        }*/}
            {/*                    } else if (sortBy == 'createdDate') {*/}
            {/*                        if (a.createdDate < b.createdDate) {*/}
            {/*                            return -1*/}
            {/*                        }*/}
            {/*                        if (a.createdDate > b.createdDate) {*/}
            {/*                            return 1*/}
            {/*                        }*/}
            {/*                    } else if (sortBy == 'customerType') {*/}
            {/*                        if (a.customerType < b.customerType) {*/}
            {/*                            return -1*/}
            {/*                        }*/}
            {/*                        if (a.customerType > b.customerType) {*/}
            {/*                            return 1*/}
            {/*                        }*/}
            {/*                    } else if (sortBy == 'grievanceStatus') {*/}
            {/*                        if (a.grievanceStatus < b.grievanceStatus) {*/}
            {/*                            return -1*/}
            {/*                        }*/}
            {/*                        if (a.grievanceStatus > b.grievanceStatus) {*/}
            {/*                            return 1*/}
            {/*                        }*/}
            {/*                    } else if (sortBy == 'grievanceDetails') {*/}
            {/*                        if (a.grievanceDetails < b.grievanceDetails) {*/}
            {/*                            return -1*/}
            {/*                        }*/}
            {/*                        if (a.grievanceDetails > b.grievanceDetails) {*/}
            {/*                            return 1*/}
            {/*                        }*/}
            {/*                    }*/}
            {/*                    return 0*/}
            {/*                }) &&*/}
            {/*                    (rowsPerPage > 0*/}
            {/*                        ? grievance.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/}
            {/*                        : grievance*/}
            {/*                    )?.map((row) => (*/}
            {/*                        <>*/}
            {/*                        {(filterGrievanceStatus == '' || filterGrievanceStatus == row.grievanceStatus) &&*/}
            {/*                        //(filterAcknowledgementStatus == '' || null == d?.grievanceAcknowledgement) &&*/}
            {/*                        (filterDuration == '' || (filterDuration > (moment().diff(row.createdDate, 'days')))) &&*/}
            {/*                        (filterCustomerStatus == '' || filterCustomerStatus == row.customerStatus) &&*/}

            {/*                        <TableRow>*/}

            {/*                            <TableCell style={{ width: 120 }} >*/}
            {/*                                <p class="fw-normal mb-1" onChange={(e) => setTno(e.target.value)}>{row.grievanceNumber}</p>*/}
            {/*                            </TableCell>*/}

            {/*                            <TableCell style={{ width: 120 }} >*/}
            {/*                                <div class="d-flex align-items-center">*/}

            {/*                                    <div class="ms-3">*/}
            {/*                                        <p class="fw-bold mb-1">{row.customerName}</p>*/}
            {/*                                        <p class="text-muted mb-0">{row.emailId}</p>*/}
            {/*                                    </div>*/}
            {/*                                </div>                                </TableCell>*/}
            {/*                            <TableCell style={{ width: 120 }} >*/}
            {/*                                <p class="fw-bold mb-1">{row.createdDate}</p>*/}
            {/*                            </TableCell>*/}
            {/*                            <TableCell style={{ width: 120 }} >*/}
            {/*                                <p class="fw-normal mb-1">{row.grievanceDetails}</p>*/}
            {/*                            </TableCell>*/}
            {/*                            <TableCell style={{ width: 120 }} >*/}
            {/*                                <span class="badge badge-success rounded-pill d-inline">{row.grievanceStatus}</span>*/}
            {/*                            </TableCell>*/}
            {/*                            <TableCell style={{ width: 120 }} >*/}
            {/*                                {row.membershipId}*/}
            {/*                            </TableCell>*/}
            {/*                            <TableCell style={{ width: 120 }} >*/}
            {/*                                {row.customerStatus}*/}
            {/*                            </TableCell>*/}
            {/*                            <TableCell style={{ width: 120 }} >*/}
            {/*                                {row.customerRemark}*/}
            {/*                            </TableCell>*/}
            {/*                            <TableCell style={{ width: 120 }} >*/}
            {/*                                {row.comment}*/}
            {/*                            </TableCell>*/}
            {/*                        </TableRow>*/}
            {/*                            }*/}
            {/*                            </>*/}
            {/*                         )) }*/}
            {/*                            {emptyRows > 0 && (*/}
            {/*                                <TableRow style={{ height: 0 * emptyRows }}>*/}
            {/*                                    <TableCell colSpan={6} />*/}
            {/*                                </TableRow>*/}
            {/*                            )}*/}
            {/*                        </TableBody >*/}
                            
            {/*                <TableFooter>*/}
            {/*                    <TablePagination*/}
            {/*                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}*/}
            {/*                        colSpan={3}*/}
            {/*                        count={grievance.length}*/}
            {/*                        rowsPerPage={rowsPerPage}*/}
            {/*                        page={page}*/}
            {/*                        SelectProps={{*/}
            {/*                            inputProps: {*/}
            {/*                                'aria-label': 'rows per page',*/}
            {/*                            },*/}
            {/*                            native: true,*/}
            {/*                        }}*/}
            {/*                        onPageChange={handleChangePage}*/}
            {/*                        onRowsPerPageChange={handleChangeRowsPerPage}*/}
            {/*                        ActionsComponent={TablePaginationActions}*/}
            {/*                    />*/}
            {/*                </TableFooter>*/}
            {/*            </Table>*/}
            {/*        </TableContainer>*/}
            {/*    </>*/}
            {/*}*/}

            {hideComponent &&
                <div>
                <table class="table mb-0 bg-white">
                        <thead class="bg-light" style={{ 'font-weight':'bold' } }>
                            <tr >
                                <th style={{ 'font-weight': 'bold' }}>Ticket Number <FaSort onClick={() => setSortBy('grievanceNumber')} /></th>
                                <th style={{ 'font-weight': 'bold' }}>Reported Date <FaSort onClick={() => setSortBy('createdDate')} /></th>
                                <th style={{ 'font-weight': 'bold' }}>Name <FaSort onClick={() => setSortBy('customerName')} /></th>
                                <th style={{ 'font-weight': 'bold' }}>Grievance Details <FaSort onClick={() => setSortBy('grievanceDetails')} /></th>
                                <th style={{ 'font-weight': 'bold' }}>Status <FaSort onClick={() => setSortBy('grievanceStatus')} /></th>
                                <th style={{ 'font-weight': 'bold' }}>Existing CCIL Member/Customer</th>
                            
                                <th style={{ 'font-weight': 'bold' }}>Customer Feedback <FaSort onClick={() => setSortBy('customerStatus')} /></th>
                                <th style={{ 'font-weight': 'bold' }}>Escalation Status</th>
                                <th style={{ 'font-weight': 'bold' }}>Customer Remarks</th>
                                <th style={{ 'font-weight': 'bold' }}>CCIL Comment</th>
                        </tr>
                        </thead>
                        {grievance && grievance?.sort((a, b) => {
                            if (sortBy == 'customerName') {
                                if (a.customerName < b.customerName) {
                                    return -1
                                }
                                if (a.customerName > b.customerName) {
                                    return 1
                                }
                            }
                            else if (sortBy == 'grievanceNumber') {
                                if (a.grievanceNumber < b.grievanceNumber) {
                                    return -1
                                }
                                if (a.grievanceNumber > b.grievanceNumber) {
                                    return 1
                                }
                            } else if (sortBy == 'createdDate') {
                                if (a.createdDate < b.createdDate) {
                                    return -1
                                }
                                if (a.createdDate > b.createdDate) {
                                    return 1
                                }
                            } else if (sortBy == 'customerStatus') {
                                if (a.customerStatus < b.customerStatus) {
                                    return -1
                                }
                                if (a.customerStatus > b.customerStatus) {
                                    return 1
                                }
                            } else if (sortBy == 'customerType') {
                                if (a.customerType < b.customerType) {
                                    return -1
                                }
                                if (a.customerType > b.customerType) {
                                    return 1
                                }
                            } else if (sortBy == 'grievanceStatus') {
                                if (a.grievanceStatus < b.grievanceStatus) {
                                    return -1
                                }
                                if (a.grievanceStatus > b.grievanceStatus) {
                                    return 1
                                }
                            } else if (sortBy == 'grievanceDetails') {
                                if (a.grievanceDetails < b.grievanceDetails) {
                                    return -1
                                }
                                if (a.grievanceDetails > b.grievanceDetails) {
                                    return 1
                                }
                            }
                            return 0
                        }).map(d => (
                            <>
                                {(filterGrievanceStatus == '' || filterGrievanceStatus == d?.grievanceStatus) &&
                                    //(filterAcknowledgementStatus == '' || null == d?.grievanceAcknowledgement) &&
                                    (filterDuration == '' || (filterDuration > (moment().diff(d?.createdDate, 'days')))) &&
                                    (filterCustomerStatus == '' || filterCustomerStatus == d?.customerStatus)  &&
                                    (filterAcknowledgementStatus == '' || filterAcknowledgementStatus == d?.grievanceAcknowledgement) &&
                                    (dateInputStartDate == null || dayjs(dateInputStartDate) <= dayjs(d?.createdDate)) &&
                                    (dateInputEndDate == null || dayjs(dateInputEndDate) >= dayjs(d?.createdDate)) &&

                                    <tbody>

                                        {/*//createdDate "2023-08-28T00:00:00"*/}
                                        {/*//customerName "Sanchita Mehetre"*/}
                                        {/*//customerType "Existing Ccil Member"*/}
                                        {/*//grievanceDetails "ryty"*/}
                                        {/*//grievanceNumber 334*/}
                                        {/*//grievanceStatus "Submitted"*/}
                                        {/*//membershipId 6765*/}
                                        <tr>
                                            <td style={{ 'max-width': '150px' }}>
                                                <p class="fw-normal mb-1" onChange={(e) => setTno(e.target.value)}>{d?.grievanceNumber}</p>
                                            </td>
                                            <td style={{ 'min-width': '140px' }}>
                                                <p class="fw-bold mb-2">{moment(d.createdDate)?.utc().format('DD/MM/YYYY')}</p>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center">

                                                    <div class="ms-3">
                                                        <p class="fw-bold mb-1">{d?.customerName}</p>
                                                        <p class="text-muted mb-0">{d?.emailId}</p>
                                                    </div>
                                                </div>
                                            </td>
                                          
                                            <td>
                                                <p class="mb-1">{d?.grievanceDetails}</p>
                                            </td>
                                            <td>
                                                <span class="badge badge-success rounded-pill d-inline">{d?.grievanceStatus}</span>
                                            </td>
                                            <td>{d?.customerType}</td>
                                      
                                           
                                            <td style={{ 'min-width': '140px' }}>{d?.customerStatus}
                                            </td>
                                            <td>
                                                {d?.escalationLevel == '1' &&
                                                    <> <p>Escalated to Level- <b>{d?.escalationLevel}</b>, In-Progress,
                                                    {d?.adminResponded == 'Yes' && <b style={{ color: "blue" }}> Action taken</b>}
                                                    {d?.adminResponded == 'No' && <b style={{ color: "red" }}> Action not taken</b>}
                                                    </p>
                                                    </>}
                                                {d?.escalationLevel == '2' &&
                                                    <>
                                                    <p>Escalated to Level- <b>{d?.escalationLevel}</b>, In-Progress,
                                                        {d?.adminResponded == 'Yes' && <b style={{ color: "blue" }}> Action taken</b>}
                                                        {d?.adminResponded == 'No' && <b style={{ color: "red" }}> Action not taken</b>}
                                                        </p>
                                                    </>}
                                                {d?.escalationLevel == '' && <><p>-</p></>}
                                            </td>
                                            <td >{d?.customerRemark}
                                            </td>
                                            <td>{d?.comment}
                                            </td>
                                        </tr>

                                    </tbody>
                                }
                       </>
                            
                        ))}
                </table>
                </div>
            }

        </div>

    );

}
import imageFile2 from './Images/img2.jpg';
import CcilLogo from './Images/CcilLogo.jfif';
import { VscServerProcess } from 'react-icons/vsc';
import { VscFeedback } from 'react-icons/vsc';
import Navbar from './Navbar';
import './Home.css';
import RegisterInternalUser from './Registration/RegisterInternalUser';

//
import * as React from 'react';
import { GrNext } from "react-icons/gr"; //'>' icon

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';

function HomePage() {
    //const navigate = useNavigate();

    //const [isToggled, setIsToggled] = useNavigate(false);

    //OLD Navigation Bar
    //const homeHandleClick = () => {
    //    navigate("/HomePage");
    //}
    //const postGrievanceHandleClick= () => {
    //    navigate("/Post_Grievance");
    //}
    //const trackGrievanceHandleClick = () => {
    //    navigate("/Track_Grievance");
    //}
    //const helpHandleClick = () => {
    //    //change to help page/information
    //    navigate("/HomePage");
    //}
    //const handleLogoutBtnClick = () => {
    //    localStorage.removeItem("EMPCODE");
    //    localStorage.clearterms

    //    console.log("Session data cleared..");
    //}
    //const handleSubmit = () => {
    //    const validate = true;
    //    if (validate) {
    //        navigate("/HomePage")
    //    }
    //}
  //function trackGrievanceHandleClick() {
  //  navigate("/track");
  //}
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const navigate = useNavigate();

    //RegisterDialog
    const [openRegisterDialog, setOpenRegisterDialog] = React.useState(false);

    const asyncGetTokenByUserName = async () => {
       
            const strLoginInternalUserUrl = 'home/loginInternalUser';
            console.log("strLoginInternalUserUrl = " + strLoginInternalUserUrl);
            await fetch(strLoginInternalUserUrl, {
                method: 'POST'
            }).then(response => {
                //console.log("res", response)
                return response.text()
            }).then(data => {
                //console.log("dataa", data);
                //Logic change..
                if (String(data) !== "") {
                    //var user = '@HttpContext.Session.GetString("EMPCODE")';
                   // asyncGetEmployeeCodeByUsername();
                    sessionStorage.setItem("jwt", data);
                //    window.location.replace("/")
                } else {
                    // enter your logic - when there is an error (eg. error toast)
                    alert("User is not authenticated!")
                }
            })
                ;
        
    }
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        //RegisterDialog
        if ((window.location.hostname == "grievanceportal-dev.ccilindia.net"
            || window.location.hostname == "grievanceportal.ccilindia.net"
            || window.location.hostname == "localhost")) {
            asyncGetEmployeeCodeByUsernameHeader().then(

            )
        }
       
        
    }, []);
    //For Internal user to set Id in sessionStorage
    const asyncGetEmployeeCodeByUsernameHeader = async () => {
        const strGetEmpCodeUrl = 'home/getEmployeeCode';

        await fetch(strGetEmpCodeUrl, {
            method: 'POST'
        }).then(response => {
            //console.log("res", response)
            if (response.status == 200) {
                return response.text()
            }
            else if (response.status == 204) {
                setOpenRegisterDialog(true)
            }
        }).then(data => {
            if (data) {
                sessionStorage.setItem("InternalUserId", data);
                setOpenRegisterDialog(false);
                let temp = "jwt" in sessionStorage;
                //console.log("valll: " + temp)
                if (!temp
                    && (window.location.hostname == "grievanceportal-dev.ccilindia.net"
                    || window.location.hostname == "grievanceportal.ccilindia.net"
                    || window.location.hostname == "localhost"
                        && "InternalUserId" in sessionStorage
                    ) ){
                    asyncGetTokenByUserName();
                }
                if (open) {
                    const { current: descriptionElement } = descriptionElementRef;
                    if (descriptionElement !== null) {
                        descriptionElement.focus();
                    }
                }
                //sessionStorage.setItem("InternalUserName", userName);
                //console.log("SESSION : ");
                //console.log(sessionStorage);
                //navigate("/GrievanceDashboard")
                /*Show Grievance Summary dialog for Internal User*/
            //    handleClickOpen()
            }
        })
    }
    const handleCloseRegisterDialog = () => {
        setOpen(false);
    };
    const [registered, setRegistered] = useState('');
    //For getting response from Register component
    const childToParent = (childdata) => {
        setOpenRegisterDialog(!childdata)//Close dialog if Register Component returns True
        setRegistered(childdata)
    }
    return (
        <div>
            <Navbar />
            {sessionStorage.getItem("InternalUserId") == null &&
                <Dialog
                    fullWidth='false'
                    maxWidth='sm'
                    open={openRegisterDialog}
                    onClose={handleCloseRegisterDialog}
                    scroll='paper'
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description" >
                    <DialogTitle id="scroll-dialog-title">Register</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'} tabIndex={-1} id="scroll-dialog-description">
                        <RegisterInternalUser childToParent={childToParent} />
                    </DialogContent>
                    {/*<DialogActions>*/}
                    {/*    <Button onClick={handleCloseRegisterDialog} href="/">SUBMIT</Button>*/}
                    {/*    <Button onClick={handleCloseRegisterDialog} href="/">CANCEL</Button>*/}
                    {/*</DialogActions>*/}
                </Dialog>
            }
            {/*<div class="nav-path-align" ><h4>*/}

            {/*    <a href="/HomePage" style={{ 'color': 'black' }}>Home<GrNext style={{ 'margin-bottom': '2px', 'size': '10px' }} /></a>*/}
            {/*</h4>*/}
            {/*</div>*/}
            {/*<hr/>*/}
            <h1 className="title">Grievance Redressal Portal</h1>

            {/*Sub Navigation Bar starts*/}
            {/*<nav class="navbar navbar-light bg-light">*/}
            {/*    <div class="mrleft-nav container">*/}
            {/*        <a class="navbar-brand d-inline" href="#">*/}

            {/*            <AiOutlineMenu className="AiOutlineMenu-col-blue d-inline"*/}
            {/*                src={AiOutlineMenu}*/}
            {/*                height="20"*/}
            {/*                alt="Menu Logo"*/}
            {/*                ></AiOutlineMenu>*/}
            {/*        </a>*/}
            {/*            <ul class=" d-inline  me-auto mb-2 mb-lg-0">*/}
            {/*                <li class="d-inline nav-item active">*/}
            {/*                */}{/*<a class="" aria-current="page" href="/HomePage">HomePage</a>*/}
            {/*                <MDBBtn className='me-1' type="button" onClick={homeHandleClick} >*/}
            {/*                    HomePage*/}
            {/*                </MDBBtn>*/}
            {/*                </li>*/}
            {/*                <li class="d-inline">*/}
            {/*                */}{/*<a class="" href="/Post_Grievance">Post Grievance</a>*/}
            {/*                <MDBBtn className='me-1' type="button" onClick={postGrievanceHandleClick} >*/}
            {/*                    Post Grievance*/}
            {/*                </MDBBtn>*/}
            {/*                </li>*/}
            {/*                <li class="d-inline">*/}
            {/*                */}{/*<a class="" href="/Track_Grievance">Track Grievance</a>*/}
            {/*                <MDBBtn className='me-1' type="button" onClick={trackGrievanceHandleClick} >*/}
            {/*                    Track Grievance*/}
            {/*                </MDBBtn>*/}
            {/*            </li>*/}
            {/*            <li class="d-inline">*/}
            {/*                */}{/*<a class="" href="/Post_Grievance">Post Grievance</a>*/}
            {/*                <MDBBtn className='me-1' type="button" onClick={handleLogoutBtnClick} >*/}
            {/*                    Logout*/}
            {/*                </MDBBtn>*/}
            {/*            </li>*/}
            {/*            <li class="d-inline">*/}
            {/*                */}{/*<a class="navig-link" href="#">Help</a>*/}
            {/*                <MDBBtn className='me-1' type="button" onClick={helpHandleClick} >*/}
            {/*                    Help*/}
            {/*                </MDBBtn>*/}
            {/*            </li>*/}
            {/*            </ul>*/}

            {/*    </div>*/}
            {/*</nav>*/}

            {/*Sub Navigation Bar Ends*/}
            {/*TODO : Image*/}
            {/*<img class="bg HomeImage" src={imageFile2} alt="Sample image main"></img>*/}
            {/*<img className="bg HomeImage" src="img.jpg" alt="Sample image main"></img>*/}

            {/*<span className="spanclass">*/}
            {/*    <img src={imageFile} class="img-fluid" alt="Sample image main"></img>            </span>*/}
            {/*<img className="HomeImage" src={imageFile}  alt="Sample image main"></img>*/}
            {/*<img src="D:\work\VSProject\REACT\GrievancePortal\client\getting-started-react\public\HomePageSample_img.webp" class="img-fluid" alt="Sample image" />*/}

            {/*<MDBCol col='5' md='10'>*/}
            {/*    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp" class="img-fluid" alt="Sample image" />*/}
            {/*</MDBCol>*/}
            
            <div class="container my-5">
              
                {/*<!-- Section: Block Content -->*/}
                <section class="dark-grey-text">

                    <h3 class="align-left  font-weight-bold mb-4 pb-2">About Grievance Management System</h3>
                    {/*<p class="text-center text-muted w-responsive mx-auto mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur veniam.</p>*/}
                    <hr/>
                    {/*<!-- Grid row -->*/}
                    <div class="row">

                        {/*<!-- Grid column -->*/}
                        <div class="col-lg-3 mb-lg-0 mb-4">

                            <img src={CcilLogo} class="img-fluid rounded z-depth-1" alt="Sample project image"></img>
                        </div>

                        {/*<div class="col-lg-3 mb-lg-0 mb-4">*/}

                        {/*    <img src="https://mdbootstrap.com/img/Photos/Others/images/83.jpg" class="img-fluid rounded z-depth-1" alt="Sample project image"/>*/}
                        {/*</div>*/}
                        {/*<!-- Grid column -->*/}

                        {/*<!-- Grid column -->*/}
                        <div class="col-lg-7">

                            <ul class="list-unstyled fa-ul mb-0">
                                <li class="d-flex justify-content-center pl-4">
                                    <span class="fa-li"><i class="fas fa-book fa-2x cyan-text"></i></span>
                                    <div>
                                        <h5 class="font-weight-bold mb-3">Vision</h5>
                                        <p class="text-muted">"To be a resilient and compliant Financial Market Infrastructure offering secure and innovative products in a cost-effective and efficient manner."</p>
                                    </div>
                                </li>

                            </ul>

                        </div>
                        {/*<!-- Grid column -->*/}
                        {/*<img src={CcilLogo} class="img-fluid rounded z-depth-1" alt="Sample project image"></img>*/}

                    </div>
                    {/*<!-- Grid row -->*/}

                    <hr class="my-5" />
                    {/*<!-- Grid row -->*/}
                    <div class="row">

                        {/*<!-- Grid column -->*/}
                        <div class="col-lg-3 mb-lg-0 mb-4">
                            <VscServerProcess class="br-icon img-fluid rounded z-depth-1 fa-9x red-text" />
                            {/*<img src={CcilLogo} class="img-fluid rounded z-depth-1" alt="Sample project image"></img>*/}
                        </div>

                        <div class="col-lg-7">
                            <ul>
                                <li class="d-flex justify-content-center pl-4">
                                    <span class="fa-li"><VscServerProcess class="fa-2x red-text" /></span>
                                    <div>
                                        <h5 class="font-weight-bold mb-3">Process</h5>
                                        <p class="text-muted">The grievance can be filed under the appropriate category(Department). Once grievance has been submitted, a ticket number will be generated. Customer can track the status of the grievance with the help of ticket number. The generated grievance will be addressed by the competent department within a period of defined working days.</p>
                                    </div>
                                </li>

                            </ul>

                        </div>
                        {/*<!-- Grid column -->*/}

                    </div>
                    {/*<!-- Grid row -->*/}
                    <hr class="my-5" />
                    {/*<!-- Grid row -->*/}
                    <div class="row">

                        {/*<!-- Grid column -->*/}
                        <div class="col-lg-3 mb-lg-0 mb-4">
                            <VscFeedback class="br-icon img-fluid rounded z-depth-1 fa-9x red-text" />

                            {/*<img src={CcilLogo} class="img-fluid rounded z-depth-1" alt="Sample project image"></img>*/}
                        </div>

                        <div class="col-lg-7">
                            <ul>
                                <li class="d-flex justify-content-center pl-4">
                                    <span class="fa-li"><VscFeedback class="fa-2x red-text" /></span>
                                    <div>
                                        <h5 class="font-weight-bold mb-3">Feedback</h5>
                                        <p class="text-muted">Customer/Citizen can provide feedback for the quality of resolution of grievance through a "satisfied" / "Dissatisfied" option.</p>
                                    </div>
                                </li>

                            </ul>

                        </div>
                        {/*<!-- Grid column -->*/}

                    </div>
                    {/*<!-- Grid row -->*/}

                    <hr class="my-5" />

                </section>
                {/*<!-- Section: Block Content -->*/}


            </div>
            <br/>

            <br/>

        </div>
    );
}
export default HomePage;
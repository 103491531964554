import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

//import DrawerHeader from '@mui/material/DrawerHeader'
import IconButton from '@mui/material/IconButton'
//import InboxIcon from '@mui/icons-material/MoveToInbox';
//import MailIcon from '@mui/icons-material/Mail';
import { IoIosArrowForward } from "react-icons/io";
export default function ThemeDrawer() {
    const [state, setState] = React.useState('false');
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState(true);
    };

    const handleChangeTheme = (theme) => {
        if (theme) {
            localStorage.removeItem("Theme");
            localStorage.setItem("Theme", theme);
            console.log("Changing theme.." + theme)
        }
        window.location.reload();
    }
    const handleDrawerClose = () => {
        toggleDrawer(false)
        setOpen(false);
        //navigate("/HomePage");

    };
    
    React.useEffect(() => {
       //toggleDrawer(true)
    },[])
    return (
        <div >
            {open &&
                //className="nav-bg-col"
                <div >

                    <SwipeableDrawer
                        anchor='right'
                        open={state}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}

                    >
                        <Box className="right-nav-bg-color"
                            sx={{ width: 250, height: '100%',color:'white' }}
                            role="presentation"
                            onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)} >
                            <Button onClick={handleDrawerClose}>
                                <IconButton >
                                    <IoIosArrowForward style={{color:'white'}} />
                                </IconButton>
                            </Button>

                            <List className="right-nav-bg-color"
                                                sx={{ width: '100%', maxWidth: 360 }}
                                subheader={<ListSubheader class="bold right-nav-bg-color"><label class="bold right-nav-bg-color">Change Theme</label></ListSubheader>}>
                                    <ListItem disablePadding>
                                            <ListItemButton onClick={() => handleChangeTheme('Theme1')} href="">
                                          
                                                <ListItemText>Sage and pine green</ListItemText>

                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                            <ListItemButton onClick={() => handleChangeTheme('Theme2')} >
                                          
                                                Light blue and cobalt
                                            <ListItemText  />
                                        </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => handleChangeTheme('Theme3')} >
                                                
                                                Raspberry pink and dark chocolate
                                                <ListItemText />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => handleChangeTheme('Theme4')} >
                                               
                                                Very dark cyan
                                                <ListItemText />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => handleChangeTheme('Theme5')} >
                                             
                                                Scarlet, light olive, & light teal (classic)
                                                <ListItemText />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => handleChangeTheme('Theme6')} >
                                             
                                                Coral, spiced apple & peach (trending)
                                                <ListItemText />
                                            </ListItemButton>
                                        </ListItem>

                                </List>
                            
                            <Divider />
                          
                        </Box>
                    </SwipeableDrawer>



                {/*    <SwipeableDrawer className="nav-bg-col"*/}
                {/*    anchor='right'*/}
                {/*        open={state}*/}
                {/*    onClose={toggleDrawer(false)}*/}
                {/*    onOpen={toggleDrawer(true)}*/}
                {/*    ><div className="nav-bg-col">*/}
                {/*            <Box className="nav-bg-color"*/}
                {/*                sx={{ width: 250, height: '100%' }}*/}
                           
                {/*    role="presentation"*/}
                {/*    onClick={toggleDrawer(false)}*/}
                {/*    onKeyDown={toggleDrawer(false)}*/}
                {/*>*/}
                {/*        <Button onClick={handleDrawerClose}>*/}
                {/*        <IconButton  >*/}
                {/*            */}{/*{theme.direction === 'rtl' ? <IoIosArrowForward /> : <IoIosArrowForward />}*/}
                {/*            <IoIosArrowForward />*/}
                {/*        </IconButton>*/}
                {/*        </Button>*/}
                   
                {/*                <List className="nav-bg-color"*/}
                {/*                    sx={{ width: '100%', maxWidth: 360 }}*/}
                {/*                    subheader={<ListSubheader className="nav-bg-col">Change Theme</ListSubheader>}>*/}
                    
                {/*        <ListItem disablePadding>*/}
                {/*                <ListItemButton onClick={() => handleChangeTheme('Theme1')} href="">*/}
                {/*                */}{/*<ListItemIcon>Icon1*/}
                {/*                    */}{/*</ListItemIcon>*/}
                {/*                    <ListItemText>Sage and pine green</ListItemText>*/}
                                    
                {/*            </ListItemButton>*/}
                {/*        </ListItem>*/}
                {/*        <ListItem disablePadding>*/}
                {/*                <ListItemButton onClick={() => handleChangeTheme('Theme2')} >*/}
                {/*                */}{/*<ListItemIcon>Icon2*/}
                {/*                */}{/*</ListItemIcon>*/}
                {/*                    Light blue and cobalt*/}
                {/*                <ListItemText  />*/}
                {/*            </ListItemButton>*/}
                {/*            </ListItem>*/}
                {/*            <ListItem disablePadding>*/}
                {/*                <ListItemButton onClick={() => handleChangeTheme('Theme3')} >*/}
                {/*                    */}{/*<ListItemIcon>Icon2*/}
                {/*                    */}{/*</ListItemIcon>*/}
                {/*                    Raspberry pink and dark chocolate*/}
                {/*                    <ListItemText />*/}
                {/*                </ListItemButton>*/}
                {/*            </ListItem>*/}
                {/*            <ListItem disablePadding>*/}
                {/*                <ListItemButton onClick={() => handleChangeTheme('Theme4')} >*/}
                {/*                    */}{/*<ListItemIcon>Icon2*/}
                {/*                    */}{/*</ListItemIcon>*/}
                {/*                    Very dark cyan*/}
                {/*                    <ListItemText />*/}
                {/*                </ListItemButton>*/}
                {/*            </ListItem>*/}
                {/*            <ListItem disablePadding>*/}
                {/*                <ListItemButton onClick={() => handleChangeTheme('Theme5')} >*/}
                {/*                    */}{/*<ListItemIcon>Icon2*/}
                {/*                    */}{/*</ListItemIcon>*/}
                {/*                    Scarlet, light olive, & light teal (classic)*/}
                {/*                    <ListItemText />*/}
                {/*                </ListItemButton>*/}
                {/*            </ListItem>*/}
                {/*            <ListItem disablePadding>*/}
                {/*                <ListItemButton onClick={() => handleChangeTheme('Theme6')} >*/}
                {/*                    */}{/*<ListItemIcon>Icon2*/}
                {/*                    */}{/*</ListItemIcon>*/}
                {/*                    Coral, spiced apple & peach (trending)*/}
                {/*                    <ListItemText />*/}
                {/*                </ListItemButton>*/}
                {/*            </ListItem>*/}

                {/*    </List>*/}
                    
                {/*    <Divider />*/}
                {/*    */}{/*<List>*/}
                {/*    */}{/*    {['All mail', 'Trash', 'Spam'].map((text, index) => (*/}
                {/*    */}{/*        <ListItem key={text} disablePadding>*/}
                {/*    */}{/*            <ListItemButton>*/}
                {/*    */}{/*                <ListItemIcon>*/}

                {/*    */}{/*                </ListItemIcon>*/}
                {/*    */}{/*                <ListItemText primary={text} />*/}
                {/*    */}{/*                <Switch*/}
                {/*    */}{/*                    edge="end"*/}
                {/*    */}{/*                    inputProps={{*/}
                {/*    */}{/*                        'aria-labelledby': 'switch-list-label-wifi',*/}
                {/*    */}{/*                    }}*/}
                {/*    */}{/*                />*/}
                {/*    */}{/*            </ListItemButton>*/}
                {/*    */}{/*        </ListItem>*/}
                {/*    */}{/*    ))}*/}
                {/*    */}{/*</List>*/}
                {/*            </Box>*/}
                {/*        </div>*/}
                {/*    </SwipeableDrawer>*/}
                    </div>
            }
        </div>
    );
}

import React, { useEffect } from "react";

import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { MDBBtn } from 'mdb-react-ui-kit';
import { useRef } from 'react';
import './TrackGrievance.css';
import { FaSort } from 'react-icons/fa';
import Navbar from '../Navbar.js';

import { BiSolidHelpCircle } from 'react-icons/bi';
import { FaFile, FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import { GrNext } from "react-icons/gr"; //'>' icon

import useMediaQuery from '@mui/material/useMediaQuery';

//Table Components Material UI
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
//import FirstPageIcon from '@mui/icons-material/FirstPage';
//import LastPageIcon from '@mui/icons-material/LastPage';

//import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
//import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { SlControlStart } from "react-icons/sl";
import { SlControlEnd } from "react-icons/sl";
import { TfiControlSkipBackward } from "react-icons/tfi";
import { TfiControlSkipForward } from "react-icons/tfi";

import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { BiSolidEditAlt } from "react-icons/bi";


//import { MDBCheckbox } from "../../../../node_modules/mdb-react-ui-kit/dist/types/index-free";
//Table functions : 
function TablePaginationActions(props) {
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <SlArrowRight /> : <TfiControlSkipBackward />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <SlArrowLeft /> : <IoIosArrowBack />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <SlControlStart /> : <IoIosArrowForward />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <SlControlStart /> : <TfiControlSkipForward />}
            </IconButton>
        </Box>
    );
};

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
    return { name, calories, fat };
};

const rows = [
    createData('Cupcake', 305, 3.7),
    createData('Donut', 452, 25.0),
    createData('Eclair', 262, 16.0),
    createData('Frozen yoghurt', 159, 6.0),
    createData('Gingerbread', 356, 16.0),
    createData('Honeycomb', 408, 3.2),
    createData('Ice cream sandwich', 237, 9.0),
    createData('Jelly Bean', 375, 0.0),
    createData('KitKat', 518, 26.0),
    createData('Lollipop', 392, 0.2),
    createData('Marshmallow', 318, 0),
    createData('Nougat', 360, 19.0),
    createData('Oreo', 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

function TrackGrievance() {
    const [ticketNumber, setTicketNumber] = useState('')
    const [showComponent, setShowComponent] = useState(false);
    const [hideComponent, setHideComponent] = useState(true);
    const [grievance, setGrievance] = useState('')
    const [remark, setRemark] = useState('') //Comments from Internal User Regarding Grievance
    const [selectedEscalationReason, setSelectedEscalationReason] = useState('') //Selected reason for escalating Grievance from dropdown list
    const [userStatus, setUserStatus] = useState('') //Status : Updated by Internal User
    const [tno, setTno] = useState('')
    const navigate = useNavigate();
    const [singleGrievance, setSingleGrievance] = useState('')
    const [downloadBtnActive, setDownloadBtnActive] = useState(true)
    const [fileExtension, setFileExtension]=useState('.txt')
    const [sortBy, setSortBy] = useState('grievanceNumber')
    const [showEnterReasonForEscalation, setShowEnterReasonForEscalation]=useState(false)
    const [escalateToLevel, setEscalateToLevel] = useState('0');
    const [errorForNotFoundGrievance, setErrorForNotFoundGrievance] = useState('');
    const [errorInRemark, setErrorInRemark] = useState('');
    const theme = useTheme();

    const moment = require('moment') 
    const refInputBoxClear = useRef(null);

    /////////////////////
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    ////////////////////////


    // Search
    const [copyList, setCopyList] = useState(grievance)
    const [filteredRowsFinal, setFilteredRowsFinal] = useState('')

    const requestSearch = (searchedVal) => {
        const filteredRows = grievance?.filter((row) => {
            return row.grievanceStatus.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setFilteredRowsFinal(filteredRows)
        console.log("filteredRows :: " + filteredRows);
        setCopyList(filteredRows);
        console.log("Filtered rows ::" + filteredRows)
        console.log(filteredRows == '')
        if (filteredRowsFinal == '') {
            const filteredRows2 = grievance?.filter((row) => {
                return row.grievanceDetails.toLowerCase().includes(searchedVal.toLowerCase());
            });
            setCopyList(filteredRows2);
            console.log("Filtered rows2 ::" + filteredRows)
            if (filteredRows2 == '') {
                const filteredRows3 = grievance?.filter((row) => {
                    return row.department.toLowerCase().includes(searchedVal.toLowerCase());
                });
                setCopyList(filteredRows3);
                console.log("Filtered rows3 ::" + filteredRows)

            }

        }
        //console.log("rowsPerPage=====" + copyList[0]);
        //console.log("RowsPerPage.customerName==== " + filteredRows[0].customerName)
        //console.log("RowsPerPage..grievanceDetails==== " + filteredRows[0].grievanceDetails)
    };


    //const handleClick = () => setShowComponent(!showComponent) //hides component if shown, reveals if not shown
    const handleClickEdit = (grievanceNumber) => {
        console.log("==========" + grievanceNumber);
        if (grievanceNumber != '') {
            asyncTrackGrievance(grievanceNumber);
            
        } else {
            console.log("Empty grievance number")
            setErrorForNotFoundGrievance("Enter grievance number!")
        }
    } //hides component if shown, reveals if not shown
    const handleClickClear = () => {
        setShowComponent(false)
        setHideComponent(true);
        setErrorForNotFoundGrievance('');
        refInputBoxClear.current.value = '';
    } //hides component if shown, reveals if not shown
    const handleClickSatisfied = () => {
        if (userStatus == 'Satisfied') {
            setUserStatus('')
        } else {
            setUserStatus('Satisfied')
        }
    }
    const handleClickDissatisfied = () => {
        if (userStatus == 'Dissatisfied') {
            setUserStatus('')
        } else {
            setUserStatus('Dissatisfied')
        }
        console.log("Diss.."+userStatus)
    }
    const handleSubmitClickRemarksAndStatus = (grievanceNumber) => {
      
            if (remark == '') {
                setErrorInRemark("Field cannot be empty")
            } else {
                setShowComponent(false);
                setHideComponent(true);
                asyncSubmitRemarksAndStatus(grievanceNumber);
                asyncGetGrievanceByphoneNumber();
                navigate("/Track_Grievance");
            }
    }
    const handleEscalationRadioButtonClick = () => {
        setShowEnterReasonForEscalation(!showEnterReasonForEscalation);
    }
    useEffect(() => {
        //All User grievances will be displayed.
        console.log("New strUrl selectedEscalationReason test : " + selectedEscalationReason);
        if ("CustomerId" in sessionStorage) {
            asyncGetGrievanceByphoneNumber();
        }
    }, []);
    const asyncTrackGrievance = async (grievanceNumber) => {
        
        try {
            //string customerType, int membershipId, string emailId, string phoneNumber, string customerName, string grievanceDetails, string department)
            const strGetSingleGrievanceUrl = 'grievance/grievance-data-by-tno-pno?ticketNumber=' + grievanceNumber+"&phoneNumber="+sessionStorage.getItem("CustomerId");
            console.log("strURL = " + strGetSingleGrievanceUrl);
            let cookieValue =Cookies.get('jwt'); 
            //console.log("Cookies.get ::" + Object.keys(Cookies))
            console.log('cookies jwt::' , cookieValue)

            await fetch(strGetSingleGrievanceUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({ ticketNumber: grievanceNumber, phoneNumber: sessionStorage.getItem("CustomerId") })

            }).then(response => {
                console.log("..........." + response.status == 200)
                console.log("..........." + response.status == 'Ok')
                if (response.status == 401) {
                    alert("Session expired!")
                } else {

                    if (response.status !== 200) {
                        setErrorForNotFoundGrievance('Invalid Grievance number!');
                        setShowComponent(false)
                        setHideComponent(true)
                        console.log("errorForNotFoundGrievance : " + errorForNotFoundGrievance);
                        return Error;
                    } else {
                        setShowComponent(true)
                        setHideComponent(false)
                        setErrorInRemark('')
                        return response.json()
                    }
                }
            }).then(data => {
                console.log("utc() :: "+moment().utc())
                setSingleGrievance(data);
                console.log("SingleGrievance data ::[0] " + data.canEscalateGrievance);
                console.log("singleGrievance.CanEscalateGrievance == true" + data.canEscalateGrievance === 'true')
                
                    //(moment(moment()).diff(singleGrievance.dateOfEscalation, 'days') > singleGrievance.escalationSupportDays) && (singleGrievance.status == "Resolved") && () &&
                        console.log("Should be true :: " + moment(moment()).diff(singleGrievance.modifiedDate, 'days') <= singleGrievance.escalationSupportDays);
                console.log("date :: " + moment(singleGrievance.createdDate)?.utc().format('DD/MM/YYYY'))
                console.log("SingleGrievance : "+singleGrievance);
            });

        } catch (error) {
            alert("Something went wrong")
            console.log("error : " + error);
        };
        console.log("errorForNotFoundGrievance == ''", errorForNotFoundGrievance === '')  
    }

   
   
    //Get All Grievance Details by CustomerId=phoneNumber (Todo : Email can be used)
    const asyncGetGrievanceByphoneNumber = async () => {

        try {
            //Response : string customerType, int membershipId, string emailId, string phoneNumber, string customerName, string grievanceDetails, string department)
            const strGetAllGrievanceUrl = 'grievance/get-grievance-all-using-pno';
            //const strGetGrievanceUrl = '/grievance/grievance-data';
            console.log("strURL = " + strGetAllGrievanceUrl);
            const strPhoneNumber = sessionStorage.getItem("CustomerId").toString();
            await fetch(strGetAllGrievanceUrl, {
                method: 'POST',
              
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`,
                    //'Content-Type': 'application/json; charset=utf-8'
                    //'Accept': 'application/json'
                }
                //body: JSON.stringify({ PhoneNumber: strPhoneNumber })
            }).then(response => {
                if (response.status == 401) {
                    alert("Session expired!")
                } else {
                    return response.json()
                }
            }).then(data => {
                console.log("data123", data);
                setGrievance(data);
                setCopyList(data)
            });

        } catch (error) {
            alert("Something went wrong")
            console.log("error : " + error);
        };
    }

    const asyncSubmitRemarksAndStatus = async (grievanceNumber) => {
        try {
            var strSubmitRemarksAndStatus ='grievance/grievance-update-remark-status';
            if (selectedEscalationReason != '') {
                 setRemark( selectedEscalationReason +" - "+ remark);
                console.log("New strUrl : " + strSubmitRemarksAndStatus);
            } else if (userStatus=="Satisfied") {
                setEscalateToLevel(0);
            }
            
                console.log("strURL = " + strSubmitRemarksAndStatus);
            await fetch(strSubmitRemarksAndStatus, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    GrievanceNumber: grievanceNumber,
                    CustomerRemark: remark,
                    CustomerStatus: userStatus,
                    EscalationLevel: escalateToLevel
                //    EmpCode: `${sessionStorage.getItem('CustomerId')}`
                })
            }).then(response => {
                if (response.status == 401) {
                    alert("Session expired!")
                } else {
                    return response.json()
                }
            }).then(data => {
                console.log("data", data);
                //TODO : write code on successful submission of remarks and status by customer.
            });

        } catch (error) {
            alert("Something went wrong")
            console.log("error : " + error);
        };
    }

    const handleDownload = async (grievanceNumber) => {
        const strGetFileExtension = "file/get-extension?grievanceNumber=" + grievanceNumber;
        var strFileExtension
        await fetch(strGetFileExtension, {
            method: 'GET',
            headers: {
                'Authorization': `${sessionStorage.getItem('jwt')}`,
            }
        }).then(response => {
            return response.json()
        }).then(data => {
            console.log("extn", data);
            strFileExtension=data.fileExtension;
        }).then(
            fetch(`file/download/${grievanceNumber}`, {
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`,
                }
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('File not found');
                    }
                    return response.blob();
                })
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const a = document.createElement('a');
                    a.href = url;

                    a.download = grievanceNumber + strFileExtension;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    console.error('Error:', error);
                }));


    }
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <Navbar />
         
            <div class="nav-path-align" ><h4>

                <a href="/" style={{ 'color': 'black' }}>Home<GrNext style={{ 'margin-bottom': '2px', 'size': '10px' }} /></a>
                <a href="/Track_Grievance" style={{ 'color': 'black' }}>Track Grievance</a> 
            </h4>
            </div>
            <hr/>
            {/*<h1> Track Grievance : Summary Dashboard  </h1><hr/>*/}
            <div id="div-align-center">
                {!isSmallScreen &&
                <div class="input-group">
                    {!isSmallScreen &&
                        <label className="bold search-label"> Track Your Grievances Status by Ticket Number :
                            {errorForNotFoundGrievance && <p style={{ color: "red" }}>{errorForNotFoundGrievance}</p>}</label>}
                    
                    <input ref={refInputBoxClear} class="mb-4" type="text" id="datatable-search-input" onChange={(e) => setTicketNumber(e.target.value)} placeholder="Search by Ticket Number"  />
                    <button onClick={() => handleClickEdit(ticketNumber)} class="mrright mb-4 btn btn-primary" id="advanced-search-button" type="button">
                        <i class="fa fa-search"></i>

                    </button>
                    <div class="submit-div-search">
                        {/*<MDBBtn type="submit" className="d-inline mb-4 mrleft">Apply</MDBBtn>*/}
                        <MDBBtn rippleColor='red' type="cancel" className=" mb-4 mrleft" onClick={handleClickClear}>Reset</MDBBtn>
                    </div>
                    <div className="mrright">
                   
                            <input className="mrright"
                                placeholder='Track grievane by keyword'
                                type='text'
                                onInput={(e) => requestSearch(e.target.value)}
                                style={{ display: 'inline - block' } }
                            />
                        
                    </div>
                    <button style={{ display: 'inline - block' }} className="mb-4 btn-white btn-primary" type="button">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
                }
                {isSmallScreen &&
                    <div style={{ 'flex-wrap': 'unset !important' }}>
                        {!isSmallScreen &&
                            <label className="bold search-label">Track Grievance by Ticket Number :
                                {errorForNotFoundGrievance && <p style={{ color: "red" }}>{errorForNotFoundGrievance}</p>}</label>
                        }
                        {/*<input type="text" class="form-control" id="datatable-search-input" placeholder="Search by Ticket Number" />*/}
                        {/*<button class="btn btn-primary" id="advanced-search-button" type="button" onClick={handleClick}>*/}
                        {/*    <i class="fa fa-search" ></i>*/}
                        {/*</button>*/}

                        <input ref={refInputBoxClear} class="mb-4" type="text" id="datatable-search-input" onChange={(e) => setTicketNumber(e.target.value)} placeholder="Search by Ticket Number" />
                        <button class="mb-4 btn-primary" id="advanced-search-button" onClick={() => handleClickEdit(ticketNumber)} type="button">
                            <i class="fa fa-search" style={{ color: "white" }}></i>

                        </button>
                        <div class="submit-div-search">
                            {/*<MDBBtn type="submit" className="d-inline mb-4 mrleft" onClick={() => handleClickEdit(ticketNumber)}>Apply</MDBBtn>*/}
                            <MDBBtn type="cancel" className="mb-4 mrleft" id="clear-input-btn" onClick={e => handleClickClear(e)}>Reset</MDBBtn>
                        </div>
                        <div className="mrright">
                            <input className="mrright"
                                placeholder='Track grievance by keyword'
                                type='text'
                                onInput={(e) => requestSearch(e.target.value)}

                            />
                            <button className="mb-4 btn-white btn-primary" type="button">
                                <i class="fa fa-search"></i>
                            </button>
                        </div>

                    </div>
                }
            </div>
      {/*//createdDate "2023-08-28T00:00:00"*/}
    {/*//customerName "Sanchita Mehetre"*/}
    {/*//customerType "Existing Ccil Member"*/}
    {/*//grievanceDetails "ryty"*/}
    {/*//grievanceNumber 334*/}
    {/*//grievanceStatus "Submitted"*/}
    {/*//membershipId 6765*/}

            {showComponent &&
                <div>
                    <div class="grid-container">
                        <div class="grid-item"><p className="bold">Ticket Number :</p></div>
                        <div class="grid-item"> <p className="">{singleGrievance.grievanceNumber}</p></div>
                        <div class="grid-item"><p className="bold">Type of the customer :</p></div>
                        <div class="grid-item">   <p className="">{singleGrievance.customerType}</p></div>
                        <div class="grid-item"> <p className="bold">Member ID :</p></div>
                        <div class="grid-item">  <p className="">{singleGrievance.membershipId}</p></div>
                        <div class="grid-item"> <p className="bold"> Grievance Department : </p></div>
                        <div class="grid-item"> <p className="">{singleGrievance.department}</p></div>
                        <div class="grid-item"> <p className="bold">Name of the customer :</p></div>
                        <div class="grid-item"> <p className="">{singleGrievance.customerName}</p></div>
                        <div class="grid-item"> <p className="bold"> Reported Date :</p></div>
                        <div class="grid-item"> <p className="">{moment(singleGrievance.createdDate)?.utc().format('DD/MM/YYYY')}</p></div>
                        <div class="grid-item"> <p className="bold"> Grievance Details : </p></div>
                        {/*A Customer may raise their complaints/grievances related to the Platform*/}
                        <div class="grid-item">  <p className="">{singleGrievance.grievanceDetails}</p></div>
                        <div class="grid-item">  <p className="bold">Additional Details / File : </p></div>

                        <div class="grid-item">
                            {/*{console.log("(singleGrievance?.isAttachmentPresent : ", (singleGrievance.isAttachmentPresent))}*/}
                            {/*{ console.log("single : disabled : ", (singleGrievance?.isAttachmentPresent)?.length > 1 ? true : false)}*/}
                            <MDBBtn id="download_btn" className="position-center disabled-color" disabled={(singleGrievance.isAttachmentPresent)?.length > 1 ? false : true} onClick={() => handleDownload(singleGrievance.grievanceNumber)}
                                style={{ '--mdb-btn-padding-top': '0.4rem !important', '--mdb-btn-padding-bottom': ' 0.3rem', '--mdb-btn-padding-x': '0.8rem' }}
                            >Download <FaFile style={{ 'margin-bottom': '5px' }} /></MDBBtn>

                            {/*<MDBBtn id="download_btn" disabled={(singleGrievance.isAttachmentPresent)?.length > 1 ? true : false} text={singleGrievance.isAttachmentPresent} className="position-center" onClick={() => handleDownload(singleGrievance.grievanceNumber)}>Download file <FaFile /></MDBBtn>*/}
                            {/*<MDBBtn id="download_btn" className="position-center" onClick={() => handleDownload(singleGrievance.grievanceNumber)}>Download file <FaFile /></MDBBtn>*/}
                        </div>

                        <div class="grid-item"><p className="bold">Status : </p></div>
                        <div class="grid-item">
                            <p>{singleGrievance.grievanceStatus}</p>
                        </div>
                        <div class="grid-item"> <p className="bold">CCIL Comment : </p></div>
                        <div class="grid-item"> <p>{singleGrievance.comment}</p></div>
                        {singleGrievance.escalationLevel == '1' &&
                            <>

                                <div class="grid-item"><p className="bold">Escalation Status : </p></div>
                                <div class="grid-item">
                                    <p>Escalated to Level- <b>{singleGrievance.escalationLevel}</b>, In-Progress,
                                        {singleGrievance.adminResponded == 'Yes' && <b style={{ color: "blue" }}> Action taken</b>}
                                        {singleGrievance.adminResponded == 'No' && <b style={{ color: "red" }}> Action not taken</b>}
                                    </p>
                                </div>
                            </>}

                        {singleGrievance.escalationLevel == '2' &&
                            <>
                                <div class="grid-item"><p className="bold">Escalation Status : </p></div>
                                <div class="grid-item">
                                    <p>Escalated to Level- <b>{singleGrievance.escalationLevel}</b>, In-Progress,
                                        {singleGrievance.adminResponded == 'Yes' && <b style={{ color: "blue" }}> Action taken</b>}
                                        {singleGrievance.adminResponded == 'No' && <b style={{ color: "red" }}> Action not taken</b>}
                                    </p>
                                </div>
                            </>}
                    </div>
                    {(singleGrievance.grievanceCategory != 'BANK RELATED' ||
                        singleGrievance.grievanceCategory != 'GENERAL QUERY') &&
                        < div class="grid-container3">
                            <div class="grid-item"> <p className=""><b>NOTE : </b>Grievance can be escalated to higher levels by user only after <b> {singleGrievance.supportDays} Business Days </b> from Change of Status.</p></div>
                </div>
            }
                    <br></br>
                </div>
            }
            {showComponent && singleGrievance && (singleGrievance.grievanceStatus != 'Submitted')
                && (singleGrievance.grievanceStatus != 'Closed')
                && (singleGrievance.grievanceCategory != 'BANK RELATED' ||
                    singleGrievance.grievanceCategory != 'GENERAL QUERY')
                && 
                <div class="d-inline">
                    <MDBBtn  style={{ "opacity": userStatus === 'Satisfied' ? "1" : "0.8", "border": userStatus === 'Satisfied' ? "2px solid white" : "0.5px" }} type="submit" className="Satisfiedbtn mb-4" onClick={handleClickSatisfied}><FaThumbsUp style={{ 'margin-bottom': '2px' }} />  Satisfied</MDBBtn>
                    <MDBBtn style={{ "opacity": userStatus === 'Dissatisfied' ? "1" : "0.8", "border": userStatus === 'Dissatisfied' ? "2px solid white" : "0.5px" }} type="cancel" className="Dissatisfiedbtn mb-4 mrleft" onClick={handleClickDissatisfied}><FaThumbsDown style={{ 'margin-bottom': '2px' }} /> Dissatisfied</MDBBtn>
                   
                </div>
            }
            
            {/*{showComponent && singleGrievance && (singleGrievance.grievanceStatus != 'Closed') &&*/}
            {/*    <MDBBtn className="d-inline" type="submit" className="Withdrawbtn mb-4 mrleft" > Withdraw </MDBBtn>}*/}
            <br></br>
            {showComponent && singleGrievance && (singleGrievance.grievanceStatus != 'Closed')
                && (singleGrievance.grievanceCategory != 'BANK RELATED' ||
                    singleGrievance.grievanceCategory != 'GENERAL QUERY')
                && userStatus === 'Dissatisfied' && (singleGrievance.grievanceStatus != 'Submitted') &&
                <div>
                    {/*<span> <label><b>Dissatisfaction Reason :</b>   </label>   </span> */}
                    {/*<select class="select-option mb-4 browser-default custom-select" onChange={(e) => setSelectedEscalationReason(e.target.value)}>*/}
                    {/*    <option value="" selected disabled hidden>--None--</option>*/}
                    {/*    <option value="Delay in Response">Delay in Response</option>*/}
                    {/*    <option value="Incomplete Resolution">Incomplete Resolution</option>*/}
                    {/*    <option value="Option 3">Option 3</option>*/}
                    {/*    <option value="Other">Other</option>*/}
    
                    {/*</select>*/}
                    <br/>
                    {singleGrievance.escalationLevel == '' && singleGrievance.canEscalateGrievance ==='true' &&
                        <div>
                        <button type="checkbox" className="escalate-btn mb-4" ><input type="checkbox" class="escalate-checkbox" onChange={() => setEscalateToLevel('1')} onClick={handleEscalationRadioButtonClick}></input>
                            <b> Escalate To Level 1 </b>
                            {/*{singleGrievance.escalationLevel == '1' && (moment(moment()).diff(singleGrievance.dateOfEscalation, 'days') > singleGrievance.escalationSupportDays) && <b> Escalate To Level 2 </b>}*/}
                            </button>
                            {showEnterReasonForEscalation && singleGrievance && <p>Enter reason for escalation</p>}
                    </div>}

                    {singleGrievance.escalationLevel !== '' && singleGrievance.canEscalateGrievance ==='true' &&
                    //Not Required as CanEscalateGrievance is already checking all conditions
                    // (moment(moment()).diff(singleGrievance.dateOfEscalation, 'days') > singleGrievance.escalationSupportDays) && (singleGrievance.status == "Resolved")
                        //OLD : && (moment(moment()).diff(singleGrievance.modifiedDate, 'days') <= singleGrievance.escalationSupportDays)
                        
                    <div>
                        <button type="checkbox" className="escalate-btn mb-4" ><input class="escalate-checkbox" onChange={() => setEscalateToLevel('2')} type="checkbox" onClick={handleEscalationRadioButtonClick}></input>

                            <b> Escalate To Level {parseInt(singleGrievance.escalationLevel)+1} </b>

                            </button>
                            {showEnterReasonForEscalation && singleGrievance && <p>Enter reason for escalation</p>}
                        </div>
                    }
                </div>
            }
            {showComponent && singleGrievance && (singleGrievance.grievanceStatus != 'Submitted')
                && (singleGrievance.grievanceStatus != 'Closed')
                && (singleGrievance.grievanceCategory != 'BANK RELATED' ||
                    singleGrievance.grievanceCategory != 'GENERAL QUERY') &&

                    <div>
                    <textarea type="text" minlength="15" onChange={(e) => setRemark(e.target.value)} placeholder="Add feedback/ Reason" rows={3} cols={70} />
                    
                    <BiSolidHelpCircle title="Only / & % - ( ) $ ? these special characters are allowed." className="help-btn d-inline" />
                    {errorInRemark && remark === '' &&
                        <p style={{ color: "red" }}>{errorInRemark}</p>}
                </div>
               }
            
            {showComponent && singleGrievance && (singleGrievance.grievanceStatus != 'Submitted')
                && (singleGrievance.grievanceStatus != 'Closed')
                && (singleGrievance.grievanceCategory != 'BANK RELATED' ||
                    singleGrievance.grievanceCategory != 'GENERAL QUERY') &&
                <div className=" mx-3 mb-4 mrtop">
               
                <br></br>
                <MDBBtn type="submit" className="mb-4" onClick={() => handleSubmitClickRemarksAndStatus(singleGrievance.grievanceNumber)}>Submit feedback</MDBBtn>
                <MDBBtn type="cancel" className="mb-4 mrleft" onClick={handleClickClear}>Cancel</MDBBtn>
                </div>}

            {hideComponent &&
                <>
                    <TableContainer >

                    <Table component={Paper}  sx={{'margin-bottom':70, minWidth: 700, 'text-align': 'center' }} aria-label="custom pagination table">

                            <TableHead>
                            {/*<TableRow >*/}
                            {/*    <TableCell colSpan='11'>*/}
                                    {/*<div className="input-group-inside-btn">*/}
                                    {/*    <input className=""*/}
                                    {/*        placeholder='Track grievane by keyword'*/}
                                    {/*        type='text'*/}
                                    {/*        onInput={(e) => requestSearch(e.target.value)}*/}
                                    {/*        style={{ width: "400px" }}*/}
                                    {/*    />*/}
                                    {/*    <button className="btn-white btn-primary" type="button">*/}
                                    {/*        <i class="fa fa-search"></i>*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                            {/*    </TableCell>*/}
                            {/*</TableRow>*/}
                            <TableRow sx={{ 'text-align': 'center' }}>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }} >Edit Ticket
                                    </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }} >Ticket Number <FaSort onClick={() => setSortBy('grievanceNumber')} />
                                </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                    Reported Date <FaSort onClick={() => setSortBy('createdDate')} />
                                </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                        Name <FaSort onClick={() => setSortBy('customerName')} />
                                    </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                        Grievance Details <FaSort onClick={() => setSortBy('grievanceDetails')} />
                                    </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                        Status <FaSort onClick={() => setSortBy('grievanceStatus')} />
                                    </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                        Existing Member/Customer
                                    </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                        Additional Details/ File
                                </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                    Customer Status <FaSort onClick={() => setSortBy('customerStatus')} />
                                </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                    Escalation Status
                                </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                    Customer Remarks
                                </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                    CCIL Comment
                                </TableCell>
                                <TableCell sx={{ 'text-align': 'center', 'font-weight': 'bold' }}>
                                    Department
                                </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {copyList && copyList?.sort((a, b) => {
                                    if (sortBy == 'customerName') {
                                        if (a.customerName < b.customerName) {
                                            return -1
                                        }
                                        if (a.customerName > b.customerName) {
                                            return 1
                                        }
                                    }
                                    else if (sortBy == 'grievanceNumber') {
                                        if (a.grievanceNumber < b.grievanceNumber) {
                                            return -1
                                        }
                                        if (a.grievanceNumber > b.grievanceNumber) {
                                            return 1
                                        }
                                    } else if (sortBy == 'createdDate') {
                                        if (a.createdDate < b.createdDate) {
                                            return -1
                                        }
                                        if (a.createdDate > b.createdDate) {
                                            return 1
                                        }
                                    } else if (sortBy == 'customerType') {
                                        if (a.customerType < b.customerType) {
                                            return -1
                                        }
                                        if (a.customerType > b.customerType) {
                                            return 1
                                        }
                                    } else if (sortBy == 'grievanceStatus') {
                                        if (a.grievanceStatus < b.grievanceStatus) {
                                            return -1
                                        }
                                        if (a.grievanceStatus > b.grievanceStatus) {
                                            return 1
                                        }
                                    } else if (sortBy == 'grievanceDetails') {
                                        if (a.grievanceDetails < b.grievanceDetails) {
                                            return -1
                                        }
                                        if (a.grievanceDetails > b.grievanceDetails) {
                                            return 1
                                        }
                                    }
                                    return 0
                                }) &&
                                    (rowsPerPage > 0
                                ? copyList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : copyList
                                    )?.map((row) => (
                                        <TableRow>
                                            <TableCell component="th" style={{ width: 120 }} scope="row">
                                                <MDBBtn onClick={() => asyncTrackGrievance(row.grievanceNumber)} style={{ '--mdb-btn-padding-top': '0.4rem !important', '--mdb-btn-padding-bottom': ' 0.3rem', '--mdb-btn-padding-x': '0.8rem' }} ><BiSolidEditAlt style={{ 'font-size': 'large' }} /></MDBBtn>
                                            </TableCell>
                                            <TableCell sx={{ 'text-align': 'center' }} style={{ width: 120 }} >
                                                <p class="fw-normal mb-1" onChange={(e) => setTno(e.target.value)}>{row.grievanceNumber}</p>
                                            </TableCell>
                                            <TableCell sx={{ 'text-align': 'center' }} style={{ minWidth: 110 }} >
                                                <p class="fw-bold mb-1">{moment(row.createdDate)?.utc().format('DD/MM/YYYY')}</p>
                                            </TableCell>
                                            <TableCell sx={{ 'text-align': 'center' }} style={{ width: 120 }} >
                                                <div class="d-flex align-items-center">

                                                    <div class="ms-3">
                                                        <p class="fw-bold mb-1">{row.customerName}</p>
                                                        <p class="text-muted mb-0">{row.emailId}</p>
                                                    </div>
                                                </div>                                </TableCell>
                                            
                                            <TableCell sx={{ 'text-align': 'center' }} style={{ minWidth: 180 }} >
                                                <p class="fw-normal mb-1">{row.grievanceDetails}</p>
                                            </TableCell>
                                            <TableCell sx={{ 'text-align': 'center' }} style={{ width: 120 }} >
                                                <span class="badge badge-success rounded-pill d-inline">{row.grievanceStatus}</span>
                                            </TableCell>
                                            <TableCell sx={{ 'text-align': 'center' }} style={{ width: 120 }} >
                                                {row.membershipId}
                                            </TableCell>
                                            <TableCell sx={{ 'text-align': 'center' }} style={{ minWidth: 170 }} >
                                                <MDBBtn className="disabled-color" disabled={(row.isAttachmentPresent).length > 1 ? false : true} onClick={() => handleDownload(row.grievanceNumber)}
                                                    style={{ '--mdb-btn-padding-top': '0.4rem !important', '--mdb-btn-padding-bottom': ' 0.3rem', '--mdb-btn-padding-x': '0.8rem' }}
                                                >Download <FaFile style={{ 'margin-bottom': '5px' }} />
                                                </MDBBtn>

                                                    {/*<label class="custom-file-label" for="inputGroupFile01">Choose file</label>*/}
                                            </TableCell>
                                            <TableCell style={{ width: 120, 'text-align': 'center' }} >
                                                {row.customerStatus}
                                            </TableCell>
                                            <TableCell sx={{ 'text-align': 'center' }} style={{ minWidth: 160 }}>
                                                {row.escalationLevel == '1' &&
                                                    <> <p>Escalated to Level- <b>{row.escalationLevel}</b>, In-Progress,
                                                                {row.adminResponded == 'Yes' && <b style={{ color: "blue" }}> Action taken</b>}
                                                                {row.adminResponded == 'No' && <b style={{ color: "red" }}> Action not taken</b>}
                                                            </p>
                                                    </>}
                                                {row.escalationLevel == '2' &&
                                                    <>
                                                        <p>Escalated to Level- <b>{row.escalationLevel}</b>, In-Progress,
                                                            {row.adminResponded == 'Yes' && <b style={{ color: "blue" }}> Action taken</b>}
                                                            {row.adminResponded == 'No' && <b style={{ color: "red" }}> Action not taken</b>}
                                                            </p>
                                                    </>}
                                                {row.escalationLevel == '' && <><p>-</p></>}
                                            </TableCell>
                                            <TableCell style={{ minWidth: 170, 'text-align': 'center' }} >
                                                {row.customerRemark}
                                            </TableCell>

                                            <TableCell sx={{ 'text-align': 'center' }} style={{ width: 120 }} >
                                                {row.comment}
                                            </TableCell>
                                            <TableCell sx={{ 'text-align': 'center' }} style={{ width: 120 }} >
                                                {row.department}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 0 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={4}
                                    count={grievance.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </>
            }



            {/*{hideComponent &&*/}
            {/*    <table class="table mb-0 bg-white" >*/}

            {/*        <thead class="bg-light">*/}

            {/*            <tr>*/}
            {/*                <th>Edit Ticket</th>*/}
            {/*                <th>Ticket Number  <FaSort onClick={() => setSortBy('grievanceNumber')} /></th>*/}
            {/*                <th>Name <FaSort onClick={() => setSortBy('customerName')} /></th>*/}
            {/*                <th>Reported Date  <FaSort onClick={() => setSortBy('createdDate')} /></th>*/}
            {/*                <th>Grievance Details  <FaSort onClick={() => setSortBy('grievanceDetails')} /></th>*/}
            {/*                <th>Status  <FaSort onClick={() => setSortBy('grievanceStatus')} /></th>*/}
            {/*                <th>Existing CCIL Member/Customer</th>*/}
            {/*                <th>Additional Details/ File</th>*/}
            {/*                <th>CCIL Comment</th>*/}
            {/*                <th>Department</th>*/}

            {/*            </tr>*/}
            {/*        </thead>*/}

            {/*        {grievance && grievance?.sort((a, b) => {*/}
            {/*            if (sortBy == 'customerName') {*/}
            {/*                if (a.customerName < b.customerName) {*/}
            {/*                    return -1*/}
            {/*                }*/}
            {/*                if (a.customerName > b.customerName) {*/}
            {/*                    return 1*/}
            {/*                }*/}
            {/*            }*/}
            {/*            else if (sortBy == 'grievanceNumber') {*/}
            {/*                if (a.grievanceNumber < b.grievanceNumber) {*/}
            {/*                    return -1*/}
            {/*                }*/}
            {/*                if (a.grievanceNumber > b.grievanceNumber) {*/}
            {/*                    return 1*/}
            {/*                }*/}
            {/*            } else if (sortBy == 'createdDate') {*/}
            {/*                if (a.createdDate < b.createdDate) {*/}
            {/*                    return -1*/}
            {/*                }*/}
            {/*                if (a.createdDate > b.createdDate) {*/}
            {/*                    return 1*/}
            {/*                }*/}
            {/*            //} else if (sortBy == 'customerStatus') {*/}
            {/*            //    if (a.customerStatus < b.customerStatus) {*/}
            {/*            //        return -1*/}
            {/*            //    }*/}
            {/*            //    if (a.customerStatus > b.customerStatus) {*/}
            {/*            //        return 1*/}
            {/*            //    }*/}
            {/*            } else if (sortBy == 'customerType') {*/}
            {/*                if (a.customerType < b.customerType) {*/}
            {/*                    return -1*/}
            {/*                }*/}
            {/*                if (a.customerType > b.customerType) {*/}
            {/*                    return 1*/}
            {/*                }*/}
            {/*            } else if (sortBy == 'grievanceStatus') {*/}
            {/*                if (a.grievanceStatus < b.grievanceStatus) {*/}
            {/*                    return -1*/}
            {/*                }*/}
            {/*                if (a.grievanceStatus > b.grievanceStatus) {*/}
            {/*                    return 1*/}
            {/*                }*/}
            {/*            } else if (sortBy == 'grievanceDetails') {*/}
            {/*                if (a.grievanceDetails < b.grievanceDetails) {*/}
            {/*                    return -1*/}
            {/*                }*/}
            {/*                if (a.grievanceDetails > b.grievanceDetails) {*/}
            {/*                    return 1*/}
            {/*                }*/}
            {/*            }*/}
            {/*            return 0*/}
            {/*        }).map(d => (*/}
            {/*        <tbody>*/}
            {/*            */}{/*//createdDate "2023-08-28T00:00:00"*/}
            {/*            */}{/*//customerName "Sanchita Mehetre"*/}
            {/*            */}{/*//customerType "Existing Ccil Member"*/}
            {/*            */}{/*//grievanceDetails "ryty"*/}
            {/*            */}{/*//grievanceNumber 334*/}
            {/*            */}{/*//grievanceStatus "Submitted"*/}
            {/*                */}{/*//membershipId 6765*/}
            {/*                <tr>*/}
            {/*                    <td>*/}
            {/*                        <MDBBtn type="submit" onClick={() => handleClickEdit(d?.grievanceNumber)}>Edit</MDBBtn>*/}
            {/*                    </td>*/}
            {/*                    <td>*/}
            {/*                        <p class="fw-normal mb-1">{d?.grievanceNumber}</p>*/}
            {/*                    </td>*/}
            {/*                    <td>*/}
            {/*                        <div class="d-flex align-items-center">*/}

            {/*                            <div class="ms-3">*/}
            {/*                                <p class="fw-bold mb-1">{d?.customerName}</p>*/}
            {/*                                <p class="text-muted mb-0">{d?.emailId}</p>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </td>*/}
            {/*                    <td>*/}
            {/*                        <p class="fw-bold mb-1">{d?.createdDate}</p>*/}
            {/*                    </td>*/}
            {/*                    <td>*/}
            {/*                        <p class="fw-normal mb-1">{d?.grievanceDetails}</p>*/}

            {/*                    </td>*/}
            {/*                    <td>*/}
            {/*                        <span class="badge badge-success rounded-pill d-inline">{d?.grievanceStatus}</span>*/}
            {/*                    </td>*/}
            {/*                    <td>{d?.membershipId}</td>*/}
            {/*                    <td>*/}
            {/*                        */}{/*<button type="button" class="btn btn-link btn-sm btn-rounded">*/}
            {/*                        */}{/*    Edit*/}
            {/*                        */}{/*</button>*/}

            {/*                        <MDBBtn type="submit" className="disabled-color" disabled={(d?.isAttachmentPresent).length > 1 ? false : true} onClick={() => handleDownload(d?.grievanceNumber)}>Download <FaFile className="" /></MDBBtn>*/}

            {/*                        */}{/*<MDBBtn type="submit" className="mb-4">Download*/}
            {/*                        */}{/*    <FaFile /></MDBBtn>*/}
            {/*                    </td>*/}
            {/*                    <td>*/}
            {/*                        */}{/*<input type="text" multiline class="form-control" id="comment-input" placeholder="Enter comments" />*/}
            {/*                        */}{/*<TextInput multiline style={{ maxHeight: 80 }} />*/}
            {/*                        <p>{d?.comment}</p>*/}
            {/*                    </td>*/}
            {/*                    <td>*/}
            {/*                       <p>{d?.department}</p>*/}
            {/*                        </td>*/}
            {/*                </tr>*/}

            {/*            </tbody>*/}
            {/*        ))}*/}

            {/*    </table>*/}

            {/*}*/}
        
        </div>

    )
}
export default TrackGrievance;
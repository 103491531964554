import * as React from 'react';
import "./DrawerComp.css"
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
//import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
//import DrawerHeader from '@mui/material/DrawerHeader'
import IconButton from '@mui/material/IconButton'
//import InboxIcon from '@mui/icons-material/MoveToInbox';
//import MailIcon from '@mui/icons-material/Mail';
import { IoIosArrowForward } from "react-icons/io";

import { IoMdSettings } from "react-icons/io";
import { IoMdHome } from "react-icons/io";
import { FaUserPen } from "react-icons/fa6";
import { RiUserSearchFill } from "react-icons/ri";
import { RiLogoutBoxRFill } from "react-icons/ri";
import { FaUserShield } from "react-icons/fa6"; //Admin login
import { RiUserFill } from "react-icons/ri";
import { FaTableList } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";


export default function DrawerComp() {
    const [state, setState] = React.useState('false');
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate();
    const [showLoginBtn, setShowLoginBtn] = useState(false)
    const [showInternalUserLoginBtn, setShowInternalUserLoginBtn] = useState(true)
    const [showLogoutBtn, setShowLogoutBtn] = useState(false)
    const [showPostGrievanceBtn, setShowPostGrievanceBtn] = useState(false)
    const [showTrackGrievanceBtn, setShowTrackGrievanceBtn] = useState(false)
    //const [selectedTheme, setSelectedTheme] = useState('Theme1')
    const [showSidePanel, setShowSidePanel] = useState(false)

    const handleLogoutBtnClick = () => {
        
        if ("InternalUserId" in sessionStorage) {
            asyncPostLogoutLogsUpdate();
            console.log("Internal User logged out.")
        }
        asyncPostClearCookies();
        sessionStorage.clear();
        console.log("Session data cleared..");
    }

    //console.log("localStorage data : ", localStorage)

    useEffect(() => {
        if (sessionStorage.getItem("InternalUserId")) {
            setShowLogoutBtn(true);
            setShowInternalUserLoginBtn(false);
            setShowLoginBtn(false);
            setShowPostGrievanceBtn(false);
            setShowTrackGrievanceBtn(false);
        } else {
            setShowLoginBtn(true);
            setShowPostGrievanceBtn(true);
            setShowTrackGrievanceBtn(true);
        }
        if (sessionStorage.getItem("CustomerId")) {
            setShowLogoutBtn(true);
            setShowInternalUserLoginBtn(false);
            setShowLoginBtn(false);
        }
    }, [])

    //API CALLS:

    const asyncPostLogoutLogsUpdate =  () => {
        try {
            let strurl = '/home/logoutInternalUser';
             fetch(strurl, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ EmpCode: sessionStorage.getItem("InternalUserId") })

            }).then(response => {
                return response.json()
            }).then(data => {
                console.log(data);
                //Logic change..
                if (data == true) {
                    console.log("Logged out.")
                } else {
                    // enter your logic - when there is an error (eg. error toast)
                    alert("Unsuccessful logout")
                }
            });
        } catch (error) {
            console.log("error : " + error);
            alert("Something went wrong : " + error);
        }
    }

    const asyncPostClearCookies = () => {
        try {

             fetch('/home/logout', {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`
                }
            }).then(response => {
                return response.json()
            }).then(data => {
                console.log(data);
                //Logic change..
                if (data == true) {
                    console.log("Logged out.")
                    alert("Logout successful.")
                    navigate("/")
                } else {
                    // enter your logic - when there is an error (eg. error toast)
                    alert("Unsuccessful logout")
                }
            });
        } catch (error) {
            console.log("error : " + error);
            alert("Something went wrong : " + error);
        }
    }
    const toggleDrawer = (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {return;}
        setState(true);
    };

    const handleChangeTheme = (theme) => {
        if (theme) {
            localStorage.removeItem("Theme");
            localStorage.setItem("Theme", theme);
            console.log("Changing theme.." + theme)
        }
        window.location.reload();
    }
    const handleDrawerClose = () => {
        toggleDrawer(false)
        setOpen(false);
        //navigate("/HomePage");

    };
    const handleSettingsClick = () => {
        setShowSidePanel(!showSidePanel)
    }
    return (
        <div>
           
                <SwipeableDrawer
                    anchor='left'
                    open={state}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                  
                >
                    <Box className="nav-bg-color"
                        sx={{ width: 250,height:'100%' }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)} >
                        {/*<Button onClick={handleDrawerClose}>*/}
                        {/*    <IconButton >*/}
                        {/*        */}{/*{theme.direction === 'rtl' ? <IoIosArrowForward /> : <IoIosArrowForward />}*/}
                        {/*        <IoIosArrowForward />*/}
                        {/*    </IconButton>*/}
                        {/*</Button>*/}

                        <List className="nav-bg-color"  sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} style={{ 'color': 'white' }}>
                       {/*     subheader={<ListSubheader>Sample Title</ListSubheader>}*/}
                        
                            <ListItem disablePadding>
                                <ListItemButton href="/">
                                    {/*<ListItemText>Home</ListItemText>*/}
                                    <a class="navig-link" aria-current="page" href="/"><IoMdHome style={{ 'margin-bottom': '5px' }} /> Home</a>
                                </ListItemButton>
                            </ListItem>
                            {showPostGrievanceBtn &&
                            <ListItem disablePadding>
                                    <ListItemButton href="/Post_Grievance">
                                        {/*<ListItemText>Post Grievance</ListItemText>                                 */}
                                        <a class="navig-link hide-navigation-item" href="/Post_Grievance"><FaUserPen style={{ 'margin-bottom': '5px' }} /> Post Grievance</a>

                                </ListItemButton>
                            </ListItem>
                            }
                            {showTrackGrievanceBtn &&
                            <ListItem disablePadding>
                                    <ListItemButton href="/Track_Grievance">
                                    {/*<ListItemIcon>Icon2*/}
                                    {/*</ListItemIcon>*/}
                                        {/*<ListItemText >Track Grievance</ListItemText>*/}
                                        <a class="navig-link hide-navigation-item" href="/Track_Grievance"><RiUserSearchFill style={{ 'margin-bottom': '5px' }} /> Track Grievance</a>
                                                
                                </ListItemButton>
                                </ListItem>
                            }
                            {!showPostGrievanceBtn &&
                            <ListItem disablePadding>
                                    <ListItemButton href="/GrievanceDashboard" >
                                        <ListItemText ><FaTableList style={{ 'margin-bottom': '2px' }} /> Grievance Dashboard</ListItemText>
                                        
                                    <ListItemText />
                                </ListItemButton>
                                </ListItem>
                            }
                            {!showPostGrievanceBtn &&
                            <ListItem disablePadding>
                                    <ListItemButton href="/GenerateReport">
                                        <FaFileAlt style={{ 'margin-bottom': '3px' }} />  Generate Report
                                    
                                </ListItemButton>
                                </ListItem>
                            }
                            {showLoginBtn &&
                            <ListItem disablePadding>
                                    <ListItemButton href="/Login">
                                        {/*<ListItemText >Login</ListItemText>*/}
                                        <a class="navig-link" href="/Login"><RiUserFill style={{ 'margin-bottom': '5px' }} />Customer Login</a>
                                    
                                </ListItemButton>
                                </ListItem>
                            }
                            {showInternalUserLoginBtn &&
                            <ListItem disablePadding>
                                    <ListItemButton href="/InternalUserLogin" >
                                        {/*<ListItemText >CCIL Login</ListItemText>*/}
                                        <a class="navig-link" href="/InternalUserLogin"><FaUserShield style={{ 'margin-bottom': '5px' }} /> CCIL Login</a>
                                  
                                </ListItemButton>
                                </ListItem>
                            }
                            {showLogoutBtn &&
                            <ListItem disablePadding>
                                    <ListItemButton href="/" onClick={handleLogoutBtnClick}>
                                        {/*<ListItemText >Logout</ListItemText>*/}
                                        <a type="button"  class="navig-link" onClick={handleLogoutBtnClick}><RiLogoutBoxRFill /> Logout</a>
                                    <ListItemText />
                                </ListItemButton>
                                </ListItem>
                            }
                        </List>
                       </Box>
                </SwipeableDrawer>
            
        </div>
    );
}

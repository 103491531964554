import { useEffect, useState } from 'react';
import * as React from 'react';

import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login/Login';
import HomePage from './components/HomePage';
import InternalUserLogin from './components/Login/InternalUserLogin';
import PostGrievance from './components/Post_Grievance/PostGrievance';
import GrievanceSubmissionSummary from './components/Post_Grievance/GrievanceSubmissionSummary';
import Navbar from './components/Navbar';
import TrackGrievance from './components/Track_Grievance/TrackGrievance';
import Registration from './components/Registration/RegisterInternalUser';
import GrievanceDashboard from './components/Internal_User/GrievanceDashboard';
import GenerateReport from './components/Internal_User/GenerateReport';
import Protected from "./components/Protected";
//import Theme2 from "./components/Theme2.css";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//import InboxIcon from '@mui/icons-material/MoveToInbox';
//import MailIcon from '@mui/icons-material/Mail';
import SwipeableTemporaryDrawer from "./components/ThemeDrawer";
import DrawerComp from "./components/DrawerComp.js";
import ThemeDrawer from "./components/ThemeDrawer.js";


import ProtectedInternalUser from "./components/ProtectedInternalUser";

function App () {
    //const [showLoginBtn, setShowLoginBtn] = useState(true)
    //const [showInternalUserLoginBtn, setShowInternalUserLoginBtn] = useState(true)
    //const [showLogoutBtn, setShowLogoutBtn] = useState(false)
    //const handleLogoutBtnClick = () => {
        //    console.log("Reading empcode from session..")
        //    asyncPostLogoutLogsUpdate();
        //    //window.location.href="/HomePage";

        //    sessionStorage.clear();
        //    //console.log("Session data cleared..");
        //}
    const [pages, setPages] = useState('')
    //const routeComponents = pages.map(({ pageName, componentName }, key) => (
    //    <Route exact path={pageName} element={componentName} key={key} />
    //)
    //);
    const selectedStyles = localStorage.getItem("Theme");
    console.log("style.."+selectedStyles);
    if (selectedStyles) {
        import(`./components/${selectedStyles}.css`);
    } else {
        import(`./App.css`)
    }
  
    //React.useEffect(() => {
    //   // console.log("IF condi : "+localStorage.getItem("InternalUserId"));
    //    if (localStorage.getItem("InternalUserId")) {
    //            setShowLogoutBtn(true);
    //            setShowInternalUserLoginBtn(false);
    //            setShowLoginBtn(false);
    //        } else {
    //            setShowInternalUserLoginBtn(true);
    //            setShowLoginBtn(true);
    //        }
    //        if (localStorage.getItem("CustomerId")) {
    //            setShowLogoutBtn(true);
    //            setShowInternalUserLoginBtn(false);
    //            setShowLoginBtn(false);
    //        } else {
    //            setShowInternalUserLoginBtn(true);
    //            setShowLoginBtn(true);
    //        }
    //    }, [])
   // const colorTheme
    useEffect(() => {
        //selectedTheme = selectedStyles === 'theme1' ? Theme1 : Theme2;
        //const selectedStyles = localStorage.getItem("Theme");
        //const linkElement = document.createElement('link');
        //linkElement.rel = 'stylesheet';
        //linkElement.type = 'text/css';
        //linkElement.href = 'D:/work/VSProject/REACT/SampleReactDotNetApp/ClientApp/src/components/Theme1.css';
        //const existingLink = document.getElementById('theme-style');
        //console.log("Changing themes : "+linkElement.href)
        //if (existingLink) {
        //    existingLink.parentNode.removeChild(existingLink);
        //}

        //linkElement.id = 'theme-style';
        //document.head.appendChild(linkElement);

        //let xhr = new XMLHttpRequest();
        //let headers = xhr.getAllResponseHeaders();
        //console.log("** HEADERS RECEIVED ** ::: " + headers);
        //console.log("** HEADERS RECEIVED ** ::: " + headers[0]);
        //console.log(xhr.response);
        //console.log(XMLHttpRequest.HEADERS_RECEIVED)
       
        //console.log("Username ::: " + window.Headers["username"])

        //console.log("headers::: " + window.Headers)
        asyncGetPages();
    }, []);


    const asyncGetPages = async () => {

        try {
            const strGetAllPages = 'home/getPages';
            //const strGetGrievanceUrl = '/grievance/grievance-data';
            //console.log("strGetAllPages = " + strGetAllPages);

            await fetch(strGetAllPages, {
                method: 'POST'
            }).then(response => {
               return response.json()
            }).then(data => {
                console.log("data :: ", data);
                setPages(data);
            });
        } catch (error) {

            alert("Something went wrong : "+error)
            console.log("error : " + error);
        };
    }
    const [state, setState] = React.useState('right');

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    <Box
        sx={{ width:  250 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
    >
        <List>
            {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                <ListItem key={text} disablePadding>
                    <ListItemButton>
                       
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
       
    </Box>
  
        return (
            <div className="root" >

                <div className="App">
                
                    <br/>
                {/*<Navbar/>*/}
                    <div className="wrapper">
                    <br/>

                        <BrowserRouter>
                                {/*{("CustomerId" in sessionStorage) ?(*/}
                                {/*    <Route path="/Post_Grievance" element={<Protected Component={PostGrievance} />} />*/}
                                {/*) : (*/}
                                {/*        <Route path="/Login" element={<Login/>} />*/}
                                {/*)*/}
                            {/*}*/}
                            <Routes>
                                <Route index element={<HomePage />} />

                            </Routes>
                                {pages && pages.map((data) => (

                                <Routes>

                                {/*    {pages && pages.map((data ) => (*/}
                                {/*        <Route path={data.pageName} element={ } />*/}
                                {/*)*/}
                                {/*    )}*/}
                                        {data.pageName ==='/Post_Grievance' &&
                                            < Route path="/Post_Grievance" element={<Protected Component={PostGrievance} />} />
                                        }
                                        {data.pageName === '/Track_Grievance' &&

                                            <Route path="/Track_Grievance" element={< Protected Component={TrackGrievance} />} />
                                        }
                                        {data.pageName === '/InternalUserLogin' &&

                                            <Route path="/InternalUserLogin" element={<InternalUserLogin />} />
                                        }
                                        {data.pageName === '/GrievanceDashboard' &&

                                            <Route path="/GrievanceDashboard" element={<GrievanceDashboard/>} />
                                        }
                                        {data.pageName === '/GenerateReport' &&
                                            //<Route path="/GenerateReport" element={<ProtectedInternalUser Component={GenerateReport} />} />
                                             <Route path="/GenerateReport" element={< GenerateReport />} />
                                        }
                                        {data.pageName == "/Login" &&

                                            <Route path="/Login" element={<Login />} />
                                        }
                                        {data.pageName == "/Register" &&

                                            <Route path="/Register" element={<Registration />} />
                                        }
                                        <Route path="/Navbar" element={<Navbar />} />
                                        {data.pageName == "/GrievanceSubmissionSummary" &&

                                            <Route path="/GrievanceSubmissionSummary" element={< Protected Component={GrievanceSubmissionSummary} />} />
                                        }
                                {/*<Route path="/AlertDialog" element={<AlertDialog isOpen={this.} toggle={} title='' content=''  />} />*/}
                                <Route path="/temp" element={<SwipeableTemporaryDrawer />} />
                                    <Route path="/DrawerComp" element={<DrawerComp />} />
                                        <Route path="/logout" element={<HomePage />} />

                                </Routes>
                                )
                                    )}
                        </BrowserRouter>
                        
                    </div>
                </div>
               
            {/*         <div className=" max-height padding-footer padding-footer-bottom  d-flex  text-center text-md-start bg-primary-col  ">*/}
            {/*    */}{/*<div className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">*/}
            {/*    <div className="text-white">*/}
            {/*        Copyright � 2024. All rights reserved.*/}
            {/*    </div>*/}

            {/*    <div>*/}

            {/*    </div>*/}

            {/*</div>*/}

               
            </div>

        );
    }

export default App;

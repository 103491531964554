import React, { Component } from 'react';
import { MDBBtn } from 'mdb-react-ui-kit';
import Pdf, { usePDF,Resolution, Margin } from "react-to-pdf";
import { FaFile } from 'react-icons/fa'
import { MdOutlineDownloadDone } from 'react-icons/md'
import { useState, useEffect } from 'react';

import './GrievanceSubmissionSummary.css';
import Navbar from '../Navbar.js';
import PdfIcon from '../Images/pdf-icon.png';

function GrievanceSubmissionSummary() { 
    const [grievanceData,setGrievanceData ] = useState(null);
    const ref = React.createRef();
    const moment = require('moment');
    useEffect(() => {
        console.log("session data:: " + sessionStorage.getItem("GrievanceId"))
        if (sessionStorage.getItem("GrievanceId") !== "" && sessionStorage.getItem("GrievanceId") !== null) {
            try {
                const strGetGrievanceUrl = '/grievance/grievance-data?grievanceNumber=' + sessionStorage.getItem("GrievanceId");

                console.log("strURL = " + strGetGrievanceUrl);
                console.log("Asynch called. inside constructor. grievance/grievance-data : ");
                fetch(strGetGrievanceUrl, {
                    method: 'POST',
                    headers: {
                        'Authorization': `${sessionStorage.getItem('jwt')}`,
                    }
                }).then(response => {
                    // this.setState({ Grievance: response.data });
                    //console.log("Grievance Data : " + response.data);
                    if (response.status == 401) {
                        alert("Session expired!")
                    } else {
                        return response.json()
                    }
                }).then(data => {
                    console.log(data);
                    setGrievanceData(data);
                    //this.setState({ GrievanceData: data });
                    // Date Formating :2024-02-02T00:00:00
                });
            }
            catch (error) {
                alert("Something went wrong")
                console.log("error : " + error);
            };
        }
        }, []);


    //asyncLoadGrievanceCall = function ()
 const handleDownload = async () =>{
        const grievanceNumber = sessionStorage.getItem("GrievanceId");//sessionStorage.getItem("GrievanceID")
        var strFileExtension;
        const strGetFileExtension = "file/get-extension?grievanceNumber=" + grievanceNumber;
       await fetch(strGetFileExtension, {
            method: 'GET'
        }).then(response => {
            return response.json()
        }).then(data => {
            console.log("extn", data);
            strFileExtension = data.fileExtension;
            // setFileExtension(data.fileExtension);
        }).then(
            fetch(`file/download/${sessionStorage.getItem("GrievanceId") }`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('File not found');
                    }
                    return response.blob();
                }).then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const a = document.createElement('a');
                    a.href = url;

                    a.download = sessionStorage.getItem("GrievanceId") + strFileExtension;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }).catch((error) => {
                    console.error('Error:', error);
                }));
    };

   const handleDownloadSummary = async () => {
        //const response = await axios({
        //    url: 'http://localhost:5000/student/generatePdf',
        //    method: 'POST',
        //    data: student,
        //    responseType: 'blob',  // Important: indicates a binary data response
        //});



       //const response= await fetch(`report/download-submission-summary`, {
       //     method: 'POST',
       //     headers: {
       //         'Authorization': `${sessionStorage.getItem('jwt')}`,
       //     },
       //     responseType: 'blob'
       // });
       // const file = new Blob([response.data], { type: 'application/pdf' });
       // const fileURL = URL.createObjectURL(file);
       // window.open(fileURL);



        await fetch(`report/download-submission-summary`, {
            method: 'POST',
            headers: {
                'Authorization': `${sessionStorage.getItem('jwt')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                GrievanceNumber: `${sessionStorage.getItem('GrievanceId')}`
            })
        }).then((response) => {
                if (!response.ok) {
                    throw new Error('File not found');
                }
                return response.blob();
            })
            .then((data) => {
                const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
                //window.open(url);
               const a = document.createElement('a');
                a.href = url;
                a.download = sessionStorage.getItem("GrievanceId") +"_SubmissionSummary.pdf";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

           

return (
    <div>
        <Navbar />
        <h1>Grievance Submission Summary</h1>
        <br/>
        <h5 class="h4-green"><MdOutlineDownloadDone /> Thank you for submitting the grievance, we will process your grievance as soon as possible. Reported grievance details has been forwarded to your email.</h5>
        <hr/>
        <div class="align-left">
            <span>
                <p className="d-inline bold">Your Grievance Ticket Number is :<p class="d-inline tno-col"> {grievanceData?.grievanceNumber} </p></p></span>
            <p className="bold">You can use this Ticket Number to track the status of your Grievance through Track Grievance Status.
            </p>
            <p className="bold">
                Thanks & Regards,<br />
                Grievance Support<br /><br />
            </p>
        </div>
        <div class="align-left-10" >

            <div class="grid-container-summary">
                <div class="grid-item-summary"><p className="bold">Ticket Number :</p></div>
                <div class="grid-item-summary"> <p className="">{grievanceData?.grievanceNumber}</p></div>
                <div class="grid-item-summary"><p className="bold">Member ID / Customer ID :</p></div>
                <div class="grid-item-summary">   <p className="">{grievanceData?.membershipId}</p></div>
                <div class="grid-item-summary"> <p className="bold">Name of the customer :</p></div>
                <div class="grid-item-summary">  <p className="">{grievanceData?.customerName}</p></div>
                <div class="grid-item-summary"> <p className="bold">Grievance Details :</p></div>
                <div class="grid-item-summary"> <p className="">{grievanceData?.grievanceDetails}</p></div>
                <div class="grid-item-summary"> <p className="bold">Additional Details / File :</p></div>
                <div class="grid-item-summary">
                    <MDBBtn disabled={(grievanceData?.isAttachmentPresent)?.length > 1 ? false : true} onClick={() => handleDownload()}>Download Uploaded File <FaFile /></MDBBtn>
                </div>
                <div class="grid-item-summary"> <p className="bold">Current Status : </p></div>
                <div class="grid-item-summary"> <p className="">{grievanceData?.grievanceStatus}</p></div>
                <div class="grid-item-summary"> <p className="bold">Date Of Submission : </p></div>
                {/*<div class="grid-item-summary"> <p className="">{GrievanceData.createdDate?.split('T')[0]}</p></div>*/}
                <div class="grid-item-summary"> <p className="">{moment(grievanceData?.createdDate)?.utc().format('DD/MM/YYYY')}</p></div>

            </div>

        </div>


        <div class="align-left">
            <MDBBtn type="submit" onClick={() => handleDownloadSummary()} className="download-pdf-btn"><img src={PdfIcon} class="img-fluid" alt="Sample image" />
                <p id="blue-text"><b>Submission </b><br /> Summary</p></MDBBtn>

        </div>
        {/*<div class="align-left">*/}
        {/*    <Pdf targetRef={ref}*/}
        {/*        options={{*/}
        {/*            margin: "Margin.SMALL", orientation: "portrait", mimeType: 'image/png',*/}
        {/*            qualityRatio: "1", resolution: "Resolution.HIGH"*/}
        {/*        }}*/}
        {/*        Margin="Margin.SMALL" scale="1.4" filename="GrievanceDetails.pdf">*/}
        {/*        {({ toPdf }) => <div> <MDBBtn type="submit" onClick={toPdf} className="download-pdf-btn"><img src={PdfIcon} class="img-fluid" alt="Sample image" />*/}
        {/*            <p id="blue-text"><b>Submission </b><br /> Summary</p></MDBBtn>*/}


        {/*        </div>}*/}
        {/*    </Pdf>*/}
        {/*</div>*/}

    </div>
)
           
    }
    

export default GrievanceSubmissionSummary;
import React, { useState, useEffect } from 'react';
import { IoMdSettings } from "react-icons/io";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

import ThemeDrawer from "./ThemeDrawer.js"; //Settings Panel [Theme]
import ProfileDrawer from "./ProfileDrawer.js";

import DrawerComp from './DrawerComp.js'; //left navbar
import { IoMdHome } from "react-icons/io";
import { FaUserPen } from "react-icons/fa6";
import { RiUserSearchFill } from "react-icons/ri";
import { RiLogoutBoxRFill } from "react-icons/ri";
import { FaUserShield } from "react-icons/fa6"; //Admin login
import { FaUser } from "react-icons/fa6"; //UserLogin Icon
import { RiUserFill } from "react-icons/ri";
import { FaTableList } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";

import { FaUserCircle } from "react-icons/fa"; //UserProfile
//Drawer components

import IconButton from '@mui/material/IconButton';
import { IoIosArrowForward } from "react-icons/io";
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import ListSubheader from '@mui/material/ListSubheader';
import MenuIcon from '@mui/icons-material/Menu';


export default function Navbar() {
    const [showLoginBtn, setShowLoginBtn] = useState(false)
    const [showInternalUserLoginBtn, setShowInternalUserLoginBtn] = useState(true)
    const [showLogoutBtn, setShowLogoutBtn] = useState(false)
     const [showPostGrievanceBtn, setShowPostGrievanceBtn] = useState(false)
    const [showTrackGrievanceBtn, setShowTrackGrievanceBtn] = useState(false)
    //const [selectedTheme, setSelectedTheme] = useState('Theme1')
    const [showSidePanel, setShowSidePanel] = useState(false);
    const [showProfilePanel, setShowProfilePanel] = useState(false);
    const navigate = useNavigate();

    const theme = useTheme();
      let xhr = new XMLHttpRequest();
        let headers = xhr.getAllResponseHeaders();
    const deviceType = (headers['user-agent']) || 'desktop';
    console.log("DeviceType : " + deviceType);
    //deviceType == 'mobile' ? theme.breakpoints.down('xs') : 
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    //console.log("isSmallScreen : "+isSmallScreen)
    const handleLogoutBtnClick = () => {
       
        //if (sessionStorage.getItem("CustomerId")) {
        //    sessionStorage.removeItem("CustomerId")
        //    if (sessionStorage.getItem("MailId")) {
        //        sessionStorage.removeItem("MailId")
        //    }

        //} else if (sessionStorage.getItem("InternalUserId")) {
        //    //asyncPostLogoutLogsUpdate();
        //    sessionStorage.removeItem("InternalUserId")
        //}
        if ("InternalUserId" in sessionStorage) {
            asyncPostLogoutLogsUpdate();
            console.log("Internal User logged out.")
        }
        asyncPostClearCookies();
        sessionStorage.clear();
        console.log(window.location.hostname)
        if (window.location.hostname == "grievanceportal-dev.ccilindia.net"
            || window.location.hostname == "grievanceportal.ccilindia.net") {
            window.location.replace("/logout")
            // navigate("/logout")
        } else {
            window.location.reload();
        }
    }

        //console.log("localStorage data : ", localStorage)
   
    useEffect(() => {
        setShowInternalUserLoginBtn(false)
        //sessionStorage.getItem("InternalUserId")
        if (window.location.hostname == "grievanceportal-dev.ccilindia.net"
            || window.location.hostname == "grievanceportal.ccilindia.net") {
            setShowLogoutBtn(true);

        }
        if ("InternalUserId" in sessionStorage) {
            setShowLogoutBtn(true);
            setShowInternalUserLoginBtn(false);
            setShowLoginBtn(false);
            setShowPostGrievanceBtn(false);
            setShowTrackGrievanceBtn(false);
        } else if (window.location.hostname == "grievanceportal-dev.ccilindia.com"
                   || window.location.hostname == "grievanceportal.ccilindia.com") {
            setShowLoginBtn(true);
            setShowPostGrievanceBtn(true);
            setShowTrackGrievanceBtn(true);
        }
        console.log("HOST: " + window.location.host);

        console.log("HOSTNAME: " + window.location.hostname);
        if (window.location.hostname == "grievanceportal-dev.ccilindia.net"
            || window.location.hostname == "grievanceportal.ccilindia.net") {
            setShowInternalUserLoginBtn(false);
            setShowPostGrievanceBtn(false);
            setShowTrackGrievanceBtn(false);
        }
        //sessionStorage.getItem("CustomerId")
        const temp = "CustomerId" in sessionStorage;
        console.log("CustomerId :: temp" +temp )
        if ("CustomerId" in sessionStorage) {
            setShowLogoutBtn(true);
            setShowInternalUserLoginBtn(false);
            setShowLoginBtn(false);
        }
    }, [])

    //API CALLS:

     
    const asyncPostLogoutLogsUpdate =  () => {
        try {
            let strurl = '/home/logoutInternalUser';
             fetch(strurl, {
                 method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ EmpCode: sessionStorage.getItem("InternalUserId") })                    
            }).then(response => {
                if (response.status == 204 || response.status == 200) {
                    console.log("Logged out.")
                    const strUrl2 = '/logout';
                    console.log("strUrl2:"+strUrl2)
                    //fetch(strUrl2, {
                    //    method: 'GET',
                    //    headers: {
                    //        'Authorization': `${sessionStorage.getItem('jwt')}`
                    //    }
                    //}).then(response => {
                    //    //Logic change..
                    //    if (response.status == 204 || response.status == 200) {
                    //        console.log("Internal user logged out..")
                    //    }
                    //});
                } 
            });
        } catch (error) {
            console.log("error : " + error);
            //alert("Something went wrong : " + error);
        }
    }

    const asyncPostClearCookies =  () => {
        try {
            //TODO : For Internal User Login username is not present as we store Emp Code in session Storage
            const strUrl = '/home/logout';
             fetch(strUrl, {
                method: 'POST',
                 headers: {
                    'Authorization': `${sessionStorage.getItem('jwt')}`
                }
            }).then(response => {
                //Logic change..
                if (response.status == 204 || response.status == 200) {
                    //alert("Logout successful.")
                    //Change due to: page refresh is making calls from useeffect
                    //window.location.reload();
                    if (window.location.hostname != "grievanceportal-dev.ccilindia.net"
                        || window.location.hostname != "grievanceportal.ccilindia.net") {
                        navigate("/")//Refresh page if hostname is not .net
                    }
                } 
            });
            
        } catch (error) {
            console.log("error : " + error);
        //    alert("Something went wrong : " + error);
        }
    }
    
    const ProfileDrawerToggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const SettingsDrawerToggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setSettingsState({ ...settingsState, [anchor]: open });
    };
    const [settingsState, setSettingsState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const handleChangeTheme = (theme) => {
        if (theme) {
            localStorage.removeItem("Theme");
            localStorage.setItem("Theme", theme);
            console.log("Changing theme.." + theme)
        }
        window.location.reload();
    }

    //NavBar Drawer
    const NavbarDrawerToggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setNavbarState({ ...navbarState, [anchor]: open });
    };
    const [navbarState, setNavbarState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    return (
        <div>
            <SwipeableDrawer
            anchor={'right'}
            open={state['right']}
            onClose={ProfileDrawerToggleDrawer('right', false)}
            onOpen={ProfileDrawerToggleDrawer('right', true)}
        >
                <Box className="right-nav-bg-color"
                    sx={{ width: 250, height: '100%', color: 'white' }}
                role="presentation"
                onClick={ProfileDrawerToggleDrawer('right', false)}
                    onKeyDown={ProfileDrawerToggleDrawer('right', false)}
                    
                >
                    <Button onClick={ProfileDrawerToggleDrawer('right', false)}>
                        <IconButton >
                            <IoIosArrowForward style={{ color: 'white' }} />
                        </IconButton>
                    </Button>
                <List className="right-nav-bg-color"
                    sx={{ width: '100%' }}
                    subheader={<ListSubheader class="bold right-nav-bg-color"><label class="bold right-nav-bg-color">Profile</label></ListSubheader>}>
                    {sessionStorage.getItem("CustomerId") &&
                        <ListItem disablePadding>
                            <ListItemButton >

                                <ListItemText> Customer Id : {sessionStorage.getItem("CustomerId")} </ListItemText>

                            </ListItemButton>
                        </ListItem>
                    }
                    {sessionStorage.getItem("MailId") &&
                        <ListItem disablePadding>
                            <ListItemButton >

                                <ListItemText> Mail Id : {sessionStorage.getItem("MailId")} </ListItemText>

                            </ListItemButton>
                        </ListItem>
                    }
                    {sessionStorage.getItem("InternalUserId") &&
                        <ListItem disablePadding>
                            <ListItemButton >

                                <ListItemText> Internal User Id : {sessionStorage.getItem("InternalUserId")} </ListItemText>

                            </ListItemButton>
                        </ListItem>
                    }
                </List>
            </Box>
            </SwipeableDrawer>
            <SwipeableDrawer
                anchor={'right'}
                open={settingsState['right']}
                onClose={SettingsDrawerToggleDrawer('right', false)}
                onOpen={SettingsDrawerToggleDrawer('right', true)}
            >
                <Box className="right-nav-bg-color"
                    sx={{ width: 250, height: '100%', color: 'white' }}
                    role="presentation"
                    onClick={SettingsDrawerToggleDrawer('right', false)}
                    onKeyDown={SettingsDrawerToggleDrawer('right', false)} >
                    <Button onClick={SettingsDrawerToggleDrawer('right', false)}>
                        <IconButton >
                            <IoIosArrowForward style={{ color: 'white' }} />
                        </IconButton>
                    </Button>

                    <List className="right-nav-bg-color"
                        sx={{ width: '100%', maxWidth: 360 }}
                        subheader={<ListSubheader class="bold right-nav-bg-color"><label class="bold right-nav-bg-color">Change Theme</label></ListSubheader>}>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleChangeTheme('Theme1')}>

                                <ListItemText>Sage and pine green</ListItemText>

                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleChangeTheme('Theme2')} >

                                Light blue and cobalt
                                <ListItemText />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleChangeTheme('Theme3')} >

                                Raspberry pink and dark chocolate
                                <ListItemText />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleChangeTheme('Theme4')} >

                                Very dark cyan
                                <ListItemText />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleChangeTheme('Theme5')} >

                                Scarlet, light olive, & light teal (classic)
                                <ListItemText />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleChangeTheme('Theme6')} >

                                Coral, spiced apple & peach (trending)
                                <ListItemText />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />

                </Box>
            </SwipeableDrawer>
            {isSmallScreen ? (
                <div className="nav-bg-color  fixed-top" style={{
                    'width':'100%',
                    'display': 'flex',
                    'align-items': 'center'
                }}>
                    <Button onClick={NavbarDrawerToggleDrawer('left', true)}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            sx={{
                                justifyContent: 'initial'
                            }}>
                            <MenuIcon className="nav-bg-color" style={{ 'text-align': 'left', color: 'white' }} />
                        </IconButton>
                    </Button>
                    <SwipeableDrawer
                anchor='left'
                open={navbarState['left']}
                onClose={NavbarDrawerToggleDrawer('left', false)}
                onOpen={NavbarDrawerToggleDrawer('left', true)}

            >
                <Box className="nav-bg-color"
                    sx={{ width: 250, height: '100%' }}
                    role="presentation"
                    onClick={NavbarDrawerToggleDrawer('left', false)}
                    onKeyDown={NavbarDrawerToggleDrawer('left', false)} >
                    
                    <Button onClick={NavbarDrawerToggleDrawer('left', false)}>
                        <IconButton >
                            <IoIosArrowForward style={{ color: 'white' }} />
                        </IconButton>
                    </Button>
                    <List className="nav-bg-color" sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} style={{ 'color': 'white' }}>

                        <ListItem disablePadding>
                            <ListItemButton href="/">
                                <a class="navig-link" aria-current="page" href="/"><IoMdHome style={{ 'margin-bottom': '5px' }} /> Home</a>
                            </ListItemButton>
                        </ListItem>
                        {showPostGrievanceBtn &&
                            <ListItem disablePadding>
                                <ListItemButton href="/Post_Grievance">
                                            <a class="navig-link hide-navigation-item" href={("CustomerId" in sessionStorage) ? "/Post_Grievance" : "/Login"}><FaUserPen style={{ 'margin-bottom': '5px' }} /> Post Grievance</a>

                                </ListItemButton>
                            </ListItem>
                        }
                        {showTrackGrievanceBtn &&
                            <ListItem disablePadding>
                                <ListItemButton href="/Track_Grievance">
                                            <a class="navig-link hide-navigation-item" href={("CustomerId" in sessionStorage) ? "/Track_Grievance" : "/Login"}><RiUserSearchFill style={{ 'margin-bottom': '5px' }} /> Track Grievance</a>
                                 </ListItemButton>
                            </ListItem>
                        }
                        {!showPostGrievanceBtn &&
                            <ListItem disablePadding>
                                <ListItemButton href="/GrievanceDashboard" >
                                    <ListItemText ><FaTableList style={{ 'margin-bottom': '2px' }} /> Grievance Dashboard</ListItemText>

                                    <ListItemText />
                                </ListItemButton>
                            </ListItem>
                        }
                        {!showPostGrievanceBtn &&
                            <ListItem disablePadding>
                                <ListItemButton href="GenerateReport">
                                    <FaFileAlt style={{ 'margin-bottom': '3px' }} />  Generate Report

                                </ListItemButton>
                            </ListItem>
                        }
                        {showLoginBtn &&
                            <ListItem disablePadding>
                                <ListItemButton href="/Login">
                                    {/*<ListItemText >Login</ListItemText>*/}
                                    <a class="navig-link" href="/Login"><RiUserFill style={{ 'margin-bottom': '5px' }} />Customer Login</a>

                                </ListItemButton>
                            </ListItem>
                        }
                        {showInternalUserLoginBtn &&
                            <ListItem disablePadding>
                                <ListItemButton href="/InternalUserLogin" >
                                    {/*<ListItemText >CCIL Login</ListItemText>*/}
                                    <a class="navig-link" href="/InternalUserLogin"><FaUserShield style={{ 'margin-bottom': '5px' }} /> Admin Login</a>

                                </ListItemButton>
                            </ListItem>
                        }
                        {showLogoutBtn &&
                            <ListItem disablePadding>
                                <ListItemButton >
                                    {/*<ListItemText >Logout</ListItemText>*/}
                                    <a type="button" class="navig-link" onClick={handleLogoutBtnClick}><RiLogoutBoxRFill /> Logout</a>
                                    <ListItemText />
                                </ListItemButton>
                            </ListItem>
                                }
                                {showLogoutBtn &&
                                    <ListItem disablePadding>
                                        <ListItemButton >
                                            {/*<ListItemText >Logout</ListItemText>*/}
                                            <a type="button" class="navig-link" onClick={SettingsDrawerToggleDrawer('right', true)}><IoMdSettings style={{ 'margin-bottom': '5px', 'font-size': 'large' }} /> Settings</a>
                                            <ListItemText />
                                        </ListItemButton>
                                    </ListItem>
                                }
                                {showLogoutBtn &&
                                    <ListItem disablePadding>
                                        <ListItemButton>
                                            {/*<ListItemText >Logout</ListItemText>*/}
                                            <a type="button" class="navig-link" onClick={ProfileDrawerToggleDrawer('right', true)}><FaUserCircle style={{ 'margin-bottom': '5px' }} /> Profile</a>
                                            <ListItemText />
                                        </ListItemButton>
                                    </ListItem>
                                }
                    </List>
                </Box>
            </SwipeableDrawer>
            </div>) : (
                < div id="nav-bar" >
                    {/*<!-- Navbar -->*/}
                    < nav class="nav-bg-color navbar navbar-expand navbar-light  fixed-top" >
                        <div>

                            <div class="" id="">
                                <ul class="navbar-nav">
                                    <li class="nav-item active">
                                        <a class="navig-link" aria-current="page" href="/"><IoMdHome style={{ 'margin-bottom':'5px','font-size':'large'}} /> Home</a>
                                    </li>
                                    {showPostGrievanceBtn &&
                                        <li class="nav-item">
                                            <a class="navig-link hide-navigation-item" href={("CustomerId" in sessionStorage) ? "/Post_Grievance" : "/Login"}><FaUserPen style={{ 'margin-bottom': '5px' }} /> Post Grievance</a>
                                        </li>}
                                    {showTrackGrievanceBtn &&
                                        <li class="nav-item">
                                            <a class="navig-link hide-navigation-item" href={("CustomerId" in sessionStorage) ? "/Track_Grievance" : "/Login"}><RiUserSearchFill style={{ 'margin-bottom': '5px' }}  /> Track Grievance</a>
                                        </li>}
                                    {!showPostGrievanceBtn &&
                                        <li class="nav-item">
                                            <a class="navig-link hide-navigation-item"
                                                href="/GrievanceDashboard"
                                            ><FaTableList style={{ 'margin-bottom': '2px' }} /> Grievance Dashboard</a>
                                        </li>}
                                    {!showPostGrievanceBtn &&
                                        <li class="nav-item">
                                            <a class="navig-link hide-navigation-item"
                                                href="GenerateReport"
                                            ><FaFileAlt style={{ 'margin-bottom': '4px' }} /> Generate Report</a>
                                        </li>}
                                    {/*{!showPostGrievanceBtn &&*/}
                                    {/*    <li class="nav-item">*/}
                                    {/*        */}{/*<a class="navig-link hide-navigation-item" href="/">Grievance Dashboard</a>*/}
                                    {/*        <MDBBtn onClick={handleGenerateReport }>Generate Report</MDBBtn>*/}
                                    {/*    </li>}*/}
                                    {showLoginBtn && 
                                        <li class="nav-item">
                                            <a class="navig-link" href="/Login"><RiUserFill style={{ 'margin-bottom': '5px' }} />Customer Login</a>
                                        </li>
                                    }
                                    {showInternalUserLoginBtn &&
                                        <li class="nav-item">
                                            <a class="navig-link" href="/InternalUserLogin"><FaUserShield style={{ 'margin-bottom': '5px' }} /> Admin Login</a>
                                        </li>
                                    }
                                    {showLogoutBtn &&
                                        <li class="nav-item">
                                            <a type="button" class="navig-link" onClick={handleLogoutBtnClick}><RiLogoutBoxRFill style={{ 'margin-bottom': '5px', 'font-size': 'large' }} /> Logout</a>
                                        </li>
                                    }
                                    {showLogoutBtn &&
                                        <li class="nav-item">

                                            <a type="button" href="#" class="navig-link" onClick={SettingsDrawerToggleDrawer('right', true)}><IoMdSettings style={{ 'margin-bottom': '5px', 'font-size': 'large' }} /> Settings</a>
                                        </li>
                                    }
                                    {showLogoutBtn &&
                                        <li class="nav-item align-right-profile ">
                                            <a type="button" href="#" class="navig-link" onClick={ProfileDrawerToggleDrawer('right', true)}><FaUserCircle style={{ 'margin-bottom': '5px' }} /> Profile</a>
                                        </li>
                                    }
                                </ul>

                            </div>
                          

                        </div>
                        {/*<div className="settings-btn-align-left" style={{ color: 'white' }} onClick={() => handleSettingsClick()} >*/}
                        {/*    <IoMdSettings /> Settings*/}
                        {/*</div>*/}

                        {/*padding: 0px 20px;*/}
                        {/*margin-left:3px;*/}
                        {/*position: relative;*/}
                        {/*<ul style={{ 'margin-right': '10px' }}>*/}
                        {/*    <div  >*/}
                        {/*        <li >*/}

                        {/*            <a type="button" href="#" class="navig-link" onClick={() => handleSettingsClick()}><FaUserCircle style={{ 'margin-bottom': '5px' }} /> Profile</a>*/}
                        {/*        </li>*/}
                        {/*    </div>*/}
                        {/*</ul>*/}
                    </nav >
                </div >
            )}
            {/*{showSidePanel && <ThemeDrawer /> }*/}
            {/*{showProfilePanel && <ProfileDrawer /> }*/}
        </div>
        );
}

import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Protected(props) {

    const { Component } = props;
    const navigate = useNavigate();
    useEffect(() => {
        let login = sessionStorage.getItem("CustomerId");
        if (!login) {
            navigate('/Login')
        }
        let loginInternalUser = sessionStorage.getItem("InternalUserId");
       // if(in)
    });
    return (
        <div>
        <Component/>
        </div>
    )
}
export default Protected;
import React from 'react';
import './Login.css';
import { useState } from 'react';
import { useRef } from 'react';
import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBInput} from 'mdb-react-ui-kit';
import { useNavigate } from "react-router-dom";
import Navbar from '../Navbar.js';
import LoginPageImage from '../Images/LoginPageImage.PNG';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function Login() {
    
    const [emailId, setEmailId] = useState('')
    const [otp, setOtp] = useState('')
    const [isOtpInputFeild, setItWorks] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')

    const navigate = useNavigate();
    const [errorEmailId, setErrorEmailId] = useState('');
    const [errorPhoneNumber, setErrorPhoneNumber] = useState('');

    const [errorOtpField, setErrorOtpField] = useState(null);

    const [otpSent, setOtpSent] = useState('');
    const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(null);
    const refOtpBoxClear = useRef(null);

    const handleChangePhoneNumber = (event) => {
        const { value } = event.target;

        // Filter out non-numeric characters
        const numericValue = value.replace(/[^0-9]/g, '');

        setPhoneNumber(numericValue);
    };
    function isValidEmail(emailId) {
        return /\S+@\S+\.\S+/.test(emailId);
    }
 
    //Send OTP
     
    const handleSubmit = event => {
        setErrorEmailId('');
        setErrorPhoneNumber('');
        //console.log(errorEmailId + " Errors " + errorPhoneNumber)
        //console.log(phoneNumber.match(/\d/g).length)

        const specialChars = /[`!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
        //const specialCharsForPhoneNumber = /[`!@#$%^&*()_+\-=\[\]{};.':"\\|,<>\/?~]/;
        //console.log("numeric input :: " + phoneNumber.match(/\d/g).length === 10);
        if (emailId === '') { //EmailId is empty
            setErrorEmailId('Email Id Field cannot be empty!');
        }
        else if (emailId.indexOf(' ') >= 0 || specialChars.test(emailId) || !isValidEmail(emailId)) {
            setErrorEmailId('Email Id is invalid!');
        }
        //else if (phoneNumber.indexOf(' ') >= 0) {
        //    setErrorPhoneNumber('Phone Number is invalid!');
        //}
        else if (phoneNumber === '') { //EmailId is empty
            setErrorPhoneNumber('Phone Number Field cannot be empty!');
        }
        else if (!(phoneNumber.match(/\d/g).length === 10)) { //EmailId is empty
            setErrorPhoneNumber('Phone Number is invalid!');
        }
        else {
            console.log("asyncGetCall() method executing");
            asyncPostSendOtpCall();
        }
        //asyncGetCall();
        
       
    }
    //TODO : Temporary skip otp function:

    const handleSubmitSkipOtp =()=> {
        setErrorEmailId(null);
        setErrorPhoneNumber(null);
        if (emailId === '') { //EmailId is empty
            setErrorEmailId('Email Id Field cannot be empty!');
        }
        if (phoneNumber === '') { //EmailId is empty
            setErrorPhoneNumber('Phone Number Field cannot be empty!');
        }
        else if (isValidEmail(emailId)) { //EMail Id is Valid and non empty
            setItWorks(true);
            //phoneNuber or EmailId can be used to create login sessions
            sessionStorage.setItem("CustomerId", phoneNumber);
            sessionStorage.setItem("MailId", emailId);
            console.log("Login session Created..");
            //To show Terms and conditions popup only after successful Otp Verification
            handleClickOpen()
        } else {
            setErrorEmailId('EmailId is invalid!');
        }
    }

    //Resend

    const handleSubmitResend = event => {
        refOtpBoxClear.current.value = '';
        handleSubmit()
    }

    //verify OTP
    const handleSubmitVerifyOtp = event => {
        const specialCharsForOtp = /[`!@#$%^&*()_+\-=\[\]{};.':"\\|,<>\/?~]/;
        console.log("asyncPostVerifyOtpCall() method executing..:Invalid OTP :" + (specialCharsForOtp.test(otp)));

        setErrorOtpField(null);
        if (otp === '') { //EmailId is empty
            setErrorOtpField('OTP Field cannot be empty!');
            event.preventDefault();
        }
        else if ((specialCharsForOtp.test(otp))) {
            setErrorOtpField('Enter valid Otp!');
        }
        else { //Otp field is Valid and non empty
            asyncPostVerifyOtpCall();
        }
    }
    //verify OTP on Enter pressed only
    const handleSubmitVerifyOtpOnEnterPressed = (e) => {
        if (e.keyCode == 13 && e.shiftKey == false) {
            console.log("On Enter pressed asyncPostVerifyOtpCall() method executing..");
            asyncPostVerifyOtpCall();
        }
        
    }

    const asyncPostSendOtpCall = async () => {
        try {
            const strUrl = '/values/msg?PhoneNumber='+phoneNumber+'&emailId=' + emailId;
            console.log("strURL = " + strUrl);

            console.log("Asynch called.. values/msg : " + phoneNumber);
            const response = await fetch(strUrl, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
            }
            //body: {
            //    // your expected POST request payload goes here
            //    'PhoneNumber': phoneNumber,
            //    'emailId': emailId
            //}
            });
           
            const data = await response.text();
            console.log(response);
            // enter you logic when the fetch is successful
            console.log("data : ", data)
            if (String(data) === "true" && response.status == 200) {
                setItWorks(true);
                    //alert("OTP has been send on Mobile Number and EmailId.")
                    //setOtpSent('OTP has been send on Mobile Number and EmailId, valid for 60 seconds.');
                    setOtpSent('Please Enter One Time Password(OTP) you received on your Mobile Number and / or Email Id.');
                 } else if (String(data) === "Invalid") {
                     alert("Enter valid Email Id and Phone Number used in first login!")
                 }
                 else {
                     setOtpSent('You are re-trying too early. Please wait for some time.')
                alert("Failed to send an OTP!")
            }
            console.log("data:text() : "+data);
            
        } catch (error) {
            // enter your logic for when there is an error (ex. error toast)
            console.log(error)
        }
    }

    const asyncPostVerifyOtpCall =async() => {
        try { 
            const strVerifyOtpUrl = '/values/verify-otp?PhoneNumber=' + phoneNumber + '&OTP=' + otp + "&emailId=" + emailId;
        
            console.log("strURL = " + strVerifyOtpUrl);

            console.log("Asynch called.. /values/verify-otp : " + phoneNumber);
            await fetch(strVerifyOtpUrl, {
                method: 'POST'
            }).then(response => {
                console.log("Response: " + response);
                if (response.status == 200) {
                    return response.text()
                } else if (response.status == 403) {
                    // enter your logic - when there is an error (eg. error toast)
                    alert("Your account has been blocked. Please contact support for more information at 18002662109 or 022-61546313.")
                    return;
                } else {
                    return "";
                }
            }).then(data => {
                    console.log("Response from verify OTP call : " + data);
                
                    //Logic change..
                if (String(data) !== "" && data !== undefined) {
                    //phoneNuber or EmailId can be used to create login sessions
                    sessionStorage.setItem("jwt", data)
                    sessionStorage.setItem("CustomerId", phoneNumber);
                    sessionStorage.setItem("MailId", emailId);
                    console.log("Login session Created..");
                    //// alert("Successful login")
                    //To show Terms and conditions popup only after successful Otp Verification
                    //Hiding Terms and conditions as per the requirement given
                    // handleClickOpen()
                    navigate("/");
                    //<DrawerComp></DrawerComp>
                    //navigate("/DrawerComp");
                }
                else if (data === undefined) {
                } else {
                    alert("Invalid Otp!")

                }
                })
           
        } catch (error) {
            alert("Something went wrong")
            console.log("error : " + error);
        };
    }
  //Code for Terms and conditions popup
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

 
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        setErrorEmailId('');
        setErrorPhoneNumber('');
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, []);

    return (
        <div>
        < MDBContainer fluid className="p-2 my-3 h-custom" >
                <Navbar />

                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll='paper'
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description" >
                    <DialogTitle id="scroll-dialog-title">Terms and Conditions</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}  >
                            The information or content ("Data") published on the website is for informative purposes only, subject to change without notice. All Data provided here is as reported by members and "ON AVAILABILITY" basis without giving any kind of warranty.
                            <br /><br />
                            The Clearing Corporation of India Limited ("CCIL") or its agents do not make any representation or guarantee the adequacy, accuracy, timeliness, reliability or completeness of the Data provided. No responsibility is accepted by or on behalf of CCIL for any human or technical errors, omissions, delays or inaccurate Data on the website. CCIL reserves the right but has no obligation to update the Data available on the website and we have the right to make any changes or corrections at any time without any prior notice. However CCIL is under no obligation to store and may remove or delete any Data for any reason, without prior notice. Under no circumstances will CCIL be liable for any damages or losses or injury that results from the use of the Data on this website.
                            <br /><br />
                            This Data should not be construed as a financial advice of any kind. The Data disseminated herein is based on reported information and hence cannot be construed as or used for benchmarking purposes.
                            <br /><br />
                            CCIL or its agents shall not be liable for direct, indirect, punitive, special, consequential or incidental etc. losses or damage arising for non -performance, omission, interruption or termination of the services of dissemination of data including non-dissemination due to Force Majeure Events or any error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} href="/">ACCEPT</Button>
                        <Button onClick={handleClose} href="/">DO NOT ACCEPT</Button>
                    </DialogActions>
                </Dialog>
            <MDBRow>
                <MDBCol col='10' md='6'>
                    
                    <img src={LoginPageImage} class="img-fluid" alt="Sample image" />
                </MDBCol>

                <MDBCol col='4' md='6'>

                    <div className="divider d-flex align-items-center my-4">
                        <p className="text-center fw-bold mx-3 mb-0">Login</p>
                    </div>
                        <p className="text-center mx-3 mb-0">Login using your Mobile Number & Email Id</p>
                        <br/>
                    <MDBInput onChange={(e) => setEmailId(e.target.value)} wrapperClass='mb-4' required label='Email address' id='formControlLg' type='email' size="lg" />
                        {errorEmailId && <p className="d-flex align-items-left my-4" style={{ color: 'red' }}>{errorEmailId}</p>}
                   {/* <div className="divider d-flex align-items-center my-4">*/}
                      
                        {/*</div>*/}
                        <MDBInput wrapperClass='mb-4' type="text" maxlength="10" type="phone" pattern='[1-9]{1}[0-9]{9}' value={phoneNumber}
                            onChange={handleChangePhoneNumber}  size="lg" label='Mobile number' />
                        {errorPhoneNumber && <p className="d-flex align-items-left my-4" style={{ color: 'red' }}>{errorPhoneNumber}</p>}

                        {/*<MDBInput maxlength="10"  pattern='[1-9]{1}[0-9]{9}'  wrapperClass='mb-4' label='Mobile number' type='number' size="lg" />*/}

                        {/*error={isInvalidPhoneNumber}*/}
                        {/*onChange={(e) => {*/}
                        {/*    setPhoneNumber(e.target.value);*/}
                        {/*    if (e.target.value.length > 10) {*/}
                        {/*        setIsInvalidPhoneNumber(true);*/}
                        {/*    }*/}
                        {/*}}*/}
                    {/*Send OTP btn*/}
                    <div className='text-center text-md-start mt-4 pt-2'>
                        <MDBBtn className="d-inline mb-0 px-5" size='lg' onClick={handleSubmit} >Send OTP</MDBBtn>
                        {/*<MDBBtn className="d-inline mb-0 px-5" size='lg' onClick={handleSubmit} onClick={() => setItWorks(!isOtpInputFeild)} >Send OTP</MDBBtn>*/}

                            {/*{otpSent && <p className="d-flex align-items-left my-4" style={{ color: 'green' }}>{otpSent}</p>}*/}

                        {isOtpInputFeild ?
                                <>
                                    <p className="d-flex align-items-left my-4" style={{ color: 'green' }}>{otpSent}</p>
                                    <MDBInput ref={refOtpBoxClear} className="mrtop-otp-btn" onChange={(e) => setOtp(e.target.value)} maxlength="6" onKeyDown={handleSubmitVerifyOtpOnEnterPressed } required type="text" label='Enter OTP' />
                                    {errorOtpField && <p className="d-flex align-items-left my-4" style={{ color: 'red' }}>{errorOtpField}</p>}

                            </> : <></>}
                    </div>
                   
                    
                    <div className='text-center text-md-start mt-4 pt-2'>
                            <MDBBtn className="mb-0 px-5" size='lg' onClick={handleSubmitVerifyOtp}>Verify</MDBBtn>
                            <MDBBtn className="mb-0 px-5 mrleft" size='lg' onClick={handleSubmitResend}>Resend OTP</MDBBtn>
                            {/*<MDBBtn className="mb-0 px-5 mrleft" size='lg' onClick={handleSubmitSkipOtp}>Skip OTP</MDBBtn>*/}
                            
                            {/*<button className="mb-0 px-5" onClick={handleSubmit}>Resend OTP?</button>*/}

                        {/*<p className="small fw-bold mt-2 pt-1 mb-2"><a onClick={handleSubmit} href="" className="link-danger">Resend OTP?</a></p>*/}
                        {/*<p className="small fw-bold mt-2 pt-1 mb-2"><a href="/InternalUserLogin" className="link-danger">Internal User Login?</a></p>*/}

                    </div>

                </MDBCol>

            </MDBRow>


            </MDBContainer >
            {/*<button onClick={openPopup}>Open Popup</button>*/}
            {/*<AlertDialog*/}
            {/*    isopen={isPopupOpen}*/}
            {/*    toggle={closePopup}*/}
            {/*    title={"Login"}*/}
            {/*    content={"Otp is sent on mobile number"}*/}
            {/*/>*/}
        </div>
        
    );

}
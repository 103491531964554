//Registration Page:
//1.	Enter Valid CCIL Email Id / Employee No.
//2.	Enter Mobile Number.
//3.	Select Department
//4.	Enter Password and Confirm Password or OTP.
//5.	Submit and Cancel button.
//6.	On Submission of request:
//a.Email Id activation link will be sent to email.
//    b.Department User will be registered in Grievance Portal.

import React from 'react';
import './RegisterInternalUser.css';
import { useNavigate } from "react-router-dom";
import { useState,useEffect } from 'react';
import { MDBContainer, MDBBtn, MDBInput }from 'mdb-react-ui-kit';
import Navbar from '../Navbar';

export default function RegisterInternalUser({ childToParent }) {
    const navigate = useNavigate();
    const [emailId, setEmailId] = useState('');
    const [empCode, setEmpCode] = useState('');
    const [empName, setEmpName] = useState('');
    const [userName, setUserName] = useState('');
    const data = "This is data from Child Component to the Parent Component.";
    const [phoneNumber, setPhoneNumber] = useState('');
    const [department, setDepartment] = useState('');
    //To load data for select department 
    const [departmentData, setDepartmentData] = useState('')
    //const [password, setPassword] = useState('');
    //const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState(null);
    const [errorEmail, setErrorEmail] = useState('');
    const [errorEmployeeCode, setErrorEmployeeCode] = useState('');
    const [errorEmployeeName, setErrorEmployeeName] = useState('');
    const [errorUserName, setErrorUserName] = useState(null);
    const [errorPhoneNumber, setErrorPhoneNumber] = useState('');

    useEffect(() => {
        //console.log("Child to parent ::::" + data)
        console.log("Child to parent ::::" + childToParent)
        //Get all department related data(id,name).
        asyncGetDepartmentData();
    }, []);

    const asyncGetDepartmentData = async () => {

        try {
            await fetch('/department/getDepartmentData', {
                method: 'POST'
            }).then(response => {
                return response.json()
            }).then(data => {
                console.log("department data", data);
                setDepartmentData(data);
            });

        } catch (error) {
            alert("Something went wrong")
            console.log("error : " + error);
        };
    }
    const handleSubmit = event => {

        setError(null);
        setErrorEmployeeCode('')
        setErrorEmail('')
        setErrorEmployeeName('')
        setErrorPhoneNumber('')
        /*//setErrorUserName('')*/
         if ( phoneNumber === '') { //EmailId is empty
            setErrorPhoneNumber('Phone Number Field cannot be empty!');
            event.preventDefault();
        }
         if (empCode === '' ) { //EmailId is empty
            setErrorEmployeeCode('Employee  Code Field cannot be empty!');
            event.preventDefault();
        }
        //Check employee code for special characters and length=4
        if (typeof empCode !== "undefined") {
            let uppercase = "[A-Z]";
            if (empCode.length != 4 || (!new RegExp(uppercase).test(empCode[0]))) { 
                setErrorEmployeeCode('Please enter valid Employee Code!');
                event.preventDefault();
            }
        //    event.preventDefault();
        }

         if (empName === '') { //EmailId is empty
             setErrorEmployeeName('Employee Name field cannot be empty!');
            event.preventDefault();
        }
        //if (userName === '') { //EmailId is empty
        //    setErrorUserName('User Name field cannot be empty!');
        //    event.preventDefault();
        //}
      
        let validateRegex = ["[0-9]", "\\W", "[A-Z]", "[a-z]"];
        var pattern = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/

        if ((new RegExp(validateRegex[0]).test(empName)) || empName.match(pattern)) {
            console.log("inside validate- contains [0 - 9], \\W]" + (new RegExp(validateRegex[0]).test(empName)))
            console.log("empName.match(pattern) :: " , empName.match(pattern))
            setErrorEmployeeName('Please enter valid Employee Name!');

        } else {
           // console.log("outsid if validate- does not contains [0 - 9], \\W]" + true)

        }
   
        if ((!isValidPhoneNumber(phoneNumber)) ) {
            console.log('Inside Phone number error');
            setErrorPhoneNumber('Phone Number is invalid!');
            event.preventDefault();
        }
        if (emailId === '') { //EmailId is empty
            setErrorEmail('Email Id Field cannot be empty!');
            event.preventDefault();
        }
        else if (isValidEmail(emailId) ) { //EMail Id is Valid and non empty
                if (!emailId.toLowerCase().includes('ccilindia.co.in'.toLowerCase())) {
                setErrorEmail('Use CCIL Email Id!');
                    event.preventDefault();
                }
                else
                {
                    if (errorEmployeeCode == '' && errorEmail == '' && errorEmployeeName == '' && errorPhoneNumber == '')
                    {
                        asyncPostRegisterUserCall();
                    }
                
            }

        } else {
            setError('EmailId is invalid!');
            event.preventDefault();
        }
        //console.log("password  : " + password);
        //console.log("passwordConfirm : " + passwordConfirm);
    }

    const handleCancel = event => {
        //navigate("/InternalUserLogin")
    }
    function isValidEmail(emailId) {
        return /\S+@\S+\.\S+/.test(emailId);
    }
    function isValidPhoneNumber(phoneNumber) {
        setErrorPhoneNumber('')
        console.log(phoneNumber)
        console.log((new RegExp("\\W").test(phoneNumber)));
        if ((new RegExp("\\W").test(phoneNumber))
            || (new RegExp("[A-Z]").test(phoneNumber))
            || (new RegExp("[a-z]").test(phoneNumber))){
                setErrorPhoneNumber('Phone number is invalid')
            }
        return /^\d{10}$/.test(phoneNumber);
    }
   
    const asyncPostRegisterUserCall = async () => {
        try {
            const strRegisterUserUrl = 'home/registerInternalUser';

            console.log("strRegisterUserUrl = " + strRegisterUserUrl);
            const response = await fetch(strRegisterUserUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    EmailId: emailId,
                    EmpCode: empCode,
                    EmployeeName: empName,
                    PhoneNumber: phoneNumber,
                    Department: department
                })
            }).then(response => {
                return response.json()
            }).then(data => {
                    console.log("dataa", String(data) == "true");
                if (String(data) == "true") {
                    navigate("/")
                    alert("Successfully registered");
                    childToParent(data);
                }
                else {
                    alert("User already exists!")
                    childToParent(data);
                    }
                })
        } catch (error) {
             console.log(error)
        }
    }
    const handleMobileInputChange = (e) => {
        const inputValue = e.target.value;
        //if (inputValue==='' || /^\d{10}$/.test(inputValue)) {
        //    setPhoneNumber(inputValue);
        //}
        setPhoneNumber(inputValue);
    }

    return (
       <div>
       {/*<Navbar/>*/}
        <MDBContainer className="p-3 my-5 d-flex flex-column">

            {/*Type of input for email/eno?*/}
            {error && <p className="d-flex  align-items-left" style={{ color: 'red' }}>{error}</p>}
            {errorEmail && <p className="d-flex  align-items-left" style={{ color: 'red' }}>{errorEmail}</p>}
            <MDBInput onChange={(e) => setEmailId(e.target.value)} wrapperClass='mb-4' required label='CCIL Email Id' id='form2' type='email' size="lg" />
            
            {/*Old Field*/}
            {/*<MDBInput wrapperClass='mb-4' label='CCIL Email Id' onChange={(e) => setEmailId(e.target.value)} id='form1' type='email' />*/}
            {errorEmployeeCode && <p className="d-flex  align-items-left" style={{ color: 'red' }}>{errorEmployeeCode}</p>}
            <MDBInput wrapperClass='mb-4' label='Employee Code' onChange={(e) => setEmpCode(e.target.value)} id='form1' />

            {/*{errorUserName && <p className="d-flex align-items-left" style={{ color: 'red' }}>{errorUserName}</p>}*/}
            {/*<MDBInput wrapperClass='mb-4' label='User Name' onChange={(e) => setUserName(e.target.value)} id='form1' />*/}

            {errorEmployeeName && <p className="d-flex align-items-left" style={{ color: 'red' }}>{errorEmployeeName}</p>}
            <MDBInput wrapperClass='mb-4' label='Employee Name' onChange={(e) => setEmpName(e.target.value)} id='form1' />

            {/*Old Field*/}
            {/*<MDBInput wrapperClass='mb-4' label='Old Mobile Number' onChange={(e) => setPhoneNumber(e.target.value)} id='form2' pattern='[1-9]{1}[0-9]{9}' type='tel' />*/}
            {/*pattern='[1-9]{1}[0-9]{9}'*/}
            {errorPhoneNumber && <p className="d-flex align-items-left" style={{ color: 'red' }}>{errorPhoneNumber}</p>}
            <MDBInput wrapperClass='mb-4'  type="text" maxlength="10" type="phone" pattern="/^\d{10}$/" id='form2' onChange={handleMobileInputChange} label='Mobile number' />


            {/*<select className="mb-4" class="browser-default custom-select">*/}

            {/*Increase Margin-top margin-top="10px"*/}


            <select className="mr-bottom mb-4 d-flex1" class="select form-control-lg" onChange={(e) => setDepartment(e.target.value)}  >

                <option selected>Select Department</option>
                {departmentData && departmentData.map((dept) => (
                    <option value={dept.departmentName}>{dept.departmentName}</option>
                )
                )}
                {/*<option value="FX-Clear">FX-Clear</option>*/}
                {/*<option value="FX-Retail">FX-Retail</option>*/}
                {/*<option value="LEIL">LEIL</option>*/}
                {/*<option value="Membership">Membership</option>*/}
                {/*<option value="Accounts">Accounts</option>*/}
                {/*<option value="Derivatives">Derivatives</option>*/}
                {/*<option value="Economic Research">Economic Research</option>*/}
                {/*<option value="Economic Research & Surveillance, Membership & HRD">Economic Research & Surveillance, Membership & HRD</option>*/}
                {/*<option value="IT">Information Technology</option>*/}
                {/*<option value="Finance & Accounts">Finance & Accounts</option>*/}
                {/*<option value="Forex Settlement">Forex Settlement</option>*/}
                {/*<option value="Legal, Secretarial & Compliance">Legal, Secretarial & Compliance</option>*/}
                {/*<option value="NDS Operations">NDS Operations</option>*/}
                {/*<option value="Operations - Fixed Income and Money Market">Operations - Fixed Income and Money Market</option>*/}
                {/*<option value="Product Development">Product Development</option>*/}
                {/*<option value="Risk Management">Risk Management</option>*/}
                {/*<option value="Surveillance">Surveillance</option>*/}
                {/*<option value="Security Consultant">Security Consultant</option>*/}
                {/*<option value="Internal Audit">Internal Audit</option>*/}
                {/*<option value="Human Resources & Administration Department">Human Resources & Administration Department</option>*/}
                {/*<option value="Other">Other</option>*/}
                </select>
            <br></br>
            {/*Using AD Insted*/}
            {/*Implement Confirm Password Logic*/}
            {/*<MDBInput className="" wrapperClass='mb-4' label='Password' id='password' onChange={(e) => setPassword(e.target.value)} type='password' />*/}
            {/*<MDBInput wrapperClass='mb-4' label='Confirm Password' id='password-cofirm' onChange={(e) => setPasswordConfirm(e.target.value)} type='password' />*/}
           
            <div className="d-flex justify-content-between mx-3 mb-4">
               
                <MDBBtn type="submit" className="mb-4" onClick={handleSubmit} >Submit</MDBBtn>
                <MDBBtn type="cancel" className="mb-4" onClick={handleCancel }>Cancel</MDBBtn>

            </div>
            
            {/*<div className="text-center">*/}
            {/*    <p>Already registered? <a href="/InternalUserLogin">Login</a></p>*/}
            {/*</div>*/}

        </MDBContainer>
        </div>
    );
}
